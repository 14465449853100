import * as React from 'react';
import { Link, Search, useNavigate } from 'react-router-dom';
import * as Models from '../models/index';
import { useContext, useEffect, useReducer } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { Button, Col, FormGroup, Row, Spinner } from 'reactstrap';
import { formatDate } from '../utils';
import { Formik, FormikErrors, FormikProps, FormikValues } from 'formik';
import * as FormUtils from '../components/formUtils/index';
import UserAdministrativeExceptionService from '../services/UserAdministrativeExceptionService';
import { UserAdministrativeExceptionUsers } from '../models/UserAdministrativeExceptionUsers';
import AuthenticationContext from '../components/context/AuthenticationContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands, icon } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used

const PAGE_SIZE = 10;

type State = {
    canLoadMore: boolean;
    isLoading: boolean;
    isNoResults: boolean;
    items: UserAdministrativeExceptionUsers[];
    totalItems: number;
    searchTrigger: number;
}

const initialState: State = {
    canLoadMore: false,
    isLoading: false,
    isNoResults: false,
    items: [],
    totalItems: 0,
    searchTrigger: 0
}

export const AESearch = () => {

    const navigate = useNavigate();

    const [state, setState] = useReducer(
        (prevState: State, newState: Partial<State>) => ({ ...prevState, ...newState }),
        initialState
    );

    const search = async () => {

        setState({
            isLoading: true            
        });

        // 
        const result = await UserAdministrativeExceptionService.listAll();

        if (!result.hasErrors) {
            setState({
                items: result.value.items,
                totalItems: result.value.totalItems,
                isNoResults: result.value.totalItems == 0,
                isLoading: false,
                canLoadMore: result.value.totalItems > result.value.items.length
            })
        }

    }

    const loadMore = async () => {
        setState({
            isLoading: true
        });

        const result = await UserAdministrativeExceptionService.listAll();

        if (!result.hasErrors) {

            const newItemsSet = state.items.concat(result.value.items);

            setState({
                items: newItemsSet,
                totalItems: result.value.totalItems,
                isLoading: false,
                canLoadMore: result.value.totalItems > newItemsSet.length
            })
        }
    }

    useEffect(() => {

        search();
    
    }, [])

    return <>
        <h1>Administrative Exceptions</h1>

        <InfiniteScroll
            pageStart={0}
            loadMore={loadMore}
            hasMore={state.canLoadMore && !state.isLoading}
            useWindow={false}
            className=""
        ><div className="table-responsive mt-3">
                <table className="table table-hover">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>First Name</th>
                            <th>Last Name</th>                        
                            <th></th>  
                        </tr>
                    </thead>
                    <tbody>
                        {state.items.map((item, index) => <tr key={item.userAdministrativeExceptionId} className="cursor-pointer">
                            <td>{index + 1}</td>
                            <td><Link to={`/admin-ae-edit/${item.userProfileId}`}>{item.firstName}</Link></td>
                            <td><Link to={`/admin-ae-edit/${item.userProfileId}`}>{item.lastName}</Link></td>
                            <td><Link to={`/admin-ae-edit/${item.userProfileId}`}>Edit</Link></td>
                        </tr>)}
                        {state.isNoResults &&
                            <tr>
                                <td colSpan={6}>No Results</td>
                            </tr>
                        }
                        {state.isLoading &&
                            <tr>
                                <td colSpan={6}>
                                    <Spinner color="primary" size="sm" />
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
        </InfiniteScroll>
    </>

};
