import { ServiceBase, Result } from "./ServiceBase";
import { MedicalDegreeCountry } from "../models/index";

export class MedicalDegreeCountryService extends ServiceBase {

    public static async list(): Promise<Result<MedicalDegreeCountry[]>> {

        var result = await this.requestJson<MedicalDegreeCountry[]>({
            url: `api/medicalDegreeCountry/list`,          
            method: "GET"
        });

        return result;
    }   
}

