import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Login } from '../components/account/Login';
import { ApplicationState } from './../store';
import * as AuthorizationStore from './../store/Authorization';


interface Props {
    authorization: AuthorizationStore.IState;
    authorizationActions: typeof AuthorizationStore.actionCreators;
}

const HomeToConnect = (props: Props) => (
    <div>
        <h1>Alberta International Medical Graduate Program Portal</h1>
        <p>Welcome to the Alberta International Medical Graduate Program portal.</p>

        {!props.authorization.isSignedIn &&
            <>
                <div className="disclaimer-box">
                    <p className="disclaimer">The Alberta International Medical Graduate Program (AIMG Program) application portal collects applicant information in several parts. The AIMG Program Privacy Policy applies to all parts of the application portal.</p>

                    <p className="disclaimer">Privacy Policy:  Information is collected pursuant to <i>sections 33(c) and 39(1)(a)</i> of the authority of the Freedom of Information and Protection of Privacy Act.  It is required to evaluate your application for admission to the AIMG Program.  If admitted, it will form part of the AIMG Program record.  Specific data elements will be disclosed to the provincial and federal governments to meet reporting requirements.</p>

                    <p className="disclaimer">Please note that the information may be disclosed pursuant to <i>sections 39 (1)(c) and 40(1)(c) of the Freedom of Information and Protection of Privacy Act</i>, to other health care and regulatory bodies: names of applicants, dates of application and assessments, and residency program.  All other data is considered confidential and will be used and disclosed in accordance with privacy legislation.</p>
                </div>
                
            {/*<p>The Application Portal is closed. It will open for the 2025 Cycle in May 2024. Please see the <a href="https://www.aimg.ca/important-dates-deadlines/">Important Dates and Deadlines</a> for details. Email your questions to <a href="mailto:aimg@ucalgary.ca">aimg@ucalgary.ca</a></p>*/}
                {/*<p>
                    Please note that user names and passwords from 2022 and earlier match cycles are no longer registered.
                    If you applied between 2018 and 2022, please use the <a href="/register">Re-Register</a> option and we will retreive your profile from that application.

                </p>
                <p>
                    If you are a first-time applicant or applied prior to 2018, please use the <a href="/register">Register</a> option before you can sign in and begin an online application.
                </p>
                */}
            </>
        }
        <Login />
    </div>
);

const Home = connect(
    (state: ApplicationState) => ({
        authorization: state.authorization
    }), // Selects which state properties are merged into the component's props
    (dispatch, getState) => ({
        authorizationActions: bindActionCreators(AuthorizationStore.actionCreators, dispatch)
    })

)(HomeToConnect as any)

export { Home };
