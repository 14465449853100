import * as React from "react";
import { AppUserInfo } from "../../models/User";

export type AuthenticationContextType = {
    currentUser?: AppUserInfo;    
}

const AuthenticationContext = React.createContext<AuthenticationContextType>({ currentUser: undefined });

export default AuthenticationContext;

