import * as React from 'react';
import { useReducer } from 'react';
import GenericEditor from './GenericEditor';
import { ApplicationSubmissionEditorFields } from '../editorFields/ApplicationSubmissionEditorFields';
import { UserProfile, ValidationSchema } from '../../models/UserProfile';
import UserProfileService from '../../services/UserProfileService';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { Row, Col } from 'reactstrap';
import cn from 'classnames';
import { useParams } from 'react-router-dom';
import { FormViewContext } from '../context/FormViewContext';


export const ApplicationSubmissionEditor = (props: { mode: 'apply' | 'view' }) => {

    return <GenericEditor<UserProfile, string>
        getById={id =>
            props.mode == 'apply'
                ? UserProfileService.getNewApplicationSubmission()
                : UserProfileService.getApplicationSubmissionById(id)
        }
        save={model => UserProfileService.saveApplicationSubmission(model)}
        formikFields={ApplicationSubmissionEditorFields}
        validationSchema={ValidationSchema}
        stringToId={x => x}
        getId={x => x.userProfileId}
        shouldTryToLoad={id => true}
        allowSaveOnClean={true}
    />
}