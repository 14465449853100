import React from 'react';
import { Label, FormGroup, Button, Alert, NavLink, Row, Col, Spinner, Nav, NavItem, TabContent,  TabPane } from 'reactstrap';

import { useReducer } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { Register } from './Register';
import { RegisterLegacy } from './RegisterLegacy';

type RegisterTabsState = {
    activeTab: 'regular' | 'legacy';
}

const initialValues: RegisterTabsState = {
    activeTab: 'regular'
}

export const RegisterTabs = () => {

    const [state, setState] = useReducer(
        (prevState: RegisterTabsState, newState: Partial<RegisterTabsState>) => ({ ...prevState, ...newState }),
        initialValues
    );

    return <>
        <Nav tabs>
            <NavItem>
                <NavLink
                    className={cn({ "active": state.activeTab == 'regular' })}
                    onClick={() => setState({ activeTab: 'regular' })}
                    
                    
                >
                    Register
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink 
                    className={cn({ "active": state.activeTab == 'legacy' })}
                    onClick={() => setState({ activeTab: 'legacy' })}
                >
                    Re-Register With Existing Account
                </NavLink>
            </NavItem>
        </Nav>
        <TabContent activeTab={state.activeTab} >
            <TabPane tabId="regular">
                <Register></Register>
            </TabPane>
            <TabPane tabId="legacy">
                <RegisterLegacy></RegisterLegacy>
            </TabPane>
        </TabContent>
    </>
}