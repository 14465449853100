import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';
//import AuthenticationContext from './context/AuthenticationContext';
import { useContext, useEffect, useRef, useState } from 'react';
import { ApplicationState } from './../store';
import * as AuthorizationStore from './../store/Authorization';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands, icon } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used
import { ChatMessagesService } from '../services/ChatMessagesService';
import classNames from 'classnames';

import profileBus from "./bus/ProfileBus";

interface Props {
    authorization: AuthorizationStore.IState;
    authorizationActions: typeof AuthorizationStore.actionCreators;
}

const NavMenuToConnect = (props: Props) => {

    const [isOpen, setIsOpen] = useState<boolean>(true);

    const prevIsSignedInRef = usePrevious(props.authorization.isSignedIn);
    const navigate = useNavigate();

    const [hasAgreedProfile, setHasAgreedProfile] = useState<boolean>(false);

    useEffect(() => {

        if (props.authorization.isSignedIn == false && prevIsSignedInRef == true) {

            navigate('/');
        }

        profileBus.on("hasAgreedProfile", (data) => {
            setHasAgreedProfile(data.hasAgreedProfile);
        }
        );

    }, [props.authorization.isSignedIn]);

    return <>
        <header className="box-shadow border-bottom mb-3 d-print-none">
            <Container>
                <Navbar className="navbar-expand-xl navbar-toggleable-xl" light>
                    <NavbarBrand tag={Link} to="/"><img src="/Logo.svg" /> {props.authorization.currentUser?.isAdmin == true && <sup className="badge bg-primary">admin</sup>} </NavbarBrand>
                    <NavbarToggler onClick={() => setIsOpen(!isOpen)} className={(isOpen ? 'open' : 'closed') + ' d-print-none'} >
                        {!isOpen ? <FontAwesomeIcon icon={solid("close")} /> : <FontAwesomeIcon icon={solid("navicon")} />}
                    </NavbarToggler>
                    <Collapse className="d-xl-inline-flex flex-xl-row-reverse" isOpen={!isOpen} navbar>
                        <ul className="navbar-nav flex-grow mt-3">
                            <NavItem>
                                <NavLink tag={Link} to="/">Home</NavLink>
                            </NavItem>
                            {props.authorization.isSignedIn && !props.authorization.currentUser.isAdmin &&
                                <>
                                    {!props.authorization.currentAppSubmissionId && <>
                                        <NavItem>
                                            <NavLink tag={Link} to="/userprofile">Your Profile</NavLink>
                                        </NavItem>
                                        {(props.authorization.currentUser.hasAgreedProfile || hasAgreedProfile) &&
                                            <NavItem>
                                                <NavLink tag={Link} to="/files">Your Supporting Documents</NavLink>
                                            </NavItem>
                                        }
                                    {/*
                                        <NavItem>
                                            <NavLink tag={Link} to="/administrative-exceptions">Administrative Exceptions</NavLink>
                                        </NavItem>
                                    */}
                                        {props.authorization.currentUser.isExternship &&
                                            <NavItem>
                                                <NavLink tag={Link} to="/externship">Externship</NavLink>
                                            </NavItem>
                                        }
                                    </>}

                                    {props.authorization.currentAppSubmissionId && <>
                                        <NavItem>
                                            <NavLink tag={Link} to={`/app-submission-view/${props.authorization.currentAppSubmissionId}`}>My Application Submission</NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink tag={Link} to={`/letter/`}>Letter</NavLink>
                                        </NavItem>
                                    </>}

                                    {/*  {/*<NavItem>
                                        <NavLink tag={Link} to="/apply">Apply Now</NavLink>
                                    </NavItem>*/}
                                </>}
                            {props.authorization.isSignedIn && props.authorization.currentUser.isAdmin && <NavItem>
                                <NavLink tag={Link} to="/app-submission-search">App Submissions</NavLink>
                            </NavItem>}

                            {props.authorization.isSignedIn && props.authorization.currentUser.isAdmin &&
                                <>
                                    <NavItem>
                                        <NavLink tag={Link} to="/admin-ae-search">Administrative Exceptions</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink tag={Link} to="/users">Users</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink tag={Link} to="/letters" className="position-relative">Letters</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink tag={Link} to="/messages" className="position-relative">Messages<TotalUnreadForAdminSuperScript /></NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink tag={Link} to="/submission-cycle-settings-list">Submissions Cycles</NavLink>
                                    </NavItem>
                                </>}
                            {props.authorization.isSignedIn && <NavItem className="logout">
                                <NavLink tag={Link} onClick={() => {
                                    //navigate(`/`);
                                    props.authorizationActions.logout();
                                    //window.location.href = '/';
                                }} to="#">Logout {<FontAwesomeIcon icon={solid("right-from-bracket")} />}</NavLink>
                            </NavItem>}
                            <NavItem className="aimg-id">
                                <div>AIMG ID <strong>{props.authorization.currentUser?.userName || ''}</strong></div>
                            </NavItem>
                            {!props.authorization.isSignedIn && <><NavItem>
                                <NavLink tag={Link} to="/login">Login</NavLink>
                            </NavItem>
                                <NavItem>
                                    <NavLink tag={Link} to="/register">Register</NavLink>
                                </NavItem></>}
                        </ul>
                    </Collapse>
                </Navbar>
            </Container>
        </header>
        <div className="container">
            <img src="https://www.aimg.ca//Website/media/shared/Logo.jpg" className="d-none d-print-block" />
        </div>
    </>
        ;
}

const TotalUnreadForAdminSuperScript = () => {

    const [unreadCount, setUnreadCount] = useState(0);

    const loadData = async () => {

        var resp = await ChatMessagesService.getUnreadCountForAdmin();

        if (!resp.hasErrors) {
            setUnreadCount(resp.value);
        }

    }

    useEffect(() => {

        loadData();

    }, []);

    return <>{(unreadCount == 0) ? null
        : <span className="position-absolute translate-middle badge rounded-pill bg-danger">
            {unreadCount >= 100 ? '99+' : unreadCount}
            <span className="visually-hidden">unread messages</span>
        </span>
    }
    </>
}


const NavMenu = connect(
    (state: ApplicationState) => ({
        authorization: state.authorization
    }), // Selects which state properties are merged into the component's props
    (dispatch, getState) => ({
        authorizationActions: bindActionCreators(AuthorizationStore.actionCreators, dispatch)
    })

)(NavMenuToConnect as any)

export { NavMenu }

function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value; //assign the value of ref to the argument
    }, [value]); //this code will run when the value of 'value' changes
    return ref.current; //in the end, return the current ref value.
}