import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { Button } from 'reactstrap';
import { UserProfileEditor } from '../components/editors/UserProfileEditor';

const UserProfile = () => (
    <div>
        <h1>User Profile Page</h1>

        {/*<p>*/}
        {/*    The AIMG Program online application &quot;Your Profile&quot; is now locked for all 2024 Cycle applicants.  Please refer to the '2024 Cycle Important Dates and Deadlines' page for specific deadlines regarding supporting document submission (upload and sharing) deadlines (<a href="https://www.aimg.ca/important-dates-deadlines/" target="_blank">https://www.aimg.ca/important-dates-deadlines/</a>).*/}
        {/*</p>*/}

        {/*<p>*/}
        {/*    Further instructions will be provided here for how to upload supporting documents required after June 2nd.*/}
        {/*</p>*/}

        <p>
            REMINDERS:<br />
            NAC Results must be shared on <a href="https://physiciansapply.ca" target="_blank">physiciansapply.ca</a> by <strong>November 13th by 3:30 pm MT</strong><br />
            MCCQE1 Results must be shared on <a href="https://physiciansapply.ca" target="_blank">physiciansapply.ca</a> by <strong>November 13th by 3:30 pm MT</strong><br />
        </p>

        <UserProfileEditor />
    </div>
);

export { UserProfile };
