import * as React from 'react';
//import { useState } from 'react';
//import { AppUserFilesEditor } from '../components/editors/AppUserFilesEditor';
import { SubmissionCycleSettingsEditor } from '../components/editors/SubmissionCycleSettingsEditor';



export const SubmissionCycleSettingsEditorPage = () => {

    return <>
        <h1>Submission Cycle Edit</h1>
        <SubmissionCycleSettingsEditor/>
    </>
    
};
