import * as React from 'react';
import { useReducer, useEffect, useRef, useContext } from 'react';
import { Link} from 'react-router-dom';
import { Spinner, Button, Container, Row, Col, NavLink } from 'reactstrap';
//import InfiniteScroll from 'react-infinite-scroller';
//import * as ArrayUtils from 'typescript-array-utils';
import ShortUniqueId from 'short-unique-id';
import { AppUserInfo, ChatMessage } from '../../../models';
//import { UIMessages } from './UIUtils';
//import { PagedResult, Result } from '../services/ServiceBase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands, icon } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used
import { ChatSendMessageArea } from '../ChatSendMessageArea';
import cn from 'classnames';
import { ChatHistoryArea } from '../ChatHistoryArea';
import AuthenticationContext from '../../context/AuthenticationContext';
import { ChatMessagesService, SearchIn } from '../../../services/ChatMessagesService';
import { UserOnlineChangedData } from '../../../models/ChatMessage';

const uid = new ShortUniqueId({ length: 10 });

const PAGE_SIZE = 10;

type ChatByUserState = {
    newMessage?: ChatMessage,
    userIsOnline: boolean
}

export const ChatByUser = (props: {
    user: AppUserInfo,
    active: boolean,    
    onClose?: () => void,
    newMessage?: ChatMessage,
    userOnlineChangedData?: UserOnlineChangedData,
    messageViewed?: ChatMessage
}) => {

    const authCtx = useContext(AuthenticationContext);    

    let [state, setState] = useReducer(
        (prevState: ChatByUserState, newState: Partial<ChatByUserState>) => ({ ...prevState, ...newState }),
        {
            newMessage: undefined,
            userIsOnline: false
        }
    );

    const checkIsOnline = async () => {

        var result = await ChatMessagesService.checkIsOnline(props.user.id);

        if (!result.hasErrors && state.userIsOnline != result.value) {
            setState({ userIsOnline: result.value });
        }

    };

    useEffect(() => {

        if (props.newMessage && props.newMessage.fromUserId == props.user.id) {
            setState({ newMessage: props.newMessage });
        }

    }, [props.newMessage])

    useEffect(() => {

        if (props.userOnlineChangedData && props.userOnlineChangedData.userId == props.user.id) {
            if (props.userOnlineChangedData.isOnline != state.userIsOnline) {
                setState({ userIsOnline: props.userOnlineChangedData.isOnline });
            }
        }

    }, [props.userOnlineChangedData])

    useEffect(() => {

        checkIsOnline();

    }, []);

    return <div className={cn("chat-by-user chat", { 'd-none': !props.active })}>
        <div className="chat-header d-flex">
            <div className="flex-grow-1">
                <div><b className="position-relative">Chat</b> {state.userIsOnline ? 'online': 'offline'}</div>
                <div> <NavLink tag={Link} to={`/app-submission-search?q=${props.user.userName}`} className="position-relative chat-profile"><FontAwesomeIcon icon={solid("user")} /> {props.user.email}</NavLink></div>
            </div>

            {props.onClose && <div className="ms-2">
                <a href="#" onClick={(e) => {
                    e.preventDefault();
                    if (props.onClose)
                        props.onClose()
                }}>
                    <FontAwesomeIcon icon={solid("times")} />
                </a>
            </div>}


        </div>        

        <ChatHistoryArea
            //opened={props.active}
            newMessage={state.newMessage}
            loadMessages={(refDate, refId) => {

                var searchReq: SearchIn = {
                    userId: props.user.id
                };

                return ChatMessagesService.getMessagesForUser(searchReq, PAGE_SIZE, refDate, refId);
            }}
            messageViewed={props.messageViewed}
        />

        <ChatSendMessageArea onSend={(message) => {

            if (message.trim().length == 0)
                return;

            var payload: ChatMessage = {
                created: new Date(),
                clientId: uid(),
                fromUserId: authCtx.currentUser?.id as string,
                message: message,
                id: 0,
                readAt: undefined,
                toUserId: props.user.id
            }

            setState({ newMessage: payload });

        }} />

    </div>
};