import * as React from 'react';
import { useReducer, useEffect, useRef, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Spinner, Button, Container, Row, Col, NavLink } from 'reactstrap';
import InfiniteScroll from 'react-infinite-scroller';
import * as ArrayUtils from 'typescript-array-utils';
import ShortUniqueId from 'short-unique-id';
import { AppUserInfo } from '../../../models';
import { NonAdminUsersService, SearchIn } from '../../../services/NonAdminUsersService';
import * as FormUtils from '../../../components/formUtils/index';
import { Formik } from 'formik';
import { Search } from 'history';
import { UserOnlineChangedData } from '../../../models/ChatMessage';
//import { UIMessages } from './UIUtils';
//import { PagedResult, Result } from '../services/ServiceBase';

const initialFiltersModel: SearchIn = {
    keywords: '',
    onlyWithUnreadMessages: false
}

const PAGE_SIZE = 20;

type NonAdminUsersListState = {
    isLoading: boolean;
    items: AppUserInfo[];
    canLoadMore: boolean;
    isNoResults: boolean;
    totalItems: number;
    lastFilter?: SearchIn
    searchTrigger: number;
}

export const NonAdminUsersList = (props: {
    onOpenChat: (user: AppUserInfo) => void;
    userOnlineChangedData?: UserOnlineChangedData;
}) => {

    let [state, setState] = useReducer(
        (prevState: NonAdminUsersListState, newState: Partial<NonAdminUsersListState>) => ({ ...prevState, ...newState }),
        {
            isLoading: false,
            items: [],
            canLoadMore: false,
            isNoResults: false,
            totalItems: 0,
            lastFilter: initialFiltersModel,
            searchTrigger: 0
        }
    );

    const search = async () => {

        setState({
            isLoading: true
        });

        // 
        const result = await NonAdminUsersService.search(state.lastFilter as SearchIn, PAGE_SIZE, 0);

        if (!result.hasErrors) {
            setState({
                items: result.value.items,
                totalItems: result.value.totalItems,
                isNoResults: result.value.totalItems == 0,
                isLoading: false,
                canLoadMore: result.value.totalItems > result.value.items.length
            })
        }

    }

    const loadMore = async () => {
        setState({
            isLoading: true
        });

        const result = await NonAdminUsersService.search(state.lastFilter as SearchIn, PAGE_SIZE, state.items.length);

        if (!result.hasErrors) {

            const newItemsSet = state.items.concat(result.value.items);

            setState({
                items: newItemsSet,
                totalItems: result.value.totalItems,
                isLoading: false,
                canLoadMore: result.value.totalItems > newItemsSet.length
            })
        }
    }

    useEffect(() => {
        search();
    }, [state.lastFilter])

    useEffect(() => {

        console.log('props.userOnlineChangedData');

        if (props.userOnlineChangedData) {

            let userWasFound = false;

            var newData = state.items.map(x => {
                if (x.id == props.userOnlineChangedData?.userId && x.isOnline != props.userOnlineChangedData.isOnline) {
                    userWasFound = true;
                    x.isOnline = props.userOnlineChangedData.isOnline;
                }

                return x;
            });

            if (userWasFound) {
                setState({ items: newData });
            }
        }

    }, [props.userOnlineChangedData]);


    return <div className="users">
        <Filters onFiltersChange={(filter) => setState({ lastFilter: filter })} />
        <InfiniteScroll
            pageStart={0}
            loadMore={loadMore}
            hasMore={state.canLoadMore && !state.isLoading}
            useWindow={false}
            className=""
        ><div className="table-responsive mt-3">
                <table className="table table-hover">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Username</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th></th>
                            <th>Status</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {state.items.map((item, index) => <tr key={item.id} className="cursor-pointer">
                            <td>{index + 1}</td>
                            <td>{item.userName}</td>
                            <td>{item.firstName} {item.lastName}</td>
                            <td>{item.email}</td>
                            <td>{item.unreadMessages || 0 > 0 && <><span className="badge rounded-pill bg-danger">
                                {item.unreadMessages || 0 >= 100 ? '99+' : item.unreadMessages}
                                <span className="visually-hidden">unread messages</span>
                            </span></>}</td>

                            <td>{item.isOnline == true && 'Online'}</td>
                            <td><NavLink href="#" onClick={(e) => { e.preventDefault(); props.onOpenChat(item) }}>Open Chat</NavLink></td>
                        </tr>)}
                        {state.isNoResults &&
                            <tr>
                                <td colSpan={6}>No Results</td>
                            </tr>
                        }
                        {state.isLoading &&
                            <tr>
                                <td colSpan={6}>
                                    <Spinner color="primary" size="sm" />
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
        </InfiniteScroll>
    </div>
};



const Filters = (props: {
    onFiltersChange: (filters: SearchIn) => void;
}) => {

    return <div><Formik enableReinitialize={true}
        initialValues={initialFiltersModel}
        onSubmit={(values, actions) => {
            props.onFiltersChange(values);
        }}
    >
        {(formikState) =>
            <>
                <Row>
                    <Col>
                        <FormUtils.GroupSetText
                            title="Keywords"
                            fieldName="keywords"
                        />
                    </Col>
                    <Col>
                        <FormUtils.GroupSetCheckbox
                            title="Only With Unread Messages"
                            fieldName="onlyWithUnreadMessages"
                        />
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Button type="button" color="primary" onClick={() => { formikState.submitForm() }}>Search</Button>

                        <Button type="button" color="primary" outline className="ms-2" onClick={() => { formikState.resetForm() }}>Clear</Button>
                    </Col>
                </Row>
            </>
        }
    </Formik></div>
}

