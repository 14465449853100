import { ServiceBase, Result, PagedResult, convertStringsToDates } from "./ServiceBase";
import { FileType } from "../models/index";

export class FileTypeService extends ServiceBase {

    public static async list(aeOnly: boolean = false): Promise<Result<FileType[]>> {

        var result = await this.requestJson<FileType[]>({
            url: `api/FileType/list?aeOnly=${aeOnly}`,
            method: "GET"
        });

        return result;
    }   
}