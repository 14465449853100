import { ServiceBase, Result, PagedResult } from "./ServiceBase";
import { UserCreate, CurrentUserCtx, LoginInfo, IdentityResult, LegacyUserCreate, LoginResult, AppUserInfo, UserAccountListView, UserAccountEdit } from "../models/index";



export class SearchIn extends Object {
    keywords: string;    
}


export class AccountService extends ServiceBase {

    public static async createUser(userCreate: UserCreate): Promise<Result<IdentityResult>> {

        var result = await this.requestJson<IdentityResult>({
            url: `api/account/register`,
            method: "POST",
            data: userCreate
        });

        return result;
    }

    public static async createLegacyUser(userCreate: LegacyUserCreate): Promise<Result<IdentityResult>> {

        var result = await this.requestJson<IdentityResult>({
            url: `api/account/registerLegacy`,
            method: "POST",
            data: userCreate
        });

        return result;
    }

    public static async getCurrentUserCtx(): Promise<Result<CurrentUserCtx>> {

        var result = await this.requestJson<CurrentUserCtx>({
            url: `api/account/GetCurrentUserCtx`,
            method: "GET"            
        });

        return result;
    }

    public static async login(loginData: LoginInfo): Promise<Result<LoginResult>> {

        //console.log('service.login');

        var result = await this.requestJson<LoginResult>({
            url: `api/account/login`,
            method: "POST",
            data: loginData
        });

        return result;
    }

    public static async ConfirmUserEmail(token: string, email: string): Promise<Result<IdentityResult>> {

        var result = await this.requestJson<IdentityResult>({
            url: `api/account/ConfirmUserEmail`,
            method: "POST",
            data: {token, email}
        });

        return result;
    }

    public static async logout(): Promise<Result<boolean>> {
        var result = await this.requestJson<boolean>({
            url: `api/account/logout`,
            method: "POST"            
        });

        return result;
    }

    public static async resendEmailConfirmationEmail(email: string): Promise<Result<IdentityResult>> {

        var result = await this.requestJson<IdentityResult>({
            url: `api/account/ResendEmailConfirmationEmail`,
            method: "POST",
            data: { email }
        });

        return result;
    }

    public static async sendPwdResetEmail(email: string): Promise<Result<IdentityResult>> {

        var result = await this.requestJson<IdentityResult>({
            url: `api/account/SendResetPwdEmail`,
            method: "POST",
            data: { email }
        });

        return result;
    }

    public static async resetPassword(email: string, token: string, password: string): Promise<Result<IdentityResult>> {

        var result = await this.requestJson<IdentityResult>({
            url: `api/account/ResetPassword`,
            method: "POST",
            data: { email, token, password }
        });

        return result;
    }

    public static async getAllAdmins(): Promise<Result<AppUserInfo[]>> {

        var result = await this.requestJson<AppUserInfo[]>({
            url: `api/account/getAllAdmins`,
            method: "GET"            
        });

        return result;
    }

    public static async appUserInfoById(appUserId: string): Promise<Result<AppUserInfo>> {

        var result = await this.requestJson<AppUserInfo>({
            url: `api/account/appUserInfoById`,
            method: "POST",
            data: {
                appUserId
            }
        });

        return result;
    }

    public static async get(appUserId: string): Promise<Result<UserAccountEdit>> {

        var result = await this.requestJson<UserAccountEdit>({
            url: `api/account/getforedit/` + appUserId,
            method: "GET"            
        });

        return result;
    }

    public static async save(model: UserAccountEdit): Promise<Result<UserAccountEdit>> {

        var result = await this.requestJson<UserAccountEdit>({
            url: `api/account/save`,
            method: "POST",
            data: model
        });

        return result;
    }


    public static async search(filters: SearchIn, pageSize: number, offset: number): Promise<Result<PagedResult<UserAccountListView>>> {

        var result = await this.requestJson<PagedResult<UserAccountListView>>({
            url: `api/account/search`,
            method: "POST",
            data: {
                ...filters, pageSize, offset
            }
        });

        return result;
    }
}