import * as React from 'react';
import { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, ModalProps } from 'reactstrap';

interface IProps {
    children?: JSX.Element | JSX.Element[] | any;
    modalTitle: JSX.Element | JSX.Element[] | any;
    modal?: ModalProps,
    footer?: JSX.Element | JSX.Element[] | any;
    footerClass?: string;
}

interface IState {
    modalOpened: boolean;
}

class ModalComponent extends React.Component<IProps, IState> {

    state: IState = {
        modalOpened: false
    }

    constructor(props: IProps) {
        super(props);

        this.show = this.show.bind(this);
        this.hide = this.hide.bind(this);
    }

   
    public show() {
        this.setState({ modalOpened: true });
    }

    public hide() {
        this.setState({ modalOpened: false });
    }

    public render() {

        const modalProps = { ...{ size: 'lg' }, ...this.props.modal };

        return (
            <div>
                <Modal isOpen={this.state.modalOpened} toggle={this.hide}
                    {...modalProps}
                >
                    <ModalHeader toggle={this.hide}>{this.props.modalTitle}</ModalHeader>
                    <ModalBody>
                        {this.props.children}
                    </ModalBody>

                    {this.props.footer &&
                        <ModalFooter className={this.props.footerClass}>
                            {this.props.footer}
                        </ModalFooter>
                    }
                </Modal>
            </div>
        );
    }
}

export default ModalComponent;