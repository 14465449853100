import { ServiceBase, Result, PagedResult, convertStringsToDates } from "./ServiceBase";
import { FileTypeEligiblePending } from "../models/index";

export class FileTypeEligiblePendingService extends ServiceBase {

    public static async list(): Promise<Result<FileTypeEligiblePending[]>> {

        var result = await this.requestJson<FileTypeEligiblePending[]>({
            url: `api/FileTypeEligiblePending/list`,          
            method: "GET"
        });

        return result;
    }   
}