import { ServiceBase, Result } from "./ServiceBase";
import { AppSubmissionReviewMedicalDegree } from "../models/index";


export class AppSubmissionReviewMedicalDegreeService extends ServiceBase {
    public static async list(): Promise<Result<AppSubmissionReviewMedicalDegree[]>> {

        var result = await this.requestJson<AppSubmissionReviewMedicalDegree[]>({
            url: `api/appSubmissionReviewMedicalDegree/list`,
            method: "GET"
        });

        return result;
    }
}
