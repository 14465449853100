import React, { Component, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { AdminRoutes, GuestRoutes, UserRoutes, CommonRoutes, UserRoutesWithoutCurrentAppSubmission } from './AppRoutes';
import AuthenticationContext from './components/context/AuthenticationContext';
import { Layout } from './components/Layout';
import './styles/main.scss';
import { AppRoute } from './models/AppRoute';
import { ApplicationState } from './store';
import * as AuthorizationStore from './store/Authorization';

interface Props {
    children?: React.ReactNode;
    authorization: AuthorizationStore.IState;
    authorizationActions: typeof AuthorizationStore.actionCreators;
}

const App = (props: Props) => {

    const appRoutesToRoutes = (appRoutes: AppRoute[]) => {
        return appRoutes.concat(CommonRoutes).map((route, index) => {
            const { element, ...rest } = route;
            return <Route key={index} {...rest} element={element} />;
        })
    }

    useEffect(() => {

        //console.log('App.effect.isLoggedIn => getCurrentUserCtx');
        props.authorizationActions.getCurrentUserCtx();

    }, [props.authorization.refreshCurrentUserTrigger]);

    return <AuthenticationContext.Provider value={{ currentUser: props.authorization.currentUser }}>
        <Layout>
            <Routes>
                {!props.authorization.isSignedIn && appRoutesToRoutes(GuestRoutes)}
                {props.authorization.isSignedIn && !props.authorization.currentUser.isAdmin && appRoutesToRoutes(UserRoutes)}
                {props.authorization.isSignedIn && !props.authorization.currentUser.isAdmin && !props.authorization.currentAppSubmissionId && appRoutesToRoutes(UserRoutesWithoutCurrentAppSubmission)}
                {props.authorization.isSignedIn && props.authorization.currentUser.isAdmin && appRoutesToRoutes(AdminRoutes)}
            </Routes>
        </Layout>
    </AuthenticationContext.Provider>
}

export default connect(
    (state: ApplicationState) => ({
        authorization: state.authorization
    }), // Selects which state properties are merged into the component's props
    (dispatch, getState) => ({
        authorizationActions: bindActionCreators(AuthorizationStore.actionCreators, dispatch)
    })

)(App as any)