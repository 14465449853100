import * as React from "react";
import * as FormUtils from '../formUtils/index';
import { AlbertaResidencyType, EnglishLanguageProficiency, FileType, IELTSStatus, MedicalDegreeCountry, Province, StatusInCanada, SubmissionCycleSettingsToFileTypeExtensionDate, UserProfile } from "../../models/index";
import { AlbertaResidencyTypeService } from '../../services/AlbertaResidencyTypeService';
import { IELTSStatusService } from '../../services/IELTSStatusService';
import { ProvinceService } from '../../services/ProvinceService';
import { MedicalDegreeCountryService } from '../../services/MedicalDegreeCountryService';
import { EnglishLanguageProficiencyService } from '../../services/EnglishLanguageProficiencyService';
import { StatusInCanadaService } from '../../services/StatusInCanadaService';
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { Formik, FormikProps, FormikValues } from "formik";
import { SubmissionCycleSettings } from "../../models/SubmissionCycleSettings";
import { ValidationSchema } from '../../models/SubmissionCycleSettingsToFileTypeExtensionDate';
import { useEffect, useReducer } from "react";
import { FileTypeService } from "../../services/FileTypeService";
import ShortUniqueId from 'short-unique-id';
import { formatDate } from "../../utils";
import * as ArrayUtils from 'typescript-array-utils';

const uid = new ShortUniqueId({ length: 10 });

interface IProps {
    values: UserProfile;
}

export function SubmissionCycleSettingsEditorFields(props: FormikProps<FormikValues>) {

    const values = props.values as SubmissionCycleSettings;

    return (<div className="form-section">

        <FormUtils.GroupSetDatePicker
            fieldName="ApplicationStart"
            title="Application Start"
            noTime={false}
            date={values?.applicationStart}
            required
        />

        <FormUtils.GroupSetDatePicker
            fieldName="applicationEnd"
            title="Application End"
            noTime={false}
            date={values?.applicationEnd}
            required
        />

        <FormUtils.GroupSetText
            fieldName="questionCompletedCapOrPriorYear"
            title="Question I have completed CAP.."
            explanationText={<><strong>Preview:</strong> I have completed CAP {values.questionCompletedCapOrPriorYear}</>}
        />

        <FormUtils.GroupSetText
            fieldName="questionStatusInCanadaDateBoundary"
            title="Question Status In Canada"
            explanationText={<><strong>Preview:</strong> Permanent Resident as of {values.questionStatusInCanadaDateBoundary}</>}
        />

        <FormUtils.GroupSetText
            fieldName="questionCpsaStatusDateBoundary"
            title="Question Status"
            explanationText={<><strong>Preview:</strong> I have not yet received my results. I understand that I have until {values.questionCpsaStatusDateBoundary} to submit a copy of my IELTS Academic Version Test Report Form...</>}
        />

        <FormUtils.GroupSetText
            fieldName="questionMCCQEPart1ShareScoreOnWebsiteByDateBoundary"
            title="Question Do you currently have a PASS standing for MCCQE Part 1"
            explanationText={<><strong>Preview:</strong> <div className="fst-italic">Please share your MCCQE Part 1 exam on your <a href="https://physicianapply.ca">physicianapply.ca</a> profile by {values.questionMCCQEPart1ShareScoreOnWebsiteByDateBoundary}.</div></>}
        />

        <FormUtils.GroupSetText
            fieldName="questionNACExamShareScoreOnWebsiteByDateBoundary"
            title="Question Do you currently have a pass standing on the NAC Exam?"
            explanationText={<><strong>Preview:</strong> <div className="fst-italic">Please share your NAC exam on your <a href="https://physicianapply.ca">physicianapply.ca</a> profile by {values.questionNACExamShareScoreOnWebsiteByDateBoundary}.</div></>}
        />

        <FormUtils.GroupSetText
            fieldName="questionNACExamNoPassWillBeInDateBoundary"
            title="Question Do you currently have a pass standing on the NAC Exam?"
            explanationText={<><strong>Preview:</strong>I currently do NOT have a PASS score and am registered to take it in {values.questionNACExamNoPassWillBeInDateBoundary}.</>}
        />

        <FormUtils.GroupSetText
            fieldName="questionNACExamRetakeOpportunityDateBoundary"
            title="National Assessment Collaboration (NAC) notes.."
            explanationText={<><strong>Preview:</strong>The last opportunity to take the exam is {values.questionNACExamRetakeOpportunityDateBoundary}.</>}
        />

        <FormUtils.GroupSetText
            fieldName="questionProofOfResidenceInAlbertaDateBoundary"
            title="Question Proof of Residence in Alberta"
            explanationText={<><strong>Preview:</strong> Currently physically present and living on a day-to-day basis in the province of Alberta and have done so continuously since {values.questionProofOfResidenceInAlbertaDateBoundary}.</>}
        />

        <FormUtils.GroupSetText
            fieldName="questionEnglishLanguageProficiencyDateBoundary"
            title="Question English Language Proficiency"
            explanationText={<><strong>Preview:</strong> I have taken/will be taking an approved English Language Proficiency examinations (IELTS - Academic Version, OET, or CELPIP) no earlier than {values.questionProofOfResidenceInAlbertaDateBoundary}.</>}
        />

        <FormUtils.GroupSetText
            fieldName="questionEnglishLanguageProficiencyDateBoundary"
            title="Question English Language Proficiency"
            explanationText={<><strong>Preview:</strong> I have taken/will be taking an approved English Language Proficiency examinations (IELTS - Academic Version, OET, or CELPIP) no earlier than {values.questionProofOfResidenceInAlbertaDateBoundary}.</>}
        />

        <FormUtils.GroupSetText
            fieldName="questionMCCQEPart1DateBoundary"
            title="Question I am registered for a sitting of the MCCQE Part 1 and will share .."
            explanationText={<><strong>Preview:</strong> I am registered for a siting of the MCCQE Part 1 and will share my result with the AIMG Program through physiciansapply.ca no later than {values.questionMCCQEPart1DateBoundary}.</>}
        />

        <FormUtils.GroupSetText
            fieldName="questionMCCQEPart1SessionBoundary"
            title="Question MCCQE Part 1 notes.."
            explanationText={<><strong>Preview:</strong> The last opportunity to take the exam is the {values.questionMCCQEPart1SessionBoundary} session.</>}
        />

        <div className="card mb-3 p-3">
            <h5>Extended Dates</h5>
            <SubmissionCycleSettingsToFileTypeExtensionDateEditor
                {...{
                    items: values.submissionCycleSettingsToFileTypeExtensionDates,
                    onAdd: (item) => {
                        var newSet = (values.submissionCycleSettingsToFileTypeExtensionDates || []).concat([item]);
                        props.setFieldValue('submissionCycleSettingsToFileTypeExtensionDates', newSet);
                    },
                    onDelete: (item) => {
                        var newSet = (values.submissionCycleSettingsToFileTypeExtensionDates || []).filter(x => x._id != item._id);
                        props.setFieldValue('submissionCycleSettingsToFileTypeExtensionDates', newSet);
                    },
                    onSave: (item) => {

                        var index = values.submissionCycleSettingsToFileTypeExtensionDates.findIndex(x => x._id == item._id);

                        var updatedItems = ArrayUtils.replace(values.submissionCycleSettingsToFileTypeExtensionDates, index, item);
                        props.setFieldValue('submissionCycleSettingsToFileTypeExtensionDates', updatedItems);
                    }

                }}
            />
        </div>

    </div>);
}

type SubmissionCycleSettingsToFileTypeExtensionDateEditorState = {
    fileTypes: FileType[],
    modalKey: number;
    showModal: boolean;
    editModel: SubmissionCycleSettingsToFileTypeExtensionDate,
    modalUsedFor: 'add' | 'edit'    
}

function SubmissionCycleSettingsToFileTypeExtensionDateEditor(props: {
    items: SubmissionCycleSettingsToFileTypeExtensionDate[],
    onSave: (items: SubmissionCycleSettingsToFileTypeExtensionDate) => void,
    onAdd: (item: SubmissionCycleSettingsToFileTypeExtensionDate) => void;
    onDelete: (item: SubmissionCycleSettingsToFileTypeExtensionDate) => void;
}) {

    const createNewModel = () => {
        var result = new SubmissionCycleSettingsToFileTypeExtensionDate();
        result._id = uid();
        return result;
    }

    const [state, setState] = useReducer(
        (prevState: SubmissionCycleSettingsToFileTypeExtensionDateEditorState, newState: Partial<SubmissionCycleSettingsToFileTypeExtensionDateEditorState>) => ({ ...prevState, ...newState }),
        {
            fileTypes: [],
            modalKey: 0,
            showModal: false,
            editModel: createNewModel(),
            modalUsedFor: 'add'
        }
    );

    const loadFileTypes = async () => {

        var result = await FileTypeService.list();

        if (!result.hasErrors) {
            setState({ fileTypes: result.value });
        }
    }

    useEffect(() => {
        loadFileTypes();
    }, []);

    return <>
        <table className="table">
            <thead>
                <tr>
                    <th></th>
                    <th>File Type</th>
                    <th>Extended Date</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {state.fileTypes.length > 0 && props.items.map((x, index) =>
                    <tr key={x._id}>
                        <td>{index + 1}</td>
                        <td>{state.fileTypes.find(f => f.id == x.fileTypeId)?.description}</td>
                        <td>{formatDate(x.extendedDate)}</td>
                        <td>
                            <Button type="button" color="primary" onClick={() => {
                                setState({
                                    editModel: x,
                                    showModal: true,
                                    modalKey: state.modalKey + 1,
                                    modalUsedFor: 'edit'
                                })
                            }}>Edit</Button>
                            {' '}
                            <Button type="button" color="primary" onClick={() => {
                                props.onDelete(x);
                            }}>Delete</Button>

                        </td>
                    </tr>
                )}
                <tr>
                    <td colSpan={4}>
                        <Button type="button" color="primary" onClick={() => {
                            setState({
                                editModel: createNewModel(),
                                showModal: true,
                                modalKey: state.modalKey + 1,
                                modalUsedFor: 'add'
                            })
                        }}>Add</Button>
                    </td>
                </tr>
            </tbody>
        </table>
        {state.fileTypes.length > 0 && <SubmissionCycleSettingsToFileTypeExtensionDateEditorModal {...{
            fileTypes: state.fileTypes,
            model: state.editModel,
            key: state.modalKey,
            onSave: (item) => {
                if (state.modalUsedFor == 'add') {
                    props.onAdd(item);
                } else {
                    props.onSave(item);
                }


                setState({ showModal: false })
            },
            isOpened: state.showModal,
            modalUsedFor: state.modalUsedFor,
            onCancel: () => { setState({ showModal: false }) }
        }} />
        }
    </>
}


function SubmissionCycleSettingsToFileTypeExtensionDateEditorModal(props: {
    fileTypes: FileType[],
    model: SubmissionCycleSettingsToFileTypeExtensionDate,
    onSave: (item: SubmissionCycleSettingsToFileTypeExtensionDate) => void,
    isOpened: boolean,
    modalUsedFor: 'add' | 'edit',
    onCancel: () => void
}) {

    return <Formik
        enableReinitialize={true}
        validationSchema={ValidationSchema}
        validateOnBlur={false}
        initialValues={props.model}
        validateOnMount={true}
        onSubmit={(values, actions) => {
            props.onSave(values);
        }}
    >
        {({ values, submitForm, dirty, resetForm }) =>

            <Modal isOpen={props.isOpened} toggle={props.onCancel}>
                <ModalHeader toggle={props.onCancel}>
                    {props.modalUsedFor == 'add' && 'Add new'}
                    {props.modalUsedFor == 'edit' && 'Edit'}
                </ModalHeader>
                <ModalBody>

                    <FormUtils.GroupSetDropdown<FileType>
                        fieldName="fileTypeId"
                        title="File Type"
                        dropdownProps={{
                            dataSource: props.fileTypes,
                            getId: x => x.id,
                            renderItem: x => x.description,
                            renderSelectedItem: x => x.description,
                            selectedValue: values.fileTypeId
                        }}
                    />

                    <FormUtils.GroupSetDatePicker
                        fieldName="extendedDate"
                        title="Extened Date"
                        noTime={true}
                        date={values.extendedDate}
                        pickerConfig={{
                            minDate: new Date(2023, 1, 1),
                            showMonthDropdown: true,
                            showYearDropdown: true,
                            dropdownMode: 'select'
                        }}
                    />

                </ModalBody>

                <ModalFooter className="border-top">

                    <Button color="primary" outline className="mr-2" onClick={() => resetForm()}
                        disabled={!dirty}
                    >Discard Changes</Button>
                    <Button color="primary"
                        //disabled={!canSave}
                        onClick={() => { submitForm() }}>
                        Save
                    </Button>

                </ModalFooter>
            </Modal>
        }
    </Formik>
}