import { Home } from "./pages/Home";
import { Login } from "./components/account/Login";
import { RegisterTabs } from "./components/account/RegisterTabs";
import { AppRoute } from './models/AppRoute';
import { UserProfile } from './pages/UserProfile';
import { ConfirmUserEmail } from './pages/ConfirmUserEmail';
import { ResendEmailConfirmationEmail } from "./components/account/ResendEmailConfirmationEmail";
import { AppUserFiles } from './pages/AppUserFiles';
import { AppExternship } from './pages/AppExternship';
import { AdministrativeExceptions } from './pages/AdministrativeExceptions';
import { ApplicationSubmission } from './pages/ApplicationSubmission';
import { SubmissionCycleSettingsEditorPage } from './pages/SubmissionCycleSettingsEditorPage';
import { SubmissionCycleSettingsListPage } from './pages/SubmissionCycleSettingsListPage';
import { ApplicationSubmissionSearch } from './pages/ApplicationSubmissionSearch';
import { ResetPasswordPage } from './pages/ResetPasswordPage';
import { ForgotPasswordPage } from './pages/ForgotPasswordPage';
import { UsersSearch } from "./pages/UsersSearch";
import { UserAccountEdit } from "./pages/UsersAccountEdit";
import { AdminChat } from "./components/chat/AdminChat";
import { Letters } from "./pages/Letters";
import { Letter } from "./pages/Letter";

import { AESearch } from "./pages/AESearch";
import { AEEdit } from "./pages/AEEdit";

const GuestRoutes: AppRoute[] = [{
    index: true,
    element: <Home />
},
{
    path: '/login',
    element: <Login />
},
{
    path: '/register',
    element: <RegisterTabs />
},
{
    path: '/resendemailconfirmationemail',
    element: <ResendEmailConfirmationEmail />
}
];

const UserRoutes: AppRoute[] = [
    {
        index: true,
        element: <Home />
    },
    {
        path: '/login',
        element: <Login />
    },
    
    {
        path: '/apply',
        element: <ApplicationSubmission mode="apply" />
    },
    {
        path: '/app-submission-view/:id',
        element: <ApplicationSubmission mode="view" />
    },
    {
        path: '/letter/',
        element: <Letter />
    },
];

const UserRoutesWithoutCurrentAppSubmission: AppRoute[] = [
    {
        path: '/userprofile',
        element: <UserProfile />
    },
    {
        path: '/files',
        element: <AppUserFiles />
    },
    {
        path: '/externship',
        element: <AppExternship />
    },
    {
        path: '/administrative-exceptions',
        element: <AdministrativeExceptions />
    },
]

const CommonRoutes: AppRoute[] = [   
    {
        path: '/confirmuseremail',
        element: <ConfirmUserEmail />
    },
    {
        path: '/forgot-password',
        element: <ForgotPasswordPage />
    },    
    {
        path: '/resetpassword',
        element: <ResetPasswordPage />
    }, 
    {
        path: '/user-account-edit/:id',
        element: <UserAccountEdit />
    }
];

const AdminRoutes: AppRoute[] = [
    {
        index: true,
        element: <Home />
    },    
    {
        path: '/app-submission-view/:id',
        element: <ApplicationSubmission mode="view" />
    },
    {
        path: '/userprofile/:id',
        element: <UserProfile />
    },
    {
        path: '/app-submission-search',
        element: <ApplicationSubmissionSearch />
    },
    {
        path: '/submission-cycle-settings-list',
        element: <SubmissionCycleSettingsListPage /> 
    },
    {
        path: '/submission-cycle-settings-edit/:id',
        element: <SubmissionCycleSettingsEditorPage />
    },
    {
        path: '/submission-cycle-settings-add',
        element: <SubmissionCycleSettingsEditorPage />
    },
    {
        path: '/admin-ae-search',
        element: <AESearch />
    },
    {
        path: '/admin-ae-edit/:id',
        element: <AEEdit />
    },
    {
        path: '/users',
        element: <UsersSearch />
    },
    {
        path: '/messages',
        element: <AdminChat />
    },
    {
        path: '/letters',
        element: <Letters />
    }
];

export { GuestRoutes, UserRoutes, AdminRoutes, CommonRoutes, UserRoutesWithoutCurrentAppSubmission };
