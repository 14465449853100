import * as React from 'react';
import { GroupSetCustom, GroupSetProps } from './index';
import { useField } from "formik";
import { GenericDropdown, GenericDropdownProps } from '../controls/GenericDropdown';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { useContext, useEffect, useId, useState } from 'react';
import { FormViewContext } from '../context/FormViewContext';


export function GroupSetDropdownMultiSelect<T, K>(props: GroupSetProps & {
    dropdownProps: {
        dataSource: T[];
        getKey: (item: T) => string,
        renderItem: (item: T) => React.ReactNode | string,
        noSelctionText: React.ReactNode | string,
        isChecked: (item: T, selectedDataSource: K[]) => boolean,
        renderSelected: (selectedDataSource: K[]) => React.ReactNode | string,
        createSelectedItem: (item: T) => K,
        removeSelectedItem: (values: K[], item: T) => K[],
        findBySelectedId: (items: T[], selected: K) => T,

    }
}) {

    const [field, meta, helpers] = useField<K[]>(props.fieldName);
    const checkBoxBaseId = useId();

    const trimText = (text: string): string => {
        if ((text || '').length > 20)
            return text.substr(0, 18) + '..';

        return text;
    }

    return (<GroupSetCustom
        title={props.title}
        fieldName={props.fieldName}
        controlSize={props.controlSize}
        required={props.required}
    >
        <UncontrolledDropdown>
            <DropdownToggle caret>
                {
                    field.value.length > 0
                        ? trimText(field.value.map(x => props.dropdownProps.renderItem(props.dropdownProps.findBySelectedId(props.dropdownProps.dataSource, x))).join(', '))
                        : props.dropdownProps.noSelctionText
                }
            </DropdownToggle>
            <DropdownMenu>
                {props.dropdownProps.dataSource.map(x => <li key={props.dropdownProps.getKey(x)} className="dropdown-item">
                    <input type="checkbox" id={checkBoxBaseId + props.dropdownProps.getKey(x)} className="form-check-input"
                        /*onChange={(e) => this.props.selectChange(cp, e.target.checked)}*/
                        onChange={(e) => {
                            if (e.target.checked) {
                                var newItem = props.dropdownProps.createSelectedItem(x);

                                var newSelectedIds = field.value.concat([newItem]);
                                helpers.setValue(newSelectedIds, true);

                            } else {

                                var newSelectedIds = props.dropdownProps.removeSelectedItem(field.value, x);
                                helpers.setValue(newSelectedIds, true);
                            }

                            if (props.onValueChanged)
                                props.onValueChanged();
                        }}
                        checked={props.dropdownProps.isChecked(x, field.value)}
                    />
                    <label className="ms-1" htmlFor={checkBoxBaseId + props.dropdownProps.getKey(x)}>{props.dropdownProps.renderItem(x)}</label>
                </li>)}
            </DropdownMenu>
        </UncontrolledDropdown>
        {field.value.length > 0 && <small>{field.value.map(x => props.dropdownProps.renderItem(props.dropdownProps.findBySelectedId(props.dropdownProps.dataSource, x))).join(', ')}</small>}
    </GroupSetCustom>)
}

