import * as React from 'react';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, Button, Col, Row, Spinner } from 'reactstrap';
import { useEffect, useReducer } from 'react';
import { Formik, FormikErrors, FormikProps, FormikValues } from 'formik';
import * as FormUtils from '../components/formUtils/index';
import { AccountService } from './../services/AccountService';
import { IdentityResult } from '../models';
import * as yup from 'yup';


type State = {
    isLoading: boolean;
    identityResult?: IdentityResult;
}

type ResetPwdEdit = {
    password: string;
    passwordConfirmation: string;
}

const initialEditObject: ResetPwdEdit = {
    password: '',
    passwordConfirmation: ''
}

const initialState: State = {
    isLoading: false,
    identityResult: undefined
}

const validationSchema = yup.object().shape({
    password: yup.string().required('Required'),
    passwordConfirmation: yup.string()
        .oneOf([yup.ref('password')], 'Passwords must match')
});

export const ResetPasswordPage = () => {

    const [state, setState] = useReducer(
        (prevState: State, newState: Partial<State>) => ({ ...prevState, ...newState }),
        initialState
    );

    const resetPassword = async (password: string) => {

        setState({
            isLoading: true
        });

        const params = new URLSearchParams(window.location.search);
        console.log('params', params);
        var email = params.get('email') || '';
        var token = params.get('token') || '';

        var result = await AccountService.resetPassword(email, token, password);

        if (!result.hasErrors) {

            setState({
                isLoading: false,
                identityResult: result.value
            });

        } else {

            setState({
                isLoading: false
            });

        }
    };

    return <div>
        <h1>Reset Password</h1>

        {(!state.identityResult || !state.identityResult.succeeded) && <Formik enableReinitialize={true}
            initialValues={initialEditObject}
            validationSchema={validationSchema}
            onSubmit={(values: ResetPwdEdit, actions) => {
                resetPassword(values.password)
            }}
        >
            {(formikState) =>
                <>
                    <Row>
                        <Col>
                            <FormUtils.GroupSetText fieldName="password" title="Password" inputProps={{ type: "password" }} />
                            <FormUtils.GroupSetText fieldName="passwordConfirmation" title="Confirm Password" inputProps={{ type: "password" }} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button type="button" color="primary" onClick={() => { formikState.submitForm() }}>Reset Password</Button>
                        </Col>
                    </Row>
                </>
            }
        </Formik>}
        {state.identityResult && state.identityResult.errors.length > 0
            &&
            <Alert color="danger">
                <ul>
                    {state.identityResult?.errors.map(x => <li key={x.code}>{x.description}</li>)}
                </ul>
            </Alert>
        }

        {state.identityResult && state.identityResult?.succeeded && <p>Your password was changed</p>}

    </div>
}
