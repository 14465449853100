import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Button, Col, Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink, Row, Spinner } from 'reactstrap';
import { Link, useParams } from 'react-router-dom';
import ModalComponent from "../ModalComponent";
import { Formik, FormikErrors, FormikProps, FormikValues } from 'formik';
import { useContext, useEffect, useReducer, useRef } from 'react';
import { Result } from '../../services/ServiceBase';
import { UIMessages } from '../UIUtils';
import { createBrowserHistory } from 'history';
import { FormViewContext } from '../context/FormViewContext';
import { ErrorsSummary } from './ErrorsSummary';


type GenericEditorState<T, K> = {
    model?: T;
    modelId?: K;
    errorMessage: string;

    // indicators
    isLoading: boolean;
    isSaving: boolean;
}

function GenericEditor<T, K>(props: {
    getById: (id: K) => Promise<Result<T>>;
    save: (item: T) => Promise<Result<T>>;
    delete?: (id: K) => Promise<Result<{}>>;
    deleleConfirmMessage?: (item: T) => string;
    stringToId: (idString: string) => K;
    getId: (item: T) => K;
    shouldTryToLoad: (id: K) => boolean;
    onSave?: () => void;
    createNewModel?: () => T;
    routes?: {
        list: string;
    };
    validationSchema: any;
    formikFields: (formikState: FormikProps<FormikValues>) => React.ReactElement;
    editorTitle?: string;
    saveButtonsOnTop?: boolean;
    localReloadTrigger?: number;
    allowSaveOnClean?: boolean;
    errorsMap?: { [key: string]: React.ReactNode },
    disableSaveButton?: boolean;

}) {
    const mountRef = useRef(true);

    const confirmDeleteModalRef = useRef<ModalComponent | null>(null);

    const history = createBrowserHistory();

    const formViewContext = useContext(FormViewContext);

    let { id } = useParams();

    let [state, setState] = useReducer(
        (prevState: GenericEditorState<T, K>, newState: Partial<GenericEditorState<T, K>>) => ({ ...prevState, ...newState }),
        {
            model: undefined,
            modelId: undefined,
            errorMessage: '',
            isLoading: true,
            isSaving: false
        }
    );


    const load = (modelId: K) => {

        setState({
            isLoading: true
        })

        if (props.shouldTryToLoad(modelId)) {

            props.getById(modelId)
                .then((result) => {

                    if (!mountRef.current)
                        return;

                    if (result.hasErrors) {
                        setState({
                            isLoading: false,
                        })
                    } else {

                        setState({
                            model: result.value,
                            modelId,
                            isLoading: false,
                        })
                    }
                }, () => {
                    if (!mountRef.current)
                        return;
                });

        } else {
            if (props.createNewModel) {
                let newModel = props.createNewModel();

                setState({
                    model: newModel,
                    modelId: props.getId(newModel),
                    isLoading: false,
                })
            }
        }
    };


    // on route change
    useEffect(() => {

        let modelId = props.stringToId(id as string);

        load(modelId);


    }, [id, props.localReloadTrigger]);


    // on component did mount
    useEffect(() => {

        return () => {
            mountRef.current = false;
        }

    }, []);


    // returns
    if (state.isLoading == true)
        return <Spinner color="primary" />

    if (!state.model)
        return null;

    return (
        <Formik
            enableReinitialize={true}
            validationSchema={props.validationSchema}
            validateOnBlur={false}
            initialValues={state.model as FormikValues}
            validateOnMount={true}
            onSubmit={(values, actions) => {

                props.save(values as T)
                    .then((result) => {

                        if (!mountRef.current)
                            return;

                        if (result.hasErrors) {
                            UIMessages.showSaveFailed();

                            setState({
                                isSaving: false
                            })

                            actions.setErrors(result.errors as FormikErrors<T>);

                        } else {
                            UIMessages.showSaveSuccess();

                            setState({
                                isSaving: false,
                                model: result.value,
                                modelId: props.getId(result.value)
                            })

                            if (props.onSave)
                                props.onSave();
                        }
                    });
            }}
        >
            {(formikState) => {

                let canSave = props.allowSaveOnClean == true
                    ? formikState.isValid && !state.isLoading
                    : formikState.isValid && formikState.dirty && !state.isLoading;

                return (
                    <div>

                        <Row>
                            <Col>
                                <div className="d-flex mb-4">
                                    <div className="flex-grow-1">
                                        {props.editorTitle && <h1>{props.editorTitle}</h1>}
                                    </div>
                                    <div>
                                        {props.routes && <Link to={props.routes.list} className="btn btn-primary-outline border-0"><u>Back to List</u></Link>}
                                        {props.saveButtonsOnTop == true && formViewContext.readonly != true && !props.disableSaveButton &&
                                            <>
                                                <Button type="button" color="link" className="border-0 mr-2" onClick={() => formikState.resetForm()}
                                                    disabled={!formikState.dirty}
                                                ><u>Discard Changes</u></Button>
                                                <Button color="primary"
                                                    //disabled={!canSave}
                                                onClick={() => { formikState.submitForm() }}>
                                                    {state.isSaving && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                                    Save
                                                </Button>
                                            </>
                                        }
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {/*props.formikFields(formikState as any as FormikProps<T>)*/}
                                {props.formikFields(formikState)}
                            </Col>
                        </Row>

                        {props.delete && !props.disableSaveButton && <>
                            <Row>
                                <Col>
                                    <Button color="primary" onClick={() => confirmDeleteModalRef?.current?.show()}>Delete</Button>
                                    <ModalComponent ref={refVal => confirmDeleteModalRef.current = refVal}
                                        modalTitle="Confirm Delete"
                                        footer={<>
                                            <Button color="primary" outline className="mr-2" onClick={() => confirmDeleteModalRef?.current?.hide()}>No</Button>
                                            <Button color="primary" onClick={() => props.delete?.(state.modelId as K).then(del => {
                                                if (mountRef.current == false)
                                                    return;

                                                if (del.hasErrors) {

                                                } else {
                                                    UIMessages.showDeletedMessage();
                                                    if (props.routes) {
                                                        history.push(props.routes.list);
                                                    }
                                                }
                                            })
                                            }>Yes</Button>
                                        </>}
                                    >
                                        {props.deleleConfirmMessage ? props.deleleConfirmMessage(state.model as T) : "Delete?"}
                                    </ModalComponent>
                                </Col>
                            </Row>
                        </>}

                        {props.saveButtonsOnTop != true && formViewContext.readonly != true && !props.disableSaveButton &&
                            <Row>
                                <Col>

                                    <hr />
                                    <div className="d-flex justify-content-between mb-3">
                                        <div>
                                            <Button color="primary" outline
                                                disabled={!formikState.dirty}
                                                onClick={() => { formikState.resetForm(); }}
                                            >
                                                Discard Changes
                                            </Button>
                                            &nbsp;
                                            <Button color="primary"
                                                //disabled={!canSave}
                                                onClick={() => { console.log('formikState', formikState); formikState.submitForm() }}>
                                                {state.isSaving && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                                Save
                                            </Button>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        }

                        {props.errorsMap && <ErrorsSummary dictionary={props.errorsMap} />}

                    </div>
                )
            }}
        </Formik>
    );
}

export default GenericEditor;// (withRouter(GenericEditor as any) as any as typeof GenericEditor);