import { ServiceBase, Result, PagedResult, convertStringsToDates } from "./ServiceBase";
import { StatusInCanada } from "../models/index";

export class StatusInCanadaService extends ServiceBase {

    public static async list(): Promise<Result<StatusInCanada[]>> {

        var result = await this.requestJson<StatusInCanada[]>({
            url: `api/StatusInCanada/list`,          
            method: "GET"
        });

        return result;
    }   
}