import * as React from 'react';
import { useContext, useId } from 'react';
import { ErrorDisplay, GroupSetCustom, GroupSetProps } from './index';
import { useField, Field } from "formik";
import { Label as RLabel } from 'reactstrap';
import { FormViewContext } from '../context/FormViewContext';


export const GroupSetCheckbox = (props: GroupSetProps /*& { value: boolean }*/) => {

    const [field, meta] = useField<boolean>(props.fieldName);


    return (<div className={"form-group form-check mb-4 " + (props.className || '')}>
        <Checkbox name={props.fieldName} value={field.value} title={props.title} onValueChanged={props.onValueChanged} disabled={props.disabled} required={props.required} />
        {meta.error && meta.touched && <ErrorDisplay fieldName={props.fieldName} />}
        {props.explanationText && <div className="explanation">{props.explanationText}</div>}
    </div>);
}

function Checkbox(props) {

    const checkBoxId = useId();
    //const formViewContext = useContext(FormViewContext);



    return (
        <Field name={props.name}>
            {({ field, form }) => {

                return (<>
                    <input
                        id={checkBoxId}
                        {...field}
                        type="checkbox"
                        value="true" // yes, weird but this is how it should be
                        checked={field.value}
                        className="form-check-input"
                        onChange={(e) => {

                            field.onChange(e);

                            if (props.onValueChanged) {
                                console.log('checkBoxId change', e.target.checked);
                                props.onValueChanged();
                            }

                            
                        }}
                        readOnly={props.disabled}
                        disabled={props.disabled}
                    />
                    <RLabel check for={checkBoxId}>
                        {props.title}{props.required && <sup className="required-mark">*</sup>}
                    </RLabel>
                </>)
            }
            }
        </Field>
    );
}