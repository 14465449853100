import { ServiceBase, Result, PagedResult, convertStringsToDates } from "./ServiceBase";
import { TeamReviewNotEligible } from "../models/index";

export class TeamReviewNotEligibleService extends ServiceBase {

    public static async list(): Promise<Result<TeamReviewNotEligible[]>> {

        var result = await this.requestJson<TeamReviewNotEligible[]>({
            url: `api/TeamReviewNotEligible/list`,          
            method: "GET"
        });

        return result;
    }   
}