import { ServiceBase, Result, PagedResult, convertStringsToDates } from "./ServiceBase";
import { UserCreate, CurrentUserCtx, LoginInfo, IdentityResult, UserProfile, AppUserInfo } from "../models/index";
import { DateFields } from "../models/UserProfile";
import { UserProfileListView, DateFields as UserProfileListViewDateFields } from '../models/UserProfileListView';




export class SearchIn extends Object {    
    keywords: string;
    onlyWithUnreadMessages: boolean;
}

export class NonAdminUsersService extends ServiceBase {

    public static async search(model: SearchIn, pageSize: number, offset: number): Promise<Result<PagedResult<AppUserInfo>>> {

        var result = await this.requestJson<PagedResult<AppUserInfo>>({
            url: `api/nonadminusers/search`,
            method: "POST",
            data: {
                ...model, pageSize, offset
            }
        });

        return result;
    }
}