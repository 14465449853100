import * as yup from 'yup';
// use on user register profile
export class UserCreate { 
    userName: string;
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    passwordConfirmation: string;
}

export class LegacyUserCreate {
    email: string;
    password: string;
    passwordConfirmation: string;
}

export type IdentityError = {
    code: string,
    description: string
};

export class IdentityResult {
    errors: IdentityError[];
    succeeded: boolean;
}

export const ValidationSchema = yup.object().shape({
    // userName: yup.string().required('Required'),
    firstName: yup.string().required('Required'),
    lastName: yup.string().required('Required'),
    email: yup.string().required('Required'),
    password: yup.string().required('Required'),
    passwordConfirmation: yup.string()
        .oneOf([yup.ref('password')], 'Passwords must match')
});


export const ValidationSchemaLegacyUser = yup.object().shape({
    //userName: yup.string().required('Required'),
    //firstName: yup.string().required('Required'),
    //lastName: yup.string().required('Required'),
    email: yup.string().required('Required'),
    password: yup.string().required('Required'),
    passwordConfirmation: yup.string()
        .oneOf([yup.ref('password')], 'Passwords must match')
});