import * as React from "react";
import * as FormUtils from '../formUtils/index';
import { UserAdministrativeException } from '../../models/UserAdministrativeException';
import { AdministrativeException } from '../../models/AdministrativeException';
import { Button, Col, Row, Tooltip } from "reactstrap";
import { FormikProps, FormikValues } from "formik";
import { useState } from "react";
import { AdministrativeExceptionService } from '../../services/AdministrativeExceptionService';
import { formatDate, formatDateAndTime } from "../../utils";
//import AuthenticationContext from "../context/AuthenticationContext";
//import { useContext } from "react";

interface IProps {
    values: UserAdministrativeException;
}

type LookupItem = {
    value: number;
    text: string;
}

const Situations: LookupItem[] = [{
    value: 1,
    text: 'Employment letter does no meet the requirement. This may be due to it being provided by the Human Resources department and not including all necessary information.'
}, {
    value: 2,
    text: 'Status in Canada supporting document(s) cannot be submitted by the deadline.'
}, {
    value: 3,
    text: 'Other'
}];

export function UserAdministrativeExceptionAdminFields(props: FormikProps<FormikValues>) {

    const values = props.values as UserAdministrativeException;

    //const authCtx = useContext(AuthenticationContext);

    // const isReadOnly = values.userProfileId.length > 0;
    const isReadOnly = false;

    return (<React.Fragment>

        <div className="form-section">

            <div className="card mb-3 p-3">
                <h3>Administrative Exceptions</h3>
                <Row>
                    <Col sm="12" md="12">
                        <FormUtils.GroupSetDropdown<LookupItem>
                            fieldName="situationId"
                            title="Check the box that BEST describes your situation:"
                            dropdownProps={{
                                dataSource: Situations,
                                getId: x => x.value,
                                renderItem: x => x.text,
                                renderSelectedItem: x => x.text,
                                selectedValue: values.situationId
                            }}
                            disabled={true}
                        />
                        
                    </Col>
                    
                </Row>

                <Row>
                    <Col sm="12" md="12">
                        <FormUtils.GroupSetTextArea
                            fieldName="description"
                            title="Please explain your situation further. Only forms with a detailed explanation will be reviewed. (1000 characters max)"
                            disabled={true}
                            controlSize="large"
                        />
                    </Col>
                </Row>

                <Row>
                    <Col sm="12" md="12">
                        <p><strong>I certify the above information is correct. I understand the instructions provided, and I will provide the required supporting documents.</strong></p>
                        <FormUtils.GroupSetCheckbox
                            fieldName="agree"
                            title="I Agree"
                            explanationText=""
                            disabled={true}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col sm="12" md="12">
                        <FormUtils.GroupSetDropdownAsync<AdministrativeException, number>
                            fieldName="administrativeExceptionId"
                            title="Status"
                            dropdownProps={{
                                loadDataSource: () => AdministrativeExceptionService.list(),
                                getId: x => x.id,
                                renderItem: x => x.value,
                                renderSelectedItem: x => x.value,
                                selectedValue: values.administrativeExceptionId,
                                allowNullSelection: true,
                                nullSelectionText: 'Select'
                            }}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col sm="12" md="12">
                        <FormUtils.GroupSetTextArea
                            fieldName="AdminNote1"
                            title="AE Decision Letter 1"
                            disabled={false}
                            controlSize="large"
                        />
                    </Col>
                </Row>

                <></>

                <hr />

                <FormUtils.GroupSetCheckbox
                    fieldName="hasAE2"
                    title="Add Another Exception"
                    explanationText=""
                    disabled={true}
                />

                {values.hasAE2 && <>
                    <h3>Administrative Exception 2</h3>
                    <Row>
                        <Col sm="12" md="12">
                            <FormUtils.GroupSetDropdown<LookupItem>
                                fieldName="situationId2"
                                title="Check the box that BEST describes your situation:"
                                dropdownProps={{
                                    dataSource: Situations,
                                    getId: x => x.value,
                                    renderItem: x => x.text,
                                    renderSelectedItem: x => x.text,
                                    selectedValue: values.situationId2
                                }}
                                disabled={true}
                            />

                        </Col>

                    </Row>

                    <Row>
                        <Col sm="12" md="12">
                            <FormUtils.GroupSetTextArea
                                fieldName="description2"
                                title="Please explain your situation further. Only forms with a detailed explanation will be reviewed. (1000 characters max)"
                                disabled={true}
                                controlSize="large"
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col sm="12" md="12">
                            <p><strong>I certify the above information is correct. I understand the instructions provided, and I will provide the required supporting documents.</strong></p>
                            <FormUtils.GroupSetCheckbox
                                fieldName="agree2"
                                title="I Agree"
                                explanationText=""
                                disabled={true}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col sm="12" md="12">
                            <FormUtils.GroupSetDropdownAsync<AdministrativeException, number>
                                fieldName="administrativeExceptionId2"
                                title="Status"
                                dropdownProps={{
                                    loadDataSource: () => AdministrativeExceptionService.list(),
                                    getId: x => x.id,
                                    renderItem: x => x.value,
                                    renderSelectedItem: x => x.value,
                                    selectedValue: values.administrativeExceptionId2,
                                    allowNullSelection: true,
                                    nullSelectionText: 'Select'
                                }}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col sm="12" md="12">
                            <FormUtils.GroupSetTextArea
                                fieldName="AdminNote2"
                                title="AE Decision Letter 2"
                                disabled={false}
                                controlSize="large"
                            />
                        </Col>
                    </Row>

                    <hr />

                    <FormUtils.GroupSetCheckbox
                        fieldName="hasAE3"
                        title="Add Another Exception"
                        explanationText=""
                        disabled={true}
                    />

                    {values.hasAE3 && <>
                        <h3>Administrative Exception 3</h3>
                        <Row>
                            <Col sm="12" md="12">
                                <FormUtils.GroupSetDropdown<LookupItem>
                                    fieldName="situationId3"
                                    title="Check the box that BEST describes your situation:"
                                    dropdownProps={{
                                        dataSource: Situations,
                                        getId: x => x.value,
                                        renderItem: x => x.text,
                                        renderSelectedItem: x => x.text,
                                        selectedValue: values.situationId3
                                    }}
                                    disabled={true}
                                />

                            </Col>

                        </Row>

                        <Row>
                            <Col sm="12" md="12">
                                <FormUtils.GroupSetTextArea
                                    fieldName="description3"
                                    title="Please explain your situation further. Only forms with a detailed explanation will be reviewed. (1000 characters max)"
                                    disabled={true}
                                    controlSize="large"
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Col sm="12" md="12">
                                <p><strong>I certify the above information is correct. I understand the instructions provided, and I will provide the required supporting documents.</strong></p>
                                <FormUtils.GroupSetCheckbox
                                    fieldName="agree3"
                                    title="I Agree"
                                    explanationText=""
                                    disabled={true}
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Col sm="12" md="12">
                                <FormUtils.GroupSetDropdownAsync<AdministrativeException, number>
                                    fieldName="administrativeExceptionId3"
                                    title="Status"
                                    dropdownProps={{
                                        loadDataSource: () => AdministrativeExceptionService.list(),
                                        getId: x => x.id,
                                        renderItem: x => x.value,
                                        renderSelectedItem: x => x.value,
                                        selectedValue: values.administrativeExceptionId3,
                                        allowNullSelection: true,
                                        nullSelectionText: 'Select'
                                    }}
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Col sm="12" md="12">
                                <FormUtils.GroupSetTextArea
                                    fieldName="AdminNote3"
                                    title="AE Decision Letter 3"
                                    disabled={false}
                                    controlSize="large"
                                />
                            </Col>
                        </Row>
                    </>}
                </>}

            </div>

        </div>

    </React.Fragment>);
}

