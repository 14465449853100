import { ServiceBase, Result, PagedResult, convertStringsToDates } from "./ServiceBase";

export class SubmitAllUsersResult {
    total: number;
    skiped: number;
    successSubmissions: number;
    failedSubmissions: number;
    failedUserProfileIds: string[];
    successRebuiltMissingFileTypes: number;
    failedRebuiltMissingFileTypes: number;
}


export class SubmissionCycleService extends ServiceBase {

    public static async submitAllUsers(id: number): Promise<Result<SubmitAllUsersResult>> {

        var result = await this.requestJson<SubmitAllUsersResult>({
            url: `api/submissioncycle/SubmitAllUsers`,
            method: "POST",
            data: {
                id: id
            }
        });

        return result;
    }
}