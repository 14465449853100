import * as React from 'react';
import { GroupSetCustom, GroupSetProps } from './index';
import { useField } from "formik";
import { GenericDropdown, GenericDropdownProps } from '../controls/GenericDropdown';
import { useContext } from 'react';
import { FormViewContext } from '../context/FormViewContext';


export function GroupSetDropdown<T>(props: GroupSetProps & { dropdownProps: GenericDropdownProps<T> }) {

    const [field, meta, helpers] = useField(props.fieldName);

    const originalOnChange = props.dropdownProps.onSelectedItemChanged;

    const formViewContext = useContext(FormViewContext);

    return (<GroupSetCustom
        title={props.title}
        fieldName={props.fieldName}
        controlSize={props.controlSize}
        required={props.required}
    >
        <GenericDropdown
            {...props.dropdownProps}
            onSelectedItemChanged={(item) => {

                helpers.setValue(item ? props.dropdownProps.getId(item) : undefined)
                if (originalOnChange)
                    originalOnChange(item);
            }}
            disabled={props.disabled || formViewContext.readonly == true}
        />

    </GroupSetCustom>)
}

