import * as yup from 'yup';
import { SubmissionCycleSettingsToFileTypeExtensionDate } from './.';

// use for log in
export class SubmissionCycleSettings {
    id: number;
    applicationStart: Date;
    applicationEnd: Date;
    questionCompletedCapOrPriorYear: string;
    questionStatusInCanadaDateBoundary: string;
    questionCpsaStatusDateBoundary: string;
    questionMCCQEPart1ShareScoreOnWebsiteByDateBoundary: string;
    questionNACExamShareScoreOnWebsiteByDateBoundary: string;
    questionNACExamNoPassWillBeInDateBoundary: string;
    questionNACExamRetakeOpportunityDateBoundary: string;
    questionProofOfResidenceInAlbertaDateBoundary: string;
    questionEnglishLanguageProficiencyDateBoundary: string;
    questionMCCQEPart1DateBoundary: string;
    questionMCCQEPart1SessionBoundary: string;
    submissionCycleSettingsToFileTypeExtensionDates: SubmissionCycleSettingsToFileTypeExtensionDate[];
}

export const ValidationSchema = yup.object().shape({
    applicationStart: yup.date().required('Required'),
    applicationEnd: yup.date().required('Required'),
    questionCompletedCapOrPriorYear: yup.string().required('Required'),
    questionStatusInCanadaDateBoundary: yup.string().required('Required'),
    questionCpsaStatusDateBoundary: yup.string().required('Required'),
    questionMCCQEPart1ShareScoreOnWebsiteByDateBoundary: yup.string().required('Required'),
    questionNACExamShareScoreOnWebsiteByDateBoundary: yup.string().required('Required'),
    questionNACExamNoPassWillBeInDateBoundary: yup.string().required('Required'),
    questionNACExamRetakeOpportunityDateBoundary: yup.string().required('Required'),
    questionProofOfResidenceInAlbertaDateBoundary: yup.string().required('Required'),
    questionEnglishLanguageProficiencyDateBoundary: yup.string().required('Required'),
    questionMCCQEPart1DateBoundary: yup.string().required('Required'),
    questionMCCQEPart1SessionBoundary: yup.string().required('Required')
});

export const DateFields = ["applicationStart", "applicationEnd"];
