import * as yup from 'yup';
import { AppSubmissionReviewToAppUsers } from './AppSubmissionReviewToAppUsers';
import { AppSubmissionReviewToTeamReviewEligiblePending } from './AppSubmissionReviewToTeamReviewEligiblePending';
import { AppSubmissionReviewToTeamReviewNotEligible } from './AppSubmissionReviewToTeamReviewNotEligible';
import { AppSubmissionReviewToTeamReviewCaRMSEligible } from './AppSubmissionReviewToTeamReviewCaRMSEligible';
import { AppSubmissionReviewToCaRMSEligiblePending } from './AppSubmissionReviewToCaRMSEligiblePending';

class AppSubmissionReview {
    userProfileId: string;
    feePaymentReceived: boolean;
    docMccQ1StatementResult: string;    
    docNacExamStatementResult: string;
    medicalDegreeId: number | null;
    changeOfNameDocument: boolean;
    soloReviewNotes: string;
    teamReviewNotes: string;

    useMccCandidateToSearchPhysiciansapply: boolean;
    reviewStepDate: Date | null;
    reviewStepUserId: string;
    caRmsEligibleStatus: boolean;
    // caRmsEligiblePending: boolean;
    caRmsEligibleUndecided: boolean;
    caRmsIneligibleStatus?: boolean;
    caRmsProfileErrors?: boolean;

    teamReviewDate: Date | null;
    teamReviewUsers: AppSubmissionReviewToAppUsers[];
    teamReviewEligiblePending: AppSubmissionReviewToTeamReviewEligiblePending[];
    teamReviewNotEligible: AppSubmissionReviewToTeamReviewNotEligible[];
    teamReviewCaRMSEligible: AppSubmissionReviewToTeamReviewCaRMSEligible[];
    caRMSEligiblePending: AppSubmissionReviewToCaRMSEligiblePending[];

    additionalNotes: string;
    supportingDocReceivedByDeadline: boolean;
    docsReceivedDate: Date | null;
    lateDocumentNames: string;

    soloAlbertaResidencyScore1?: number;
    soloAlbertaResidencyScore2?: number;
    soloAlbertaResidencyScore3?: number;
    soloAlbertaResidencyScore4?: number;
    soloAlbertaResidencyScore5?: number;
    soloAlbertaResidencyScore6?: number;
    soloAlbertaResidencyScoreFinal?: number;
    teamAlbertaResidencyScore1?: number;
    teamAlbertaResidencyScore2?: number;
    teamAlbertaResidencyScore3?: number;
    teamAlbertaResidencyScore4?: number;
    teamAlbertaResidencyScore5?: number;
    teamAlbertaResidencyScore6?: number;
    teamAlbertaResidencyScoreFinal?: number;

    customLetterText: string;
}

export const DateFields = [
    "reviewStepDate",
    "teamReviewDate",
    "docsReceivedDate"
];
