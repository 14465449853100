import * as yup from 'yup';
//import { AppUserFile } from './AppUserFile';
export class UserAdministrativeExceptionChat
{
    userAdministrativeExceptionChatId: number;
    userAdministrativeExceptionId: number;
    aeType: number;
    chatMessage: string;
    dateAdded: Date;
    isAdmin: boolean;
    adminRead: boolean;
    userRead: boolean;
}

export const DateFields = [
    "dateAdded"
];

export const ValidationSchema = yup.object().shape({
    
});