import * as React from 'react';
import { useReducer, useEffect, useRef, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Spinner, Button, Container, Row, Col, ButtonGroup } from 'reactstrap';
import InfiniteScroll from 'react-infinite-scroller';
import * as ArrayUtils from 'typescript-array-utils';
import ShortUniqueId from 'short-unique-id';
import { DateFields as ChatMessageDateFields, UserOnlineChangedData } from '../../models/ChatMessage';
import { convertStringsToDates } from '../../services/ServiceBase';
//import { UIMessages } from './UIUtils';
//import { PagedResult, Result } from '../services/ServiceBase';

import {
    createSignalRContext, // SignalR
    createWebSocketContext, // WebSocket
    createSocketIoContext, // Socket.io
} from "react-signalr";
import AuthenticationContext from '../context/AuthenticationContext';
import { ChatSendMessageArea } from './ChatSendMessageArea';
import { ChatHistoryArea } from './ChatHistoryArea';
import { AppUserInfo, ChatMessage } from '../../models';
import { NonAdminUsersList } from './adminChat/NonAdminUsersList';
import { ChatByUser } from './adminChat/ChatByUser';
import { boolean } from 'yup';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


type AdminChatState = {
    openedChats: AppUserInfo[];
    activeChatUser?: AppUserInfo;
    online: boolean;
    newReceivedMesssage?: ChatMessage;
    userOnlineChangedData?: UserOnlineChangedData;
    messageViewed?: ChatMessage;
}

const uid = new ShortUniqueId({ length: 10 });

const SignalRContext = createSignalRContext();

export const AdminChat = () => {

    //const authCtx = useContext(AuthenticationContext);

    let [state, setState] = useReducer(
        (prevState: AdminChatState, newState: Partial<AdminChatState>) => ({ ...prevState, ...newState }),
        {
            openedChats: [],
            activeChatUser: undefined,
            online: false,
            newReceivedMesssage: undefined,
            userOnlineChangedData: undefined,
            messageViewed: undefined
        }
    );

    //useEffect(() => {

        //console.log('')

    //}, []);

    SignalRContext.useSignalREffect(
        'SendMessage',

        // eslint-disable-next-line @typescript-eslint/no-empty-function
        (message: ChatMessage) => {
            //setMessage(JSON.stringify(message));
            console.log('SendMessage for admin!', message, "ok");

            message = convertStringsToDates(message, ChatMessageDateFields);

            setState({ newReceivedMesssage: message })

        },
        [],
    );


    SignalRContext.useSignalREffect(
        'UserOnlineChanged',

        // eslint-disable-next-line @typescript-eslint/no-empty-function
        (data: UserOnlineChangedData) => {
            //setMessage(JSON.stringify(message));
            console.log('UserOnlineChanged!', data, "ok");
            setState({ userOnlineChangedData: data });
        },
        [],
    );

    SignalRContext.useSignalREffect(
        'MessageViewed',
        (message: ChatMessage) => {
            //setMessage(JSON.stringify(message));

            message = convertStringsToDates(message, ChatMessageDateFields);
            //console.log('MessageViewed from admin ', message, "ok");

            setState({ messageViewed: message });
        },
        [],
    )

    return <SignalRContext.Provider
        onOpen={(connection) => {
            console.log('admin onOpen')
            setState({ online: true });
        }}
        onClosed={(error) => {
            console.log('admin onClosed');
            setState({ online: false });
        }}
        url={'/chatHub'}
        transport={4} // 4 = long polling
        logMessageContent={true}
        skipNegotiation={false}
        connectEnabled={true}
    >
        <h1>Messages </h1>
        <div className="admin-chat">
            <div className="active-chats mb-3">
                <ButtonGroup>
                    {state.openedChats.map(x => <Button
                        key={x.id.toString()}
                        color="primary"
                        outline
                        onClick={() => setState({ activeChatUser: x })}
                        active={x == state.activeChatUser}
                    >{x.email}
                    </Button>)}
                </ButtonGroup>
            </div>
            <Row>
                <Col md={12} >{state.openedChats.map(x => <ChatByUser
                    key={x.id.toString()}
                    user={x}
                    active={x == state.activeChatUser}
                    newMessage={state.newReceivedMesssage}
                    userOnlineChangedData={state.userOnlineChangedData}
                    onClose={() => {
                        //console.log('close chat' + x.id.toString());
                        //console.log('state.openedChats', state.openedChats);

                        //let currentIndex = state.openedChats.findIndex(i => i.id == x.id);
                        let filteredChats = state.openedChats.filter(inner => inner.id != x.id);
                        //console.log('state.openedChats', filteredChats);

                        setState({
                            openedChats: filteredChats,
                            activeChatUser: undefined
                        })
                    }}
                    messageViewed={state.messageViewed && x.id == state.messageViewed.toUserId ? state.messageViewed : undefined}
                />)}</Col>
                <Col md={12} ><NonAdminUsersList
                    userOnlineChangedData={state.userOnlineChangedData}
                    onOpenChat={(user) => {
                    window.scrollTo({
                        top: 0,
                        behavior: 'smooth',
                    });
                    if (state.openedChats.findIndex(x => x.id == user.id) < 0) {
                        setState({
                            openedChats: state.openedChats.concat([user]),
                            activeChatUser: user
                        })
                    } else {
                        setState({
                            activeChatUser: user
                        })
                    }


                }} /></Col>
            </Row>
        </div>
    </SignalRContext.Provider>
};