import { ServiceBase, Result, PagedResult, convertStringsToDates } from "./ServiceBase";
import { Letters } from "../models/index";

export default class LettersService extends ServiceBase {

    public static async get(id: number): Promise<Result<Letters>> {

        var result = await this.requestJson<Letters>({
            url: `api/Letters/get`,
            method: "GET",
        });

        return result;
    }

    public static async save(letters: Letters): Promise<Result<Letters>> {

        var result = await this.requestJson<Letters>({
            url: `api/Letters/save`,
            method: "POST",
            data: letters,            
        });

        return result;
    }
}