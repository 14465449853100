import { ServiceBase, Result, PagedResult, convertStringsToDates } from "./ServiceBase";
import { UserAdministrativeException, DateFields } from "../models/UserAdministrativeException";
import { UserAdministrativeExceptionUsers } from "../models/UserAdministrativeExceptionUsers";

const preProcessDates = (input: UserAdministrativeException[]) => {
    return input.map(x => convertStringsToDates(x, DateFields));
}
export default class UserAdministrativeExceptionService extends ServiceBase {

    public static async get(): Promise<Result<UserAdministrativeException>> {

        var result = await this.requestJson<UserAdministrativeException>({
            url: `api/UserAdministrativeException/get/`,
            method: "GET"
        });

        return result;
    }

    public static async getAE(id: string): Promise<Result<UserAdministrativeException>> {

        var result = await this.requestJson<UserAdministrativeException>({
            url: `api/UserAdministrativeException/get/${id}`,
            method: "GET"
        });

        return result;
    }

    public static async save(model: UserAdministrativeException): Promise<Result<UserAdministrativeException>> {

        //console.log('saving:', model);

        var result = await this.requestJson<UserAdministrativeException>({
            url: `api/UserAdministrativeException/save`,
            method: "POST",
            data: model
        });

        return result;
    }

    public static async list(): Promise<Result<PagedResult<UserAdministrativeException>>> {

        var result = await this.requestJson<PagedResult<UserAdministrativeException>>({
            url: `api/UserAdministrativeException/list`,
            method: "GET",
        });

        return result;
    }

    public static async listAll(): Promise<Result<PagedResult<UserAdministrativeExceptionUsers>>> {

        var result = await this.requestJson<PagedResult<UserAdministrativeExceptionUsers>>({
            url: `api/UserAdministrativeException/listall`,
            method: "GET",
        });

        return result;
    }
}