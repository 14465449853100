import { ServiceBase, Result, PagedResult, convertStringsToDates } from "./ServiceBase";
import { UserCreate, CurrentUserCtx, LoginInfo, IdentityResult, LegacyUserCreate, LoginResult, AppUserFile } from "../models";

const preProcessDates = (input: AppUserFile[]) => {

    return input.map(x => convertStringsToDates(x, ["fileDate"]));
}

const preProcessDate = (input: AppUserFile) => {
    return convertStringsToDates(input, ["fileDate"]);
}


export default class AppUserFilesService extends ServiceBase {

    public static async list(): Promise<Result<AppUserFile[]>> {

        var result = await this.requestJson<AppUserFile[]>({
            url: `api/AppUserFile/list`,
            method: "GET",            
        }, preProcessDates);

        return result;
    }

    public static async get(id: string): Promise<Result<AppUserFile>> {

        var result = await this.requestJson<AppUserFile>({
            url: `api/AppUserFile/get`,
            method: "GET",

        });

        return result;
    }

    public static async getByUserAndFileType(fileTypeId: number, userId: string): Promise<Result<AppUserFile[]>> {

        var result = await this.requestJson<AppUserFile[]>({
            url: `api/AppUserFile/getByUserAndFileType`,
            method: "POST",
            data: {
                fileTypeId,
                userId
            }
        }, preProcessDates);

        return result;
    }

    public static async save(file: AppUserFile): Promise<Result<AppUserFile>> {

        var result = await this.requestJson<AppUserFile>({
            url: `api/AppUserFile/save`,
            method: "POST",
            data: file,            
        }, preProcessDate, true);

        return result;
    }    

    public static async delete(id: string): Promise<Result<AppUserFile>> {
        var result = await this.requestJson<AppUserFile>({
            url: `/api/AppUserFile/delete/${id}`,
            method: "DELETE"
        });
        return result;
    }
}