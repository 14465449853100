import React, { Component, useContext } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { NavMenu } from './NavMenu';
import { TimeUntilSubmissionEnd } from './TimeUntilSubmissionEnd';
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import AuthenticationContext from './context/AuthenticationContext';
import { UserChat } from './chat/UserChat';

export const Layout = (props: any) => {

    const authCtx = useContext(AuthenticationContext);

    return <div>
        <ToastContainer position='top-right' />
        <NavMenu />
        <TimeUntilSubmissionEnd />
        <Container tag="main">
            <Row>
                <Col>
                    {props.children}
                </Col>
            </Row>
        </Container>
        {authCtx.currentUser?.isAdmin == false && (authCtx.currentUser?.id || '').length > 0 &&  <UserChat />}
    </div>

}