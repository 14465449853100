import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { createRouterReducer, createRouterMiddleware } from '@lagunovsky/redux-react-router'
import { History } from 'history';
import { ApplicationState, reducers } from '.';

export default function configureStore(history: History, initialState?: ApplicationState) {
    const middleware = [
        thunk,
        createRouterMiddleware(history)
    ];

    const rootReducer = combineReducers({
        ...reducers,
        router: createRouterReducer(history)
    });

    const enhancers = [];
    const windowIfDefined = typeof window === 'undefined' ? null : window;
    if (windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__) {
        enhancers.push(windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__());
    }

    return createStore(
        rootReducer,
        initialState,
        compose(applyMiddleware(...middleware), ...enhancers)
    );
}
