import React from 'react';
import { Label, FormGroup, Button, Row, Col, Input } from 'reactstrap';
import { bindActionCreators } from 'redux';
import { useReducer } from 'react';
import { connect } from 'react-redux';
import { Formik, Field, Form } from "formik";
import * as AuthorizationStore from './../../store/Authorization';
import * as Models from './../../models';
import * as yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands, icon } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used
import * as FormUtils from '../formUtils';
import { AccountService }  from '../../services/AccountService';


const ValidationSchema = yup.object().shape({
    email: yup.string().required('Required')    
});


type EditorType = {
    email: string;
}

const initialValues: EditorType = {
    email: ''
}


type State = {
    email: string;
    isLoading: boolean;
    success: boolean;
    identityResult?: Models.IdentityResult;
}



const initialState: State = {
    email: '',
    isLoading: false,
    success: false,
    identityResult: undefined,
}


const ResendEmailConfirmationEmail = () => {

    const [state, setState] = useReducer(
        (prevState: State, newState: Partial<State>) => ({ ...prevState, ...newState }),
        initialState
    );

    const resendEmail = async (email: string) => {

        setState({
            isLoading: true,
            email: email
        });

        var result = await AccountService.resendEmailConfirmationEmail(email);

        if (!result.hasErrors) {
            //result.value.succeeded
            setState({
                identityResult: result.value,
                success: result.value.succeeded,
                isLoading: false
            })

        } else {
            setState({
                isLoading: false
            });
        }

    }



    return <Row>
        <Col sm="12" md="6" lg="4">
            <Formik
                initialValues={initialValues}
                onSubmit={async (values) => {
                    //console.log('submititng..');
                    resendEmail(values.email);
                }}
                validationSchema={ValidationSchema}
            >{({ values }) => <Form>
                <FormUtils.GroupSetText
                    fieldName="email"
                    title="Email"
                    explanationText={"your email here please"}
                    />
                    <Button type="submit" color="primary" className="mt-4">Resend Email Confirmation {state.isLoading && <FontAwesomeIcon icon={solid("spinner")} />}</Button>
            </Form>}

            </Formik>

            {state.success == false && <div className="lead warning">{state.identityResult?.errors[0]?.description}</div>}
            {state.success == true && <div className="lead">We sent a confirmation email to your <strong>{state.email}</strong></div>}
        </Col>
    </Row>
}

export { ResendEmailConfirmationEmail }