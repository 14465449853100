import * as React from 'react';
import { Link, Search, useNavigate } from 'react-router-dom';
import * as Models from '../models/index';
import { useContext, useEffect, useReducer } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { Button, Col, FormGroup, Row, Spinner } from 'reactstrap';
import { formatDate } from '../utils';
import { Formik, FormikErrors, FormikProps, FormikValues } from 'formik';
import * as FormUtils from '../components/formUtils/index';
import { AccountService, SearchIn } from '../services/AccountService';
import AuthenticationContext from '../components/context/AuthenticationContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands, icon } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used

const PAGE_SIZE = 10;

type State = {
    canLoadMore: boolean;
    isLoading: boolean;
    isNoResults: boolean;
    items: Models.UserAccountListView[];
    totalItems: number;
    lastFilter?: SearchIn
    searchTrigger: number;
}

const initialState: State = {
    canLoadMore: false,
    isLoading: false,
    isNoResults: false,
    items: [],
    totalItems: 0,
    lastFilter: undefined,
    searchTrigger: 0
}

export const UsersSearch = () => {

    const navigate = useNavigate();

    const [state, setState] = useReducer(
        (prevState: State, newState: Partial<State>) => ({ ...prevState, ...newState }),
        initialState
    );

    const search = async () => {

        if (state.lastFilter == undefined)
            return;

        setState({
            isLoading: true            
        });

        // 
        const result = await AccountService.search(state.lastFilter as SearchIn, PAGE_SIZE, 0);

        if (!result.hasErrors) {
            setState({
                items: result.value.items,
                totalItems: result.value.totalItems,
                isNoResults: result.value.totalItems == 0,
                isLoading: false,
                canLoadMore: result.value.totalItems > result.value.items.length
            })
        }

    }

    const loadMore = async () => {
        setState({
            isLoading: true
        });

        const result = await AccountService.search(state.lastFilter as SearchIn, PAGE_SIZE, state.items.length);

        if (!result.hasErrors) {

            const newItemsSet = state.items.concat(result.value.items);

            setState({
                items: newItemsSet,
                totalItems: result.value.totalItems,
                isLoading: false,
                canLoadMore: result.value.totalItems > newItemsSet.length
            })
        }
    }

    useEffect(() => {

        search();
    
    }, [state.lastFilter])

    return <>
        <h1>Users</h1>

        <UsersSearchFilters
            onFiltersChange={(filter) => setState({ lastFilter: filter })}
        />


        <InfiniteScroll
            pageStart={0}
            loadMore={loadMore}
            hasMore={state.canLoadMore && !state.isLoading}
            useWindow={false}
            className=""
        ><div className="table-responsive mt-3">
                <table className="table table-hover">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>AIMG ID</th>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Email</th>
                            <th>Locked</th>
                            <th>Edit</th>                            
                        </tr>
                    </thead>
                    <tbody>
                        {state.items.map((item, index) => <tr key={item.id} className="cursor-pointer">
                            <td>{index + 1}</td>
                            <td>{item.userName}</td>
                            <td>{item.firstName}</td>
                            <td>{item.lastName}</td>
                            <td>{item.email}</td>
                            <td>{item.locked &&<FontAwesomeIcon icon={solid("ban")} />}</td>
                            <td><Link to={`/user-account-edit/${item.id}`}>Edit</Link> {item.userProfileId != null && <Link className="ms-3" to={`/userprofile/${item.userProfileId}`}>View Profile</Link>}</td>
                        </tr>)}
                        {state.isNoResults &&
                            <tr>
                                <td colSpan={6}>No Results</td>
                            </tr>
                        }
                        {state.isLoading &&
                            <tr>
                                <td colSpan={6}>
                                    <Spinner color="primary" size="sm" />
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
        </InfiniteScroll>
    </>

};

type UsersSearchFiltersState = {    
    model: SearchIn
}

const initialApplicationSearchFiltersState: UsersSearchFiltersState = {
   
    model: {
        keywords: ''
    }
}

const UsersSearchFilters = (
    props: {
        onFiltersChange: (filters: SearchIn) => void;
    }
) => {

    const [state, setState] = useReducer(
        (prevState: UsersSearchFiltersState, newState: Partial<UsersSearchFiltersState>) => ({ ...prevState, ...newState }),
        initialApplicationSearchFiltersState
    );   

    useEffect(() => {
        props.onFiltersChange(state.model);
    }, []);

    return <>

        <Formik enableReinitialize={true}
            initialValues={state.model}
            onSubmit={(values, actions) => {
                props.onFiltersChange(values);
            }}
        >
            {(formikState) =>
                <>
                        <Row>
                            <Col sm={4} md={3}>
                                <FormUtils.GroupSetText
                                    title="Keywords"
                                    fieldName="keywords"                                
                                />
                            </Col>      
                        </Row>
                   
                    <Row>
                        <Col>
                            <Button type="button" color="primary" onClick={() => { formikState.submitForm() }}>Search</Button>

                            <Button type="button" color="primary" outline className="ms-2" onClick={() => { formikState.resetForm() }}>Clear</Button>
                        </Col>
                    </Row>
                </>
            }
        </Formik>
    </>

}


