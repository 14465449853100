import { Action, Reducer } from 'redux';
import { AppThunkAction } from './index';
import { AppUserInfo, CurrentUserCtx, LoginInfo } from '../models';
import { AccountService } from '../services/AccountService';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface IState {
    // model 
    currentUser: AppUserInfo;
    errorMessage: string;
    isSignedIn: boolean;
    emailConfirmationRequired: boolean;
    showLoginFailedMessage: boolean;
    currentAppSubmissionId?: string;
    // indicators
    isLoading: boolean;
    refreshCurrentUserTrigger: number;
}

export enum Actions {
    LoginRequest = "LoginRequest",
    LoginReceive = "LoginReceive",
    GetCurrentUserCtxRequest = "GetCurrentUserCtxRequest",
    GetCurrentUserCtxReceive = "GetCurrentUserCtxReceive",
    LogoutRequest = "LogoutRequest",
    LogoutRecieve = "LogoutRecieve",
    Fail = "FAIl",
    CleanErrorMessage = "CLEAN_ERROR_MESSAGE"
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

// login
interface LoginRequestAction {
    type: Actions.LoginRequest;
}

interface LoginReceiveAction {
    type: Actions.LoginReceive;
    refreshCurrentUserTrigger: number;
    isSignedIn: boolean;
    emailConfirmationRequired: boolean;
}

interface GetCurrentUserCtxRequestAction {
    type: Actions.GetCurrentUserCtxRequest;
}

interface GetCurrentUserCtxReceiveAction {
    type: Actions.GetCurrentUserCtxReceive;
    data: CurrentUserCtx;
}

// logout
interface LogoutRequestAction {
    type: Actions.LogoutRequest;
}

interface LogoutRecieveAction {
    type: Actions.LogoutRecieve;
}

// fail
interface FailAction {
    type: Actions.Fail;
    message: string;
}

interface CleanErrorMessage {
    type: Actions.CleanErrorMessage;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = FailAction | CleanErrorMessage
    | LoginRequestAction | LoginReceiveAction
    | LogoutRequestAction | LogoutRecieveAction
    | GetCurrentUserCtxReceiveAction | GetCurrentUserCtxRequestAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).


export const actionCreators = {
    login: (loginData: LoginInfo): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        //console.log('requestGrievance id:', id);
        const appState = getState();

        if (appState.authorization?.isLoading)
            return;

        dispatch({ type: Actions.LoginRequest });

        var result = await AccountService.login(loginData);
        //console.log('login data recieved', result);
        const ctx = appState.authorization as IState;

        if (!result.hasErrors) {
            //console.log('login data sucess', result.value);
            dispatch({
                type: Actions.LoginReceive,
                refreshCurrentUserTrigger: result.value ? ctx.refreshCurrentUserTrigger + 1 : ctx.refreshCurrentUserTrigger,
                isSignedIn: result.value.success,
                emailConfirmationRequired: result.value.emailConfirmationRequired
            });
        } else {
            //console.log('login data fail');
            dispatch({
                type: Actions.Fail,
                message: result.errorMessage
            });
        }
    },

    logout: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)


        dispatch({ type: Actions.LogoutRequest });


        var result = await AccountService.logout();

        if (!result.hasErrors) {
            dispatch({
                type: Actions.LogoutRecieve
            });
        } else {
            dispatch({
                type: Actions.Fail,
                message: result.errorMessage
            });
        }

    },

    getCurrentUserCtx: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)


        dispatch({ type: Actions.GetCurrentUserCtxRequest });


        var result = await AccountService.getCurrentUserCtx();

        if (!result.hasErrors) {

            //console.log('getCurrentUserCtx ok', result);

            dispatch({
                type: Actions.GetCurrentUserCtxReceive,
                data: result.value
            });
        } else {

            //console.log('autologin fail');
            dispatch({
                type: Actions.Fail,
                message: result.errorMessage
            });
        }
    }
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const guestUser: AppUserInfo = {
    id: '',
    userName: 'Guest',
    firstName: '',
    lastName: '',
    isAdmin: false,
    email: ''
    //isGuest: true
}

const unloadedState: IState = {
    currentUser: guestUser,
    isLoading: false,
    errorMessage: '',
    isSignedIn: false,
    refreshCurrentUserTrigger: 0,
    emailConfirmationRequired: false,
    showLoginFailedMessage: false
};

export const reducer: Reducer<IState> = (state: IState | undefined, incomingAction: Action): IState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;

    //console.log('auth reducer', action);

    switch (action.type) {

        // get
        case Actions.LoginRequest:

            return {
                ...state,
                isLoading: true,
                showLoginFailedMessage: false
            };


        case Actions.LoginReceive:

            return {
                ...state,
                //currentUser: action.currentUser,
                isSignedIn: action.isSignedIn,
                refreshCurrentUserTrigger: action.refreshCurrentUserTrigger,
                isLoading: false,
                emailConfirmationRequired: action.emailConfirmationRequired,
                showLoginFailedMessage: !action.isSignedIn,

            };


        // save
        case Actions.LogoutRequest:
            return {
                ...state,
                isLoading: true
            };

        case Actions.LogoutRecieve:
            return {
                ...state,
                currentUser: guestUser,
                isLoading: false,
                isSignedIn: false,
                emailConfirmationRequired: false,
                currentAppSubmissionId: undefined
            };

        case Actions.GetCurrentUserCtxRequest:
            return {
                ...state,
                isLoading: true
            };

        case Actions.GetCurrentUserCtxReceive:

            return action.data.appUserInfo == null
                ? {
                    ...state,
                    isSignedIn: false,
                    isLoading: false
                }
                : {
                    ...state,
                    isSignedIn: action.data.isSignedIn,
                    currentUser: action.data.appUserInfo,
                    currentAppSubmissionId: action.data.currentAppSubmissionId,
                    isLoading: false
                };

        // fail
        case Actions.Fail: {
            return {
                ...state,
                isLoading: false,
                errorMessage: action.message
            };
        }

        case Actions.CleanErrorMessage: {

            return {
                ...state,
                errorMessage: ''
            };
        }

        default:
            return state;
    }

}
