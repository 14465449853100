import React from 'react';
import { Label, FormGroup, Button, Alert, NavLink, Row, Col, Spinner } from 'reactstrap';
import { Formik, Field, Form } from "formik";
import { UserCreate } from './../../models';
import { AccountService } from './../../services/AccountService';
import { IdentityResult } from '../../models';
import { useReducer } from 'react';
import { Link } from 'react-router-dom';
import * as FormUtils from '../formUtils';
import { ValidationSchema } from './../../models/UserRegister';

type RegisterState = {
    isLoading: boolean;
    identityResult?: IdentityResult;
    showForm: boolean;
    email: string;
}

const initialValues: UserCreate = {
    userName: '',
    email: '',
    firstName: '',
    lastName: '',
    password: '',
    passwordConfirmation: ''
}

export const Register = () => {

    const [state, setState] = useReducer(
        (prevState: RegisterState, newState: Partial<RegisterState>) => ({ ...prevState, ...newState }),
        {
            isLoading: false,
            identityResult: undefined,
            showForm: true,
            email: ''
        }
    );

    return <Row>
        <Col sm="12" md="6" lg="4">
            <h3>Register</h3>
            <p>If you are new to the AIMG Program or applied prior to 2018, please use this form to create a new profile.</p>
            <p>If you applied between 2018 and 2022, please use the Re-Register With Existing Account form so we can import your existing profile.</p>
            {state.showForm && <Formik
                initialValues={initialValues}
                validationSchema={ValidationSchema}
                onSubmit={async (values) => {

                    setState({ isLoading: true, email: values.email })

                    var result = await AccountService.createUser(values);

                    setState({
                        identityResult: result.value,
                        isLoading: false,
                        showForm: !(result.value?.succeeded == true)
                    });
                }}
            >
                <Form className="mb-3">
                    {/*<FormUtils.GroupSetText fieldName="userName" title="AIMG ID" />*/}
                    <FormUtils.GroupSetText fieldName="firstName" title="First Name" />
                    <FormUtils.GroupSetText fieldName="lastName" title="Last Name" />
                    <FormUtils.GroupSetText fieldName="email" title="Email" />
                    <p>Your password must be at least ten characters. It must include a number, an uppercase letter, a lowercase letter, and a special character.</p>
                    <FormUtils.GroupSetText fieldName="password" title="Password" inputProps={{ type: "password" }} />
                    <FormUtils.GroupSetText fieldName="passwordConfirmation" title="Confirm Password" inputProps={{ type: "password" }} />

                    <Button color="primary" type="submit" className="px-4 mt-4" disabled={state.isLoading}>Register{state.isLoading && <Spinner color="light" size="sm" className="ms-1" ></Spinner>}</Button>
                </Form>
            </Formik>}
            {(state.identityResult?.errors || []).length > 0 &&
                <Alert color="danger">
                    <ul>
                        {state.identityResult?.errors.map(x => <li key={x.code}>{x.description}</li>)}
                    </ul>
                </Alert>
            }
            {state.identityResult?.succeeded == true &&
                <Alert color="light">
                    We sent an email confirmation to <strong>{state.email}</strong>, you can <NavLink tag={Link} className="text-dark d-inline-block" to="/login">login</NavLink> to start.
                </Alert>
            }

        </Col>
    </Row>
}