import * as React from 'react';
import { useContext, useEffect, useReducer, useState } from 'react';
import AuthenticationContext from '../components/context/AuthenticationContext';
import GenericEditor from '../components/editors/GenericEditor'
import UserAdministrativeExceptionService from '../services/UserAdministrativeExceptionService';
import { UserAdministrativeException, ValidationSchema } from '../models/UserAdministrativeException';
import { UserAdministrativeExceptionEditorFields } from '../components/editorFields/UserAdministrativeExceptionEditorFields';
import * as AuthorizationStore from './../store/Authorization';

import { AppUserFilesSearch } from '../components/views/AppUserFilesSearch';

type State = {
    isLoading: boolean;
    isNoResults: boolean;
    items: UserAdministrativeException[];
    totalItems: number;
}

const initialState: State = {
    isLoading: false,
    isNoResults: false,
    items: [],
    totalItems: 0
}

export const AdministrativeExceptions = () => {

    const authCtx = useContext(AuthenticationContext);

    let administrativeException = '';
    if (authCtx && authCtx.currentUser && authCtx.currentUser.administrativeExceptionId) {
        switch (authCtx.currentUser.administrativeExceptionId) {
            case 1:
                administrativeException = 'AE Submitted';
                break;
            case 2:
                administrativeException = 'Additional Information Requierd';
                break;
            case 3:
                administrativeException = 'Reviewing AE Request';
                break;
            case 4:
                administrativeException = 'AE Decision / Granted';
                break;
            case 5:
                administrativeException = 'AE Decision / Denied';
                break;
            case 6:
                administrativeException = 'AE Decision Not Applicable';
                break;
        }
    }

    const [state, setState] = useReducer(
        (prevState: State, newState: Partial<State>) => ({ ...prevState, ...newState }),
        initialState
    );

    const list = async () => {

        setState({
            isLoading: true
        });

        // 
        const result = await UserAdministrativeExceptionService.list();

        if (!result.hasErrors) {
            setState({
                items: result.value.items,
                totalItems: result.value.totalItems,
                isNoResults: result.value.totalItems == 0,
                isLoading: false
            })
        }

        console.log('AE Items', state.items);
    }

    useEffect(() => {
        list();
    }, [])

    return <>

        {/*
        <div className="disclaimer-box for-fields col-sm-12">
            <p className="disclaimer">NOTES</p>

            <p className="disclaimer">Do not use this process to request approval for time out of province.</p>

            <p className="disclaimer">Do not use this process for English Language Proficiency (ELP) testing exemptions. Instead, visit our website and click the survey link provided for that process.<br />
                <a href="https://www.aimg.ca/eligibility-supporting-documents-requirements/english-language-proficiency/" target="_blank" rel="noreferrer">English Language Proficiency Exemptions</a></p>

            <p className="disclaimer">Submitting an Administrative Exception request does not alter the Important Dates and Deadlines to which all applicants must adhere, unless an AE is granted for an alternative deadline.</p>

            <p className="disclaimer"><strong>Information provided as part of the AE request process do not become part of the applicant's AIMG Program application.</strong></p>
        </div>
        */}

        {/*{state.items.map((item, index) => <>*/}
        <div className="disclaimer-box for-fields col-sm-12">
            <p className="disclaimer">Administrative Exception Requests can no longer be accepted as of 3:30pm MT May 26, 2024</p>
            <p className="disclaimer">Submitting an Administrative Exception request does not alter the Important Dates and Deadlines to which all applicants must adhere, unless an AE is granted for an alternative deadline.</p>
            <p className="disclaimer">Documents uploaded to the Administrative Exceptions tab as part of the AE request process do not become part of the applicant's AIMG Program application.</p>
            {/*<p className="disclaimer">The main supporting documents deadline was 3:30pm MT June 7, 2024. We are downloading locked files. Applicants will not be able to access their online application while we complete this work. Thank you for your patience.</p>*/}
        </div>

            <GenericEditor<UserAdministrativeException, string>
                getById={id => UserAdministrativeExceptionService.get()}
                save={model => UserAdministrativeExceptionService.save(model)}
                formikFields={UserAdministrativeExceptionEditorFields}
                validationSchema={ValidationSchema}
                getId={x => x.userProfileId}
                stringToId={x => x}
                shouldTryToLoad={id => true}
                disableSaveButton={false}
                errorsMap={{
                    situationId: "The box that BEST describes your situation is required.",
                    description: "Explain your situation is required.",
                    agree: "You must Agree before proceeding.",

                    situationId2: "The box that BEST describes your situation is required for administrative exception 2.",
                    description2: "Explain your situation is required for administrative exception 2.",
                    agree2: "You must Agree before proceeding for administrative exception 2.",

                    situationId3: "The box that BEST describes your situation is required for administrative exception 3.",
                    description3: "Explain your situation is required for administrative exception 3.",
                    agree3: "You must Agree before proceeding for administrative exception 3."
                }}
            />

        {/*</>)}*/}

        <AppUserFilesSearch aeOnly={false} />
    </>;

};
