import * as React from 'react';
import { useReducer, useEffect, useRef, useContext, useState } from 'react';
import { Link, useFetcher } from 'react-router-dom';
import { Spinner, Button, Container, Row, Col } from 'reactstrap';
import InfiniteScroll from 'react-infinite-scroller';
import * as ArrayUtils from 'typescript-array-utils';
import ShortUniqueId from 'short-unique-id';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands, icon } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used
import { DateFields as ChatMessageDateFields, MessageViewedInfo } from '../../models/ChatMessage';
import { convertStringsToDates } from '../../services/ServiceBase';
//import useDebounce from '../../../utils';
import debounce from 'lodash.debounce';

import {
    createSignalRContext, // SignalR
    createWebSocketContext, // WebSocket
    createSocketIoContext, // Socket.io
} from "react-signalr";

import AuthenticationContext from '../context/AuthenticationContext';
import { ChatSendMessageArea } from './ChatSendMessageArea';
import { ChatHistoryArea } from './ChatHistoryArea';
import { ChatMessage } from '../../models';
import { ChatMessagesService, SearchIn } from '../../services/ChatMessagesService';
import { useDebounce } from '../../utils';

//import * as signalR from "@microsoft/signalr";


type UserChatState = {
    isLoading: boolean,
    isNoResults: boolean,
    canLoadMore: boolean,
    //messages: ChatMessage[],
    online: boolean;
    isConnecting: boolean;
    opened: boolean;
    unreadCount: number;
    isLoadingHistory: boolean;
    totalMessages: number;
    //scrollToBottomTrigger: number;
    newMessage?: ChatMessage;
    messageViewed?: ChatMessage;
    isChatEnabled: boolean;
    updateUnreadCountTrigger: number;
    //signalRConnectEnabled: boolean;
}


const SignalRContext = createSignalRContext();

const uid = new ShortUniqueId({ length: 10 });
const PAGE_SIZE = 10;

export const UserChat = () => {

    const authCtx = useContext(AuthenticationContext);

    let [state, setState] = useReducer(
        (prevState: UserChatState, newState: Partial<UserChatState>) => ({ ...prevState, ...newState }),
        {
            isLoading: false,
            isNoResults: false,
            canLoadMore: false,
            //messages: [],
            online: false, // user online info
            isConnecting: false,
            opened: false,
            unreadCount: 0,
            isLoadingHistory: true,
            totalMessages: 0,
            //scrollToBottomTrigger: 0,
            newMessage: undefined,
            messageViewed: undefined,
            isChatEnabled: false,
            updateUnreadCountTrigger: 0,
            //signalRConnectEnabled: true
        }
    );

    const debouncedUpdateUnreadTrigger = useDebounce(state.updateUnreadCountTrigger, 2000);

    const checkChatIsEnabled = async () => {

        var result = await ChatMessagesService.isChatEnabled();

        if (!result.hasErrors && result.value != state.isChatEnabled) {
            setState({ isChatEnabled: result.value });
        }
    }


    const loadUnreadCount = async () => {

        var resp = await ChatMessagesService.getUnreadCountByUserForUser(authCtx.currentUser?.id as string);

        if (!resp.hasErrors) {
            setState({ unreadCount: resp.value });
        }
    }

    SignalRContext.useSignalREffect(
        'SendMessage',

        // eslint-disable-next-line @typescript-eslint/no-empty-function
        (message) => {
            //setMessage(JSON.stringify(message));

            message = convertStringsToDates(message, ChatMessageDateFields);
            //console.log('SendMessage from admin ', message, "ok");
            //increaseUpdateUnreadCountTrigger();
            //console.log('state.updateUnreadCountTrigger.useSignalREffect', updateUnreadCountTriggerRef.current);
            setState({                
                newMessage: message,
                updateUnreadCountTrigger: state.updateUnreadCountTrigger + 1                
            });

            

            //loadUnreadCount();
            //setUpdateUnreadCountTrigger(updateUnreadCountTrigger + 1);
        },
        [state.updateUnreadCountTrigger],
    );

    SignalRContext.useSignalREffect(
        'MessageViewed',
        (message: ChatMessage) => {
            //setMessage(JSON.stringify(message));

            message = convertStringsToDates(message, ChatMessageDateFields);
            //console.log('MessageViewed from admin ', message, "ok");

            setState({ messageViewed: message });
            
        },
        [],
    )

    useEffect(() => {

        //console.log('useEffect.debouncedUpdateUnreadTrigger');

        loadUnreadCount();      

    }, [debouncedUpdateUnreadTrigger]);

    // load history on chat opened
    useEffect(() => {

        // todo reset something??

    }, [authCtx.currentUser?.id, state.opened]);


    // read unread messages count
    useEffect(() => {

        checkChatIsEnabled();

        if (authCtx.currentUser?.id)
            loadUnreadCount();

    }, [authCtx.currentUser?.id]);

    useEffect(() => {

        window.onbeforeunload = async function (e) {
            e.preventDefault();
            await ChatMessagesService.goOffline();
            return true;
        };

    }, []);
   

    if (!state.isChatEnabled)
        return <></>;

    return <SignalRContext.Provider
        onOpen={(connection) => {
            console.log('onOpen')
            setState({ online: true });
        }}
        onClosed={(error) => {
            console.log('onClosed');
            setState({ online: false });
        }}
        onBeforeClose={(connection) => { connection.stop() }}
        url={'/chatHub'}
        transport={4} // LongPolling
        logMessageContent={true}
        //skipNegotiation={false}
        //connectEnabled={state.signalRConnectEnabled}
    > <div className={"chat fixed-right " + (state.opened ? ' opened ' : ' closed ')}>
            <div className="chat-header">
                <div className="d-flex justify-content-between">
                    <h4 className="chat-title">Chat {/*state.online && <FontAwesomeIcon icon={solid("globe")} />}
                        {!state.online && <FontAwesomeIcon icon={solid("triangle-exclamation")} />*/}</h4>
                    <button className="btn chat-close" onClick={(e) => { e.preventDefault(); setState({ opened: !state.opened }) }}></button>
                    
                </div>
                {/*<div>
                    {state.unreadCount > 0 &&
                        <span className="badge rounded-pill bg-danger">
                            {state.unreadCount}
                            <span className="visually-hidden">unread messages</span>
                        </span>}
                </div>*/}
            </div>
            <div className="chat-icon">
                <a href="#" onClick={(e) => { e.preventDefault(); setState({ opened: !state.opened }) }}>
                    {state.opened && <FontAwesomeIcon icon={solid("angle-down")} />}
                    {!state.opened && <FontAwesomeIcon icon={solid("comments")} />}
                </a>
                {state.unreadCount > 0 &&
                    <span className="badge rounded-pill bg-danger unread-count position-absolute top-0 end-0">
                        {state.unreadCount}
                        <span className="visually-hidden">unread messages</span>
                    </span>}
            </div>
            {state.opened && <p className="m-3">This chat is monitored by a live person during standard working hours.  It is to be used for help for supporting document upload ONLY.  All other questions must be sent to <a href="mailto:aimg@ucalgary.ca">aimg@ucalgary.ca</a></p>}
            <ChatHistoryArea
                //opened={state.opened}
                loadMessages={(refDate, refId) => {

                    var searchReq: SearchIn = {
                        userId: authCtx.currentUser?.id as string
                    };

                    return ChatMessagesService.getMessagesForUser(searchReq, PAGE_SIZE, refDate, refId);
                }}
                newMessage={state.newMessage}
                messageViewed={state.messageViewed}
            />
            <ChatSendMessageArea
                onSend={(message) => {

                    if (message.trim().length == 0)
                        return;

                    var payload: ChatMessage = {
                        created: new Date(),
                        clientId: uid(),
                        fromUserId: authCtx.currentUser?.id as string,
                        message: message,
                        id: 0,
                        readAt: undefined,
                        toUserId: undefined
                    }

                    setState({
                        newMessage: payload
                    })
                }}
            />
        </div>
    </SignalRContext.Provider >
};