import * as React from 'react';
import { GroupSetCustom, GroupSetProps } from './index';
import { useField } from "formik";
import Dropzone from 'react-dropzone';
import { bytesToFriendly } from '../../utils';


export const GroupSetFileUpload = (props: GroupSetProps & {
    names?: {
        fileBinary?: string;
        fileName?: string;
        fileSize?: string;
        fileType?: string;
    }
}) => {

    let names = {
        ...{
            fileBinary: 'fileBinary',
            fileName: 'fileName',
            fileSize: 'fileSize',
            fileType: 'fileMimeType'
        }, ...props.names || {}
    };

    const [fieldBinary, metaBinary, helpersBinary] = useField(names.fileBinary);
    const [fieldName, metaName, helpersName] = useField(names.fileName);
    const [fieldSize, metaSize, helpersSize] = useField(names.fileSize);
    const [fieldType, metaType, helpersType] = useField(names.fileType);

    const fileSizeTooLow = fieldSize.value != undefined && fieldSize.value < 1024;
    const fileSizeTooHigh = fieldSize.value != undefined && fieldSize.value > 16000000;

    return (<GroupSetCustom {...props}>
        <Dropzone onDrop={acceptedFiles => {

            if (acceptedFiles.length == 1) {
                let file = acceptedFiles[0];
                helpersBinary.setValue(file);
                helpersName.setValue(file.name);
                helpersSize.setValue(file.size);
                helpersType.setValue(file.type);
            }
        }}>
            {({ getRootProps, getInputProps }) => (
                <section>
                    <div {...getRootProps()} className="dropzone-files">
                        <input {...getInputProps()} />
                        <div className="dropzone-message">
                            <i className="fas fa-cloud-upload-alt"></i>
                            <div>
                                Drag and drop a file from an open Explorer window,<br />
                                or click within this grey box to browse for a file.
                            </div>
                        </div>
                        <div>
                            <b>{fieldName.value}</b>
                        </div>
                        {(fieldName.value || '').length > 0 && <div>
                            size {bytesToFriendly(fieldSize.value)}
                            {fileSizeTooLow && <span className="file-warning">warning file size too low</span>}
                            {fileSizeTooHigh && <span className="file-warning">Warning: the file is too big and can't be uploaded.  Please limit to 20MB or smaller.</span>}
                        </div>}
                    </div>
                </section>
            )}
        </Dropzone>
    </GroupSetCustom>);
}