import Axios, { AxiosRequestConfig } from "axios";
import parse from "json-to-url";


export interface IRequestOptions {
    url: string;
    data?: any;
    method: "GET" | "POST" | "PUT" | "PATCH" | "DELETE";
}

export interface ISendFormDataOptions {
    url: string;
    data: FormData;
    method: "POST" | "PUT" | "PATCH";
}

/**
 * Represents base class of the isomorphic service.
 */
export abstract class ServiceBase {

    /**
     * Make request with JSON data.
     * @param opts
     */
    public static async requestJson<T>(opts: IRequestOptions, preProcessJson?: (data: any) => any, useMultiPart?: boolean): Promise<Result<T>> {

        var axiosResult: any = null;
        var result: Result<T>;

        //opts.url = transformUrl(opts.url); // Allow requests also for the Node.

        var processQuery = (url: string, data: any): string => {
            if (data) {
                return `${url}?${parse(data)}`;
            }
            return url;
        };

        var axiosRequestConfig: AxiosRequestConfig = {};
        var payload = opts.data;

        if (useMultiPart == true) {
            axiosRequestConfig = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            };

            payload = convertModelToFormData(opts.data);
            //console.log('axios', opts.url, opts.method);
            //console.log('axios data', opts.data);
            //console.log('axios payload', payload);
        } else {
            axiosRequestConfig = {
                headers: { 'Content-Type': 'application/json' }
            };
        }

        try {
            switch (opts.method) {
                case "GET":
                    axiosResult = await Axios.get(processQuery(opts.url, payload), axiosRequestConfig);
                    break;
                case "POST":
                    axiosResult = await Axios.post(opts.url, payload, axiosRequestConfig);
                    break;
                case "PUT":
                    axiosResult = await Axios.put(opts.url, payload, axiosRequestConfig);
                    break;
                case "PATCH":
                    axiosResult = await Axios.patch(opts.url, payload, axiosRequestConfig);
                    break;
                case "DELETE":
                    axiosResult = await Axios.delete(processQuery(opts.url, payload), axiosRequestConfig);
                    break;
            }

            var rowData = preProcessJson
                ? preProcessJson(axiosResult.data)
                : axiosResult.data;

            result = Result.FromValue(rowData) as Result<T>;
            console.log('axiosResult ' + opts.url, opts.data, axiosResult);
            //result = new Result(axiosResult.data, ...axiosResult.data.errors);
        } catch (error: any) {

            console.warn('Service Base error catch', error);

            if (error.response.status == 400) {

                var convertedErrors = convertDictionaryKeysToCamelStyle(error.response.data.errors);
                console.warn('processing bad request:', error.response);

                var errorTitle = error.response?.data?.title as string;
                result = new Result() as Result<T>;
                result.errors = convertedErrors;
                result.errorMessage = errorTitle;
                return result;

                //return Result.FromErrors(convertedErrors, errorTitle);                
            } else {
                console.warn('service unproceessed responce:', error);
            }

            if (error.response) {
                console.log(error.response.data);
                console.log('general error', error.response.data['']);
            }

            console.log('axiosResult error', error);
            console.log('axiosResult error.response', error.response);
            result = Result.FromErrors([], error.message) as Result<T>;
        }

        if (result.hasErrors) {
            //Ui.showErrors('Server error');
        }

        return result;
    }
}

function convertModelToFormData(model: any, form?: FormData, namespace = ''): FormData {
    let formData = form || new FormData();
    let formKey;

    // check if it is primitive
    if (model !== Object(model)) {
        let formKey = namespace;
        formData.append(formKey, model.toString());

    } else {
        for (let propertyName in model) {
            if (!model.hasOwnProperty(propertyName) || !model[propertyName]) continue;
            let formKey = namespace ? `${namespace}[${propertyName}]` : propertyName;
            if (model[propertyName] instanceof Date)
                formData.append(formKey, model[propertyName].toISOString());
            else if (model[propertyName] instanceof Array) {
                model[propertyName].forEach((element: any, index: number) => {
                    const tempFormKey = `${formKey}[${index}]`;
                    convertModelToFormData(element, formData, tempFormKey);
                });
            }
            else if (typeof model[propertyName] === 'object' && !(model[propertyName] instanceof File))
                convertModelToFormData(model[propertyName], formData, formKey);
            else {
                if (model[propertyName] instanceof File) {
                    formData.append(formKey, model[propertyName]);
                } else {
                    formData.append(formKey, model[propertyName].toString());
                }
            }
        }
    }

    return formData;
}

function convertDictionaryKeysToCamelStyle(input: any): { [id: string]: any } {

    if (!input)
        return input;

    var newResult: { [id: string]: any } = {};
    var x;
    for (x in input) {

        var propName = convertStringToCamelCase(x);
        var propValue = input[x];

        var newObjProp: { [id: string]: any } = {};
        newObjProp[propName] = propValue;
        newResult[convertStringToCamelCase(x)] = input[x];
    }

    //console.log('convertDictionaryKeysToCamelStyle input: ', input);
    //console.log('convertDictionaryKeysToCamelStyle output: ', newResult);

    return newResult;
}

function convertStringToCamelCase(input: string) {

    if (!input)
        return input;

    if (input.length == 1)
        return input.toLowerCase();

    return input[0].toLowerCase() + input.substring(1);
}

export class Result<T> {
    public value: T;
    public errors: { [id: string]: any };
    public errorMessage: string;
    public get hasErrors(): boolean {

        if ((this.errorMessage || '').length > 0)
            return true;

        if (this.errors != null && this.errors != undefined && (this.errors || []).length > 0)
            return true;

        return false;
    }


    public static FromValue<T>(value: T) {
        var result = new Result();
        result.value = value;
        return result;
    }

    public static FromErrors<T>(errors: { [id: string]: any }, errorMessage: string) {

        var result = new Result();
        result.errors = errors;
        result.errorMessage = errorMessage;
        return result;

    }
}

export class PagedResult<T> {
    public items: T[];
    public totalItems: number;
}

export function convertStringsToDates(rawData: any, keys: string[]): any {
    if (rawData == null)
        return rawData;

    for (var i in keys) {
        if (rawData.hasOwnProperty(keys[i])) {
            var s = rawData[keys[i]];

            if (s && s.length > 0) {
                var miliseconds = Date.parse(s);
                rawData[keys[i]] = new Date(miliseconds - (7 * 3600 * 1000));
            }

        } else {
            console.warn(`#convertStringsToDates: key ${keys[i]} not exists in provided object`);
        }
    }

    return rawData;
}