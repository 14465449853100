import * as React from 'react';
//import { useState } from 'react';
//import { AppUserFilesEditor } from '../components/editors/AppUserFilesEditor';
import { AppUserFilesSearch } from '../components/views/AppUserFilesSearch';

export const AppUserFiles = () => {

    return <>

        {/*<div className="disclaimer-box for-fields col-sm-12">
            <p className="disclaimer">The main supporting documents deadline was 3:30pm MT June 7, 2024. We are downloading locked files. Applicants will not be able to access their online application while we complete this work. Thank you for your patience.</p>
        </div>*/}

        <AppUserFilesSearch aeOnly={false} />
    </>
    
};
