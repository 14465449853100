import { ServiceBase, Result, PagedResult, convertStringsToDates } from "./ServiceBase";
import { ChatMessage } from "../models";
import { DateFields } from '../models/ChatMessage';



export class SearchIn extends Object {
    userId: string;

    //offsetDate?: Date;

}

export class MarkMessageAsReadOut {
    id: number;
    readAt: Date;
}

export class ChatMessagesService extends ServiceBase {

    public static async getUnreadCountByUserForUser(userId: string): Promise<Result<number>> {

        var result = await this.requestJson<number>({
            url: `api/chatMessages/GetUnreadCountByUserForUser/` + userId,
            method: "GET"
        });

        return result;
    }

    public static async goOffline(): Promise<Result<boolean>> {

        var result = await this.requestJson<boolean>({
            url: `api/chatMessages/goOffline`,
            method: "POST"
        });

        return result;
    }

    public static async checkIsOnline(userId: string): Promise<Result<boolean>> {

        var result = await this.requestJson<boolean>({
            url: `api/chatMessages/CheckIsOnline/` + userId,
            method: "GET"
        });

        return result;
    }

    public static async getUnreadCountByUserForAdmin(userId: string): Promise<Result<number>> {

        var result = await this.requestJson<number>({
            url: `api/chatMessages/getUnreadCountByUserForAdmin/` + userId,
            method: "GET"
        });

        return result;
    }

    public static async getUnreadCountForAdmin(): Promise<Result<number>> {

        var result = await this.requestJson<number>({
            url: `api/chatMessages/getUnreadCountForAdmin`,
            method: "GET"
        });

        return result;
    }
    

    public static async getMessagesForUser(model: SearchIn, pageSize: number, offsetDate?: Date, refId?:number): Promise<Result<PagedResult<ChatMessage>>> {

        var result = await this.requestJson<PagedResult<ChatMessage>>({
            url: `api/chatMessages/search`,
            method: "POST",
            data: {
                ...model, pageSize, offsetDate, refId
            }
        }, (input: PagedResult<ChatMessage>) => {

            input.items = (input.items || []).map(x => convertStringsToDates(x, DateFields))
            return input;
        });

        return result;
    }

    public static async notifyServerAboutMessageRead(msg: ChatMessage): Promise<Result<MarkMessageAsReadOut>> {

        var result = await this.requestJson<MarkMessageAsReadOut>({
            url: `api/chatMessages/markMessageAsRead`,
            method: "POST",
            data: {
                id: msg.id
            }
        }, (input: MarkMessageAsReadOut) => {

            return convertStringsToDates(input, ['readAt']);

        });

        return result;
    }

    public static async addNewMessage(model: ChatMessage): Promise<Result<ChatMessage>> {

        var result = await this.requestJson<ChatMessage>({
            url: `api/chatMessages/add`,
            method: "POST",
            data: model
        }, (input: ChatMessage) => convertStringsToDates(input, DateFields));

        return result;
    }

    public static async isChatEnabled(): Promise<Result<boolean>> {
        
        var result = await this.requestJson<boolean>({
            url: `api/chatMessages/IsChatEnabled`,
            method: "GET",
            
        });

        return result;
    }


}