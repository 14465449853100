import { ServiceBase, Result, PagedResult, convertStringsToDates } from "./ServiceBase";
import { AdministrativeException } from "../models/index";

export class AdministrativeExceptionService extends ServiceBase {

    public static async list(): Promise<Result<AdministrativeException[]>> {

        var result = await this.requestJson<AdministrativeException[]>({
            url: `api/AdministrativeExceptions/list`,          
            method: "GET"
        });

        return result;
    }   
}