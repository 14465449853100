import * as React from 'react';
import { useReducer, useEffect, useRef, useContext } from 'react';

import { AppUserInfo } from "../models/index";
import UserProfileService from '../services/UserProfileService';
import AuthenticationContext from '../components/context/AuthenticationContext';
import { Button } from 'reactstrap';


const PAGE_SIZE = 10;

type UserLetterState = {
    letter: string
}

export const Letter = (props: {
    
}) => {

    const authCtx = useContext(AuthenticationContext);

    let [state, setState] = useReducer(
        (prevState: UserLetterState, newState: Partial<UserLetterState>) => ({ ...prevState, ...newState }),
        {
            letter: ""
        }
    );

    const getLetter = async () => {

        const appUserId = authCtx.currentUser?.id;
        if (appUserId) {
            const letter = await UserProfileService.getLetter(appUserId as string);

            if (!letter.hasErrors) {
                setState({
                    letter: letter.value
                })
            }
        }
    }

    useEffect(() => {
        getLetter();
    }, []);

    return <div className="">
        <div dangerouslySetInnerHTML={{ __html: state.letter || '' }}></div>

        <Button className="d-print-none" onClick={() => window.print()} >Print</Button>
    </div>
};