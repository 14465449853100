import * as React from 'react';
import { useEffect, useReducer } from 'react';
import { useParams } from 'react-router-dom';
import { Spinner } from 'reactstrap';
//import { useState } from 'react';
import { ApplicationSubmissionEditor } from '../components/editors/ApplicationSubmissionEditor';
import { SubmissionCycleSettings } from '../models';
import { SubmissionCycleSettingsService } from '../services/SubmissionCycleSettingsService';
//import { AppUserFilesSearch } from '../components/views/AppUserFilesSearch';

type State = {
    loaded: boolean;
    isLoading: boolean;
    submissionCycleSettings?: SubmissionCycleSettings;
}

const initialState: State = {
    loaded: false,
    isLoading: false,
    submissionCycleSettings: undefined
}
export const ApplicationSubmission = (props: {
    mode: 'apply' | 'view'
}) => {

    const [state, setState] = useReducer(
        (prevState: State, newState: Partial<State>) => ({ ...prevState, ...newState }),
        initialState
    );


    const loadActive = async () => {

        setState({ isLoading: true });

        var result = await SubmissionCycleSettingsService.getActive();

        if (!result.hasErrors) {
            setState({
                loaded: true,
                isLoading: false,
                submissionCycleSettings: result.value
            })
        } else {
            setState({
                loaded: true,
                isLoading: false
            })
        }
    }

    useEffect(() => {
        loadActive();
    }, []);


    return <>
        {props.mode == 'view' && <ApplicationSubmissionEditor mode={props.mode} />}
        {props.mode == 'apply' &&
            <>
                {state.isLoading && <Spinner color="primary" size="sm" />}
                {state.loaded && state.submissionCycleSettings && <ApplicationSubmissionEditor mode={props.mode} />}
                {state.loaded && !state.submissionCycleSettings && <h2>Can't apply at the moment</h2>}
            </>
        }
    </>

};
