import * as React from 'react';
import { useReducer, useEffect, useRef, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Spinner, Button, Container, Row, Col } from 'reactstrap';
import InfiniteScroll from 'react-infinite-scroller';
import * as ArrayUtils from 'typescript-array-utils';
import { ChatMessage } from '../../models';
import cn from 'classnames';
import AuthenticationContext from '../context/AuthenticationContext';
import { ProvinceService } from '../../services/ProvinceService';
import { Context, Hub } from 'react-signalr/lib/signalr/types';
import handleViewport, { InjectedViewportProps } from 'react-in-viewport';
import { ChatMessagesService } from '../../services/ChatMessagesService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands, icon } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used
import { useTimeout } from '../../utils';
import { format } from 'date-fns';
//import { useTimout } from 

type ChatHistoryAreaState = {
    message: string;

}

export const ChatMessages = (props: {
    messages: ChatMessage[];
}) => {

    const authCtx = useContext(AuthenticationContext);

    //useEffect(() => {

        //console.log('props.messages changed', props.messages);

    //}, [props.messages])

    return <>
        {props.messages.map(x => {

            var ownMessage = authCtx.currentUser?.isAdmin
                ? (x.toUserId || '').length > 0
                : (x.toUserId || '').length == 0;

            if ((x.readAt || ownMessage) && x.id != 0) {
                return <SimpleMessageView
                    key={x.id + (x.clientId || '')}
                    model={x}
                    ownMessage={ownMessage}
                />
            }

            return <ViewportMessageView
                key={x.id + (x.clientId || '')}
                model={x}
            />
        })}

    </>
};



type MessageViewState = {
    sending: boolean;
    failed: boolean;
    success: boolean;
    ownMessage: boolean;
    renderedModel: ChatMessage;
    wasSentToServerToBeMarkedAsRead: boolean;
}

const MessageView = (props: {
    model: ChatMessage
} & InjectedViewportProps<HTMLDivElement>) => {

    const authCtx = useContext(AuthenticationContext);
    const timeoutRef = React.useRef<number>();

    const [state, setState] = useReducer(
        (prevState: MessageViewState, newState: Partial<MessageViewState>) => ({ ...prevState, ...newState }),
        {
            sending: false,
            failed: false,
            success: false,
            renderedModel: props.model,
            wasSentToServerToBeMarkedAsRead: false,
            ownMessage: authCtx.currentUser?.isAdmin
                ? (props.model.toUserId || '').length > 0
                : (props.model.toUserId || '').length == 0
        }
    );

    const sendMessageToServer = async () => {

        setState({
            sending: true,
            failed: false,
        })

        const result = await ChatMessagesService.addNewMessage(props.model);

        if (!result.hasErrors) {
            setState({
                sending: false,
                failed: false,
                success: true,
                renderedModel: result.value
            })
        } else {
            setState({
                sending: false,
                failed: true,
                success: false
            })
        }
    }

    const notifyServerAboutMessageRead = async () => {

        //console.log(' notfying server about read');
        setState({ wasSentToServerToBeMarkedAsRead: true });

        var resp = await ChatMessagesService.notifyServerAboutMessageRead(state.renderedModel);
        if (!resp.hasErrors) {

            var updatedModel = { ...state.renderedModel };
            updatedModel.readAt = resp.value.readAt;

            setState({ renderedModel: updatedModel });
        }
    }

    useEffect(() => {

        if (!state.ownMessage && !state.wasSentToServerToBeMarkedAsRead && props.inViewport && !state.renderedModel.readAt) {

            //console.log('will notfiy server about read after 0.5 seconds', );
            // wait for 0.5 seconds before marking message as read
            timeoutRef.current = window.setTimeout(() => { notifyServerAboutMessageRead() }, 500);
        }

        if (props.inViewport == false) {
            // clear timeout
            //console.log('clearing the timeout');
            window.clearTimeout(timeoutRef.current);
        }


    }, [props.inViewport])


    useEffect(() => {

        if (props.model.id == 0) {
            sendMessageToServer();
        }

    }, []);

    return <div className={cn("message",
        { 'message-own': state.ownMessage },
        { 'message-their': !state.ownMessage },
        { 'in-viewport': props.inViewport }

    )} ref={props.forwardedRef}>
        <div className="inner">
            <span className="message-text">{props.model.message}</span>
            <div className="message-status">
                <span className="sent-at">{format(state.renderedModel.created, 'HH:mm')}</span>
                {state.ownMessage && <FontAwesomeIcon icon={solid("check-double")} className={state.renderedModel.readAt ? 'text-success' : 'text-secondary'} />}
            </div>
        </div>
    </div>
}

const SimpleMessageView = (props: {
    model: ChatMessage,
    ownMessage: boolean
}) => {
    return <div className={cn("message",
        { 'message-own': props.ownMessage },
        { 'message-their': !props.ownMessage }
    )} >
        <div className="inner">
            <span className="message-text">{props.model.message}</span>
            <div className="message-status">
                <span className="sent-at">{format(props.model.created, 'HH:mm')}</span>
                {props.ownMessage && <FontAwesomeIcon icon={solid("check-double")} className={props.model.readAt ? 'text-success' : 'text-secondary'} />}
            </div>
        </div>
    </div>
}

const ViewportMessageView = handleViewport(MessageView, { threshold: 1.0, rootMargin: '15px' }, { disconnectOnLeave: false });