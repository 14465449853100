import * as React from 'react';
import { Search, useNavigate } from 'react-router-dom';
import * as Models from '../models/index';
import { useContext, useEffect, useReducer } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { Button, Col, FormGroup, Row, Spinner } from 'reactstrap';
import { formatDate } from '../utils';
import { Formik, FormikErrors, FormikProps, FormikValues } from 'formik';
import * as FormUtils from '../components/formUtils/index';
import { AccountService, SearchIn } from '../services/AccountService';
import AuthenticationContext from '../components/context/AuthenticationContext';
import { UserAccountEditor } from '../components/editors/UserAccountEditor';


const UserAccountEdit = () => (
    <div>
        <h1>User Edit</h1>        
        <UserAccountEditor />
    </div>
);

export { UserAccountEdit };
