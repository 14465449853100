import * as React from 'react';
import { ErrorDisplay, GroupSetProps, Label } from './index';
import { useField } from "formik";

declare type GroupSetCustomProps = GroupSetProps & {
    children?: React.ReactNode;
    innerContainer?: React.ExoticComponent<{ children: React.ReactNode }>;
}

export const GroupSetCustom = (props: GroupSetCustomProps) => {

    const [field, meta, helpers] = useField(props.fieldName);

    return (<div className={"form-group mb-4" + ((meta.error && meta.touched && " is-invalid ") || '')}>
        {props.title != null && <Label title={props.title} fieldName={props.fieldName} required={props.required} />}
        {/*if no container or innerContainerClass provided, render as is*/}
        {!props.innerContainer && !props.controlSize
            && props.children}

        {/*if no container but innerContainerClass provided*/}
        {!props.innerContainer && props.controlSize
            && <div className={"form-group-inner form-group-inner-" + props.controlSize}>{props.children}</div>}

        {/*render the provided container*/}
        {props.innerContainer
            && <props.innerContainer>{props.children}</props.innerContainer>}

        {meta.error && meta.touched && <ErrorDisplay fieldName={props.fieldName} />}
        {props.explanationText && <div className="explanation">{props.explanationText}</div>}
    </div>);
}