import * as yup from 'yup';

export class UserAccountEdit {
    id: string;
    userName: string;
    firstName: string;
    lastName: string;
    email: string;
    isAdmin: boolean;
    locked: boolean;
    emailAddressConfirmed: boolean;
    userProfileId: string;
}

export const ValidationSchema = yup.object().shape({
    userName: yup.string().required('Required'),
    //firstName: yup.string().required('Required'),
    //lastName: yup.string().required('Required'),
    email: yup.string().email().required('Required'),    
});