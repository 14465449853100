import * as yup from 'yup';

// use for log in
export class SubmissionCycleSettingsToFileTypeExtensionDate {
    _id: string; // client side only
    submissionCycleSettingsId: number;
    fileTypeId: number;
    extendedDate: Date;
}

export const ValidationSchema = yup.object().shape({
    fileTypeId: yup.number().required('Required'),
    extendedDate: yup.date().required('Required')   
});