import { ServiceBase, Result, PagedResult, convertStringsToDates } from "./ServiceBase";
import { UserAdministrativeExceptionChat, DateFields } from "../models/UserAdministrativeExceptionChat";

export default class UserAdministrativeExceptionChatService extends ServiceBase {

    public static async get(aeType: number): Promise<Result<UserAdministrativeExceptionChat>> {

        var result = await this.requestJson<UserAdministrativeExceptionChat>({
            url: `api/UserAdministrativeExceptionChat/list/?${aeType}`,
            method: "GET"
        });

        return result;
    }

    public static async add(model: UserAdministrativeExceptionChat): Promise<Result<UserAdministrativeExceptionChat>> {

        var result = await this.requestJson<UserAdministrativeExceptionChat>({
            url: `api/UserAdministrativeExceptionChat/add`,
            method: "POST",
            data: model
        }, (input: UserAdministrativeExceptionChat) => convertStringsToDates(input, DateFields));

        return result;
    }
}