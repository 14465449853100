import * as React from "react";
import * as FormUtils from '../formUtils/index';
import { AlbertaResidencyType, EnglishLanguageProficiency, IELTSStatus, MedicalDegreeCountry, Province, StatusInCanada, UserAccountListView, UserProfile } from "../../models/index";
import { AlbertaResidencyTypeService } from '../../services/AlbertaResidencyTypeService';
import { IELTSStatusService } from '../../services/IELTSStatusService';
import { ProvinceService } from '../../services/ProvinceService';
import { MedicalDegreeCountryService } from '../../services/MedicalDegreeCountryService';
import { EnglishLanguageProficiencyService } from '../../services/EnglishLanguageProficiencyService';
import { StatusInCanadaService } from '../../services/StatusInCanadaService';
import { Button, Col, Row } from "reactstrap";
import { FormikProps, FormikValues } from "formik";
//import AuthenticationContext from "../context/AuthenticationContext";
//import { useContext } from "react";

interface IProps {
    values: UserAccountListView;
}

export function UserAccountEditorFields(props: FormikProps<FormikValues>) {

    const values = props.values as UserProfile;

    //const authCtx = useContext(AuthenticationContext);

    //const isReadOnly = values.userProfileId.length > 0 && values.isApplicationSubmission == true;
    const isReadOnly = false;

    return (<React.Fragment>

        <div className="form-section">
            

            <FormUtils.GroupSetText
                fieldName="userName"
                title="Username"
                disabled={true}
                required
            />

            <FormUtils.GroupSetText
                fieldName="firstName"
                title="First Name"
                disabled={isReadOnly}
                required
            />

            <FormUtils.GroupSetText
                fieldName="lastName"
                title="Last Name"
                disabled={isReadOnly}
                required
            />

            <FormUtils.GroupSetText
                fieldName="email"
                title="Email"
                //disabled={isReadOnly}
                required
            />

            <FormUtils.GroupSetCheckbox
                fieldName="isAdmin"
                title="Is Admin"
                //disabled={isReadOnly}
                //required
            />


            <FormUtils.GroupSetCheckbox
                fieldName="locked"
                title="Locked"
                //disabled={isReadOnly}
                //required
            />

            {/*<FormUtils.GroupSetPhoneInput
                fieldName="telephone"
                title="Telephone"
                disabled={isReadOnly}
                required />*/}                    

        </div>

    </React.Fragment>);
}

