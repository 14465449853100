import * as React from 'react';
import { useField } from "formik";
import { FormFeedback } from 'reactstrap';

export const ErrorDisplay = (props: {
    fieldName: string,
}) => {

    const [field, meta, helpers] = useField(props.fieldName);

    if (meta.error == null || meta.error == undefined || !meta.touched)
        return null;

    if (typeof meta.error === "string") {
        return (<FormFeedback className="d-block"><div>{meta.error}</div></FormFeedback>);
    }

    return (<FormFeedback className="d-block">
        {
            Object.keys(meta.error).map((key, index) => {
                return <div key={key}>{meta.error?.[key]}</div>
            })
        }
    </FormFeedback>);
}