import * as React from "react";
import { AppUserInfo } from "../../models/User";

export type FormViewContextType = {
    readonly?: boolean;
}

export const FormViewContext = React.createContext<FormViewContextType>({ readonly: undefined });

//export const FormViewContext;

