import * as React from 'react';
import { Search, useNavigate, useSearchParams } from 'react-router-dom';
import UserProfileService, { SearchIn } from '../services/UserProfileService';
import * as Models from '../models/index';
import { useContext, useEffect, useReducer } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { Button, Col, FormGroup, Row, Spinner } from 'reactstrap';
import { formatDate } from '../utils';
import { Formik, FormikErrors, FormikProps, FormikValues } from 'formik';
import * as FormUtils from '../components/formUtils/index';
import { SubmissionCycleSettingsService } from '../services/SubmissionCycleSettingsService';
import { FileTypeService } from '../services/FileTypeService';
import { FileSubtypeService } from '../services/FileSubtypeService';
import { AppUserFileWorkflowStep } from '../models/AppUserFile';
import { AccountService } from '../services/AccountService';
import { MedicalDegreeCountryService } from '../services/MedicalDegreeCountryService';
import { ProvinceService } from '../services/ProvinceService';
import AuthenticationContext from '../components/context/AuthenticationContext';
import { AppSubmissionReviewMedicalDegree } from '../models/index';
import { AppSubmissionReviewMedicalDegreeService } from '../services/AppSubmissionReviewMedicalDegreeService';
import { EnglishLanguageProficiencyService } from '../services/EnglishLanguageProficiencyService';
import { AlbertaResidencyTypeService } from '../services/AlbertaResidencyTypeService';

const PAGE_SIZE = 10;

type State = {
    canLoadMore: boolean;
    isLoading: boolean;
    isNoResults: boolean;
    items: Models.UserProfileListView[];
    totalItems: number;
    lastFilter?: SearchIn
    searchTrigger: number;
}

const initialState: State = {
    canLoadMore: false,
    isLoading: false,
    isNoResults: false,
    items: [],
    totalItems: 0,
    lastFilter: undefined,
    searchTrigger: 0
}

export const ApplicationSubmissionSearch = () => {

    const navigate = useNavigate();



    const [state, setState] = useReducer(
        (prevState: State, newState: Partial<State>) => ({ ...prevState, ...newState }),
        initialState
    );

    const search = async () => {

        if (state.lastFilter == undefined)
            return;

        setState({
            isLoading: true
        });

        // 
        const result = await UserProfileService.search(state.lastFilter as SearchIn, PAGE_SIZE, 0);

        if (!result.hasErrors) {
            setState({
                items: result.value.items,
                totalItems: result.value.totalItems,
                isNoResults: result.value.totalItems == 0,
                isLoading: false,
                canLoadMore: result.value.totalItems > result.value.items.length
            })
        }

    }

    const loadMore = async () => {
        setState({
            isLoading: true
        });

        const result = await UserProfileService.search(state.lastFilter as SearchIn, PAGE_SIZE, state.items.length);

        if (!result.hasErrors) {

            const newItemsSet = state.items.concat(result.value.items);

            setState({
                items: newItemsSet,
                totalItems: result.value.totalItems,
                isLoading: false,
                canLoadMore: result.value.totalItems > newItemsSet.length
            })
        }

    }

    const openItem = (id: string) => {
        navigate(`/app-submission-view/${id}`);
    }

    useEffect(() => {
        search();
        //console.log('state.lastFilter recieved');
    }, [state.lastFilter])

    return <>
        <h1>All Submissions</h1>

        <ApplicationSearchFilters
            onFiltersChange={(filter) => setState({ lastFilter: filter })}
        />


        {state.totalItems > 0 && <div className="my-4 lead">
            Found <strong>{state.totalItems}</strong> results
        </div>}


        <InfiniteScroll
            pageStart={0}
            loadMore={loadMore}
            hasMore={state.canLoadMore && !state.isLoading}
            useWindow={false}
            className=""
        ><div className="table-responsive">
                <table className="table table-hover">
                    <thead>
                        <tr>
                            <th>AIMG ID</th>
                            <th>Surname</th>
                            <th>Former Surname</th>
                            <th>Given Name(s)</th>
                            <th>Email</th>
                        </tr>
                    </thead>
                    <tbody>
                        {state.items.map((item, index) => <tr key={item.userProfileId} onClick={() => { openItem(item.userProfileId) }} className="cursor-pointer">
                            <td>{item.userName}</td>
                            <td>{item.surname}</td>
                            <td>{item.formerSurname}</td>
                            <td>{item.givenNames}</td>
                            <td>{item.email}</td>
                        </tr>)}
                        {state.isNoResults &&
                            <tr>
                                <td colSpan={5}>No Results</td>
                            </tr>
                        }
                        {state.isLoading &&
                            <tr>
                                <td colSpan={5}>
                                    <Spinner color="primary" size="sm" />
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
        </InfiniteScroll>
    </>

};

type AppSearchFiltersState = {
    fileTypes: Models.FileType[],
    adminUsers: Models.AppUserInfo[],
    model: SearchIn
}

const initialApplicationSearchFiltersState: AppSearchFiltersState = {
    fileTypes: [],
    adminUsers: [],
    model: {
        onlyApplications: true,
        submissionCycleSettingsId: 3,
        keywords: '',
        appUserId: '',
        withAnyMissingFiles: false,
        withMissingFileTypes: [],
        fileSoloReviewBy: undefined,
        fileGroupReviewBy: [],
        docMccQ1StatementResult: undefined,
        docNacExamStatementResult: undefined
    }
}

const dsFileWorkflowStep: Models.DropDownItem<number>[] = [{
    value: AppUserFileWorkflowStep.Pending,
    text: 'Ready For Review'
},
{
    value: AppUserFileWorkflowStep.Rejected,
    text: 'Reupload Required'
}, {
    value: AppUserFileWorkflowStep.Accepted,
    text: 'Accepted'
}];


const ApplicationSearchFilters = (
    props: {
        onFiltersChange: (filters: SearchIn) => void;
    }
) => {

    let [searchParams, setSearchParams] = useSearchParams();

    const getInitialState = (): AppSearchFiltersState => {

        let result = initialApplicationSearchFiltersState;

        result.model.keywords = searchParams.get('q') || '';

        return result;
    }


    const [state, setState] = useReducer(
        (prevState: AppSearchFiltersState, newState: Partial<AppSearchFiltersState>) => ({ ...prevState, ...newState }),
        getInitialState()
    );

    const authCtx = useContext(AuthenticationContext);
    const showSimpleFilters = authCtx.currentUser?.isAdmin != true;

    const loadFileTypes = async () => {

        var result = await FileTypeService.list();

        if (!result.hasErrors) {
            setState({
                fileTypes: result.value
            })
        }

    }

    const loadAdmins = async () => {

        var result = await AccountService.getAllAdmins();

        if (!result.hasErrors) {
            setState({
                adminUsers: result.value
            })
        }
    }

    useEffect(() => {

        loadFileTypes();
        loadAdmins();

        props.onFiltersChange(state.model)
    }, []);


    return <>

        <Formik enableReinitialize={true}
            initialValues={state.model}
            onSubmit={(values, actions) => {
                props.onFiltersChange(values);
            }}
        >
            {(formikState) =>
                <>
                    { /* show filters for regular users */}
                    {showSimpleFilters && <>
                        <Row>
                            <Col sm={4} md={3}>
                                <FormUtils.GroupSetText
                                    title="Keywords"
                                    fieldName="keywords"
                                />
                            </Col>
                        </Row>
                    </>}

                    { /* show filters for admins */}
                    {!showSimpleFilters && <>
                        <Row>
                            <Col sm={4} md={3}>
                                <FormUtils.GroupSetText
                                    title="Keywords"
                                    fieldName="keywords"
                                />
                            </Col>
                            <Col sm={4} md={3}>
                                <FormUtils.GroupSetDropdownAsync<Models.SubmissionCycleSettings, number>
                                    fieldName="submissionCycleSettingsId"
                                    title="Submission Cycle"
                                    dropdownProps={{
                                        loadDataSource: () => SubmissionCycleSettingsService.list(),
                                        getId: x => x.id,
                                        renderItem: x => <>{formatDate(x.applicationStart)} - {formatDate(x.applicationEnd)}</>,
                                        renderSelectedItem: x => x ? <>{formatDate(x.applicationStart)} - {formatDate(x.applicationEnd)}</> : 'Any Submission Cycle',
                                        selectedValue: formikState.values.submissionCycleSettingsId,
                                        allowNullSelection: true,
                                        nullSelectionText: "Any Submission Cycle",
                                        selectFirstByDefault: true
                                    }}
                                />

                            </Col>
                            <Col sm={4} md={3}>
                                <FormUtils.GroupSetDropdownMultiSelect<Models.FileType, number>
                                    fieldName="withMissingFileTypes"
                                    title="Missing File Types"
                                    dropdownProps={{
                                        dataSource: state.fileTypes,
                                        getKey: x => x.id.toString(),
                                        isChecked: (x, ds) => ds.filter(y => y == x.id).length > 0,
                                        renderItem: x => x.description,
                                        renderSelected: (ds) => <>click here</>,
                                        createSelectedItem: (item) => item.id,
                                        removeSelectedItem: (values, item) => {
                                            return values.filter(x => x != item.id);
                                        },
                                        findBySelectedId: (values, selected) => { return values.find(x => x.id == selected) as Models.FileType },
                                        noSelctionText: <>Select</>
                                    }}
                                />

                            </Col>

                            <Col sm={4} md={3}>
                                <FormUtils.GroupSetDropdown<Models.DropDownItem<number>>
                                    fieldName="appFileReviewState"
                                    title="App File Review State"
                                    dropdownProps={{
                                        dataSource: dsFileWorkflowStep,
                                        renderItem: x => x.text,
                                        getId: x => x.value,
                                        renderSelectedItem: x => x.text,
                                        selectedValue: formikState.values.appFileReviewState,
                                        allowNullSelection: true,
                                        nullSelectionText: 'Any State'
                                    }}
                                />
                            </Col>
                            <Col sm={4} md={3}>
                                <FormUtils.GroupSetDropdown<Models.AppUserInfo>
                                    fieldName="fileSoloReviewBy"
                                    title="Reviewing User"
                                    dropdownProps={{
                                        dataSource: state.adminUsers,
                                        getId: x => x.id,
                                        renderItem: x => `${x.firstName} ${x.lastName}`.trim().length > 0 ? `${x.firstName} ${x.lastName}` : `${x.userName}`,
                                        renderSelectedItem: x => `${x.firstName} ${x.lastName}`.trim().length > 0 ? `${x.firstName} ${x.lastName}` : `${x.userName}`,
                                        selectedValue: formikState.values.fileSoloReviewBy,
                                        allowNullSelection: true,
                                        nullSelectionText: 'Select'
                                    }}
                                />
                            </Col>

                            <Col sm={4} md={3}>

                                <FormUtils.GroupSetDropdown<string>
                                    fieldName="docMccQ1StatementResult"
                                    title="MCC QE1 Statement of Results"
                                    dropdownProps={{
                                        dataSource: ['Pending', 'Shared'],
                                        getId: x => x,
                                        renderItem: x => x,
                                        renderSelectedItem: x => x,
                                        selectedValue: formikState.values.docMccQ1StatementResult,
                                        allowNullSelection: true,
                                        nullSelectionText: 'Select'
                                    }}
                                />

                            </Col>
                            <Col sm={4} md={3}>
                                <FormUtils.GroupSetDropdown<string>
                                    fieldName="docNacExamStatementResult"
                                    title="NAC Exam Statement of Results"
                                    dropdownProps={{
                                        dataSource: ['Pending', 'Shared', 'Retake'],
                                        getId: x => x,
                                        renderItem: x => x,
                                        renderSelectedItem: x => x,
                                        selectedValue: formikState.values.docNacExamStatementResult,
                                        allowNullSelection: true,
                                        nullSelectionText: 'Select'
                                    }}
                                />
                            </Col>
                            <Col sm={4} md={3}>
                                <FormUtils.GroupSetDropdownAsync<AppSubmissionReviewMedicalDegree, number>
                                    fieldName="medicalDegreeId"
                                    title="Medical Degree"
                                    dropdownProps={{
                                        //dataSource: ['Not yet conferred', 'Not Shared', 'Not at least "Received/Accepted"', 'Not ECFMG Verified'],
                                        loadDataSource: () => AppSubmissionReviewMedicalDegreeService.list(),
                                        getId: x => x.id,
                                        renderItem: x => x.name,
                                        renderSelectedItem: x => x.name,
                                        selectedValue: formikState.values.medicalDegreeId,
                                        allowNullSelection: true,
                                        nullSelectionText: 'Select'
                                    }}
                                />
                            </Col>
                            <Col sm={4} md={3}>
                                <FormUtils.GroupSetDropdownAsync<Models.EnglishLanguageProficiency, string>
                                    fieldName="englishLanguageProficiency"
                                    title="English Language Proficiency"
                                    dropdownProps={{
                                        loadDataSource: () => EnglishLanguageProficiencyService.list(),
                                        getId: x => x.key,
                                        renderItem: x => x.description,
                                        renderSelectedItem: x => x ? x.description : 'Select',
                                        selectedValue: formikState.values.englishLanguageProficiency,
                                        allowNullSelection: true,
                                        nullSelectionText: 'Select'
                                    }}
                                />
                            </Col>
                            <Col sm={4} md={3}>
                                <FormUtils.GroupSetDropdownAsync<Models.AlbertaResidencyType, string>
                                    fieldName="albertaResidencyType"
                                    title="Proof of Residence in Alberta"
                                    dropdownProps={{
                                        loadDataSource: () => AlbertaResidencyTypeService.list(),
                                        getId: x => x.type,
                                        renderItem: x => 'Option ' + x.type + ' - ' + x.description,
                                        renderSelectedItem: x => x ? 'Option ' + x.type + ' - ' + x.description : 'Select',
                                        selectedValue: formikState.values.albertaResidencyType,
                                        allowNullSelection: true,
                                        nullSelectionText: 'Select'
                                    }}
                                />
                            </Col>
                            <Col sm={4} md={3}>
                                <FormUtils.GroupSetDropdownAsync<Models.FileSubtypeView, number>
                                    fieldName="subFileType"
                                    title="File Subtype"
                                    dropdownProps={{
                                        loadDataSource: () => FileSubtypeService.list(),
                                        getId: x => x.id,
                                        renderItem: x => `${x.description} - [${x.fileTypeDescription}]`,
                                        renderSelectedItem: x => x ? `${x.description} - [${x.fileTypeDescription}]` : 'Select',
                                        selectedValue: formikState.values.subFileType
                                    }}
                                />
                            </Col>
                        </Row>
                    </>}
                    <Row>
                        <Col>
                            <Button type="button" color="primary" onClick={() => { formikState.submitForm() }}>Search</Button>

                            <Button type="button" color="primary" outline className="ms-3" onClick={() => { formikState.resetForm() }}>Clear</Button>
                        </Col>
                    </Row>
                </>
            }
        </Formik>
    </>

}


