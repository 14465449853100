import * as React from 'react';

export const AppExternship = () => {

    return <div>
        <h1>Externship</h1>
        <a className="btn btn-primary" target="_blank" rel="noreferrer" href="https://uofc.sharepoint.com/sites/spo-faculty-medicine-AIMG-Externship?e=1%3A3e65f7701eec4d75965c44b388d8e4cd">View The Externship Site</a>
    </div>  
    
};
