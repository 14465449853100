import * as React from 'react';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, Button, Col, Row, Spinner } from 'reactstrap';
import { useEffect, useReducer } from 'react';
import { Formik, FormikErrors, FormikProps, FormikValues } from 'formik';
import * as FormUtils from '../components/formUtils/index';
import { AccountService } from './../services/AccountService';
import { IdentityResult } from '../models';


type State = {
    isLoading: boolean;
    email: string;
    emailSent: boolean;
    emailNotFound: boolean;
    identityResult?: IdentityResult;
}

type ForgotPwdEditType = {
    email: string;
}

const initialEditObject: ForgotPwdEditType = {
    email: ''
}

const initialState: State = {
    email: '',
    isLoading: false,
    emailSent: false,
    emailNotFound: false,
    identityResult: undefined
}

const ForgotPasswordPage = () => {

    const [state, setState] = useReducer(
        (prevState: State, newState: Partial<State>) => ({ ...prevState, ...newState }),
        initialState
    );

    const sendResetPwdEmail = async (email: string) => {

        setState({
            isLoading: true,
            email: email            
        });

        var result = await AccountService.sendPwdResetEmail(email);

        if (!result.hasErrors) {

            setState({
                isLoading: false,
                emailSent: result.value.succeeded,
                emailNotFound: !result.value.succeeded,
                identityResult: result.value
            });

        } else {

            setState({
                isLoading: false
            });

        }
    };

    return <div>
        <h1>Forgot Password</h1>

        {(!state.identityResult || !state.identityResult.succeeded) && <Formik enableReinitialize={true}
            initialValues={initialEditObject}

            onSubmit={(values: ForgotPwdEditType, actions) => {
                sendResetPwdEmail(values.email)
            }}
        >
            {(formikState) =>
                <Row className="d-flex d-flex align-items-center">
                    <Col>
                        <FormUtils.GroupSetText
                            title="Your email"
                            fieldName="email"
                        />
                    </Col>

                    <Col>
                        <Button type="button" className="mt-2" color="primary" onClick={() => { formikState.submitForm() }}>Next Step</Button>
                    </Col>
                </Row>
            }
        </Formik>}
        {state.identityResult && state.identityResult.errors.length > 0
            &&
            <Alert color="danger">
                <ul>
                    {state.identityResult?.errors.map(x => <li key={x.code}>{x.description}</li>)}
                </ul>
            </Alert>
        }

        {state.emailSent && <p>Please check your email for the reset link</p>}

    </div>
}

export { ForgotPasswordPage };
