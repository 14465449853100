import * as React from 'react';
import { useReducer, useEffect, useRef, useContext } from 'react';

import { AppUserInfo } from "../../models/index";
import UserProfileService from '../../services/UserProfileService';
import AuthenticationContext from '../context/AuthenticationContext';


const PAGE_SIZE = 10;

type UserLetterState = {
    letter: string
}

export const UserLetter = (props: {
    user: AppUserInfo
}) => {

    const authCtx = useContext(AuthenticationContext);    

    let [state, setState] = useReducer(
        (prevState: UserLetterState, newState: Partial<UserLetterState>) => ({ ...prevState, ...newState }),
        {
            letter: ""
        }
    );

    const getLetter = async () => {
        const letter = await UserProfileService.getLetter(props.user.id);

        if (!letter.hasErrors) {
            setState({
                letter: letter.value
            })
        }
    }

    useEffect(() => {
        getLetter();
    }, []);

    return <div className="">
        <div dangerouslySetInnerHTML={{ __html: state.letter || '' }}></div>
    </div>
};