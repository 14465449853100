import { ServiceBase, Result } from "./ServiceBase";
import { AlbertaResidencyType } from "../models/index";

export class AlbertaResidencyTypeService extends ServiceBase {

    public static async list(): Promise<Result<AlbertaResidencyType[]>> {

        var result = await this.requestJson<AlbertaResidencyType[]>({
            url: `api/AlbertaResidencyType/list`,          
            method: "GET"
        });

        return result;
    }   

    public static async getProfileResidenseTypeOption(): Promise<Result<AlbertaResidencyType>> {

        var result = await this.requestJson<AlbertaResidencyType>({
            url: `api/AlbertaResidencyType/getProfileResidenseTypeOption`,
            method: "GET"
        });

        return result;
    }
}