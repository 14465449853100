import * as React from 'react';
import { useFormikContext } from 'formik';


export const ErrorsSummary = (props: {
    dictionary: { [key: string]: React.ReactNode };
}) => {

    const { errors, touched } = useFormikContext();

    return <>{Object.keys(errors).length > 0 && Object.keys(touched).length > 0 && <div className="alert alert-danger" >
        <div className="fw-bold mb-2">Please fix these errors before continuing:</div>
        <ul className="mb-0">
            {Object.keys(errors).map(x => <li key={x} >{props.dictionary[x]}</li>)
            }
        </ul>
    </div>}
    </>;
}