import { ServiceBase, Result } from "./ServiceBase";
import { IELTSStatus } from "../models/index";

export class IELTSStatusService extends ServiceBase {

    public static async list(): Promise<Result<IELTSStatus[]>> {

        var result = await this.requestJson<IELTSStatus[]>({
            url: `api/ieltsstatus/list`,          
            method: "GET"
        });

        return result;
    }   
}