import * as React from 'react';
import { useReducer, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Spinner, Button, Container, Row, Col } from 'reactstrap';
import { UIMessages } from './UIUtils';
import { SubmissionCycleSettings } from '../models/index';
import { SubmissionCycleSettingsService } from '../services/SubmissionCycleSettingsService';
import Countdown, { CountdownRenderProps, calcTimeDelta, CountdownTimeDelta, zeroPad } from 'react-countdown';


type State = {
    isLoading: boolean;
    submissionCycleSettings?: SubmissionCycleSettings;
    //countDown?: CountdownRenderProps;
    countDownDelta?: CountdownTimeDelta;
    hideControl: boolean;
}

export const TimeUntilSubmissionEnd = () => {

    let [state, setState] = useReducer(
        (prevState: State, newState: Partial<State>) => ({ ...prevState, ...newState }),
        {
            isLoading: false,
            submissionCycleSettings: undefined,
            //countDown: undefined,
            countDownDelta: undefined,
            hideControl: false
        }
    );

    const mountRef = useRef(true);

    const loadCurrentSubmissionCycle = async () => {

        setState({
            isLoading: true
        });

        var result = await SubmissionCycleSettingsService.getActive();


        setState({
            isLoading: false,
            submissionCycleSettings: result.value
        })
    }


    useEffect(() => {

        loadCurrentSubmissionCycle();

        return () => {
            mountRef.current = false;
        }

    }, []);

    const getClassName = () => {
        if (!state.countDownDelta)
            return null;

        return (state.countDownDelta.days >= 5) ? "alert-primary" : "alert-danger";
    }
    if (state.hideControl)
        return <></>;

    return (
        <React.Fragment>
            <Container>
                <Row>
                    <Col>
                        <div className={"alert text-center " + getClassName()}>
                            <h5>{state.submissionCycleSettings && <Countdown date={state.submissionCycleSettings?.applicationEnd?.getTime()}
                                onComplete={() => {
                                    setState({ hideControl: true });
                                    console.log('completed');
                                }}
                                renderer={(val) => {
                                    if (val.days >= 4) {
                                        return <><strong>{val.days}</strong> days left until application ends.</>;
                                    }

                                    if (val.days >= 1) {
                                        return <><strong>{val.days * 24 + val.hours}</strong> hours left until application ends.</>
                                    }

                                    if (val.hours == 0 && val.minutes > 0)
                                        return <><strong>{val.minutes}</strong> minutes left until application ends.</>

                                    return <><strong>{val.hours}</strong> hours and <strong>{val.minutes}</strong> minutes left until application ends.</>

                                }}
                                onTick={(val) => { setState({ countDownDelta: val }) }}
                            >
                                <span>Application submission time expired!</span>
                            </Countdown>}
                            </h5>
                        </div>
                    </Col>
                </Row>
            </Container>

        </React.Fragment>
    );

};