import * as yup from 'yup';

// use for log in
export class AppUserFile {
    id: string;
    fileTypeId: number;
    fileSubtypeId?: number;
    fileDate: Date;
    fileBinary: File;
    fileMimeType: string;
    fileSubtypeDescription: string;
    fileName: string;
    fileSize?: number;
    userProfileId: string;
    fileDescription: string;
    isForAppSubmission: boolean;
    workflowStep: AppUserFileWorkflowStep;
    fileTypeDescription: string;
    soloReviewId?: string;
    executiveReview: boolean;
    groupReview: AppUserFileGroupReview[];
}

export class AppUserFileGroupReview {
    userId: string;
    fileId: string;
}

export enum AppUserFileWorkflowStep {    
    Pending = 0,
    Rejected = 1,
    Accepted = 2
}

export const ValidationSchema = yup.object().shape({
    fileMimeType: yup.string().required(),
    fileTypeId: yup.number().moreThan(0, 'Required'),
    fileSize: yup.number().moreThan(1024, 'Required')    
});