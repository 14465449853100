import * as yup from 'yup';

// use for log in
export class LoginInfo {
    email: string;
    password: string;
    remember: boolean;
}

export class LoginResult {
    success: boolean;
    emailConfirmationRequired: boolean;
}


export const ValidationSchema = yup.object().shape({
    email: yup.string().required('Required'),
    password: yup.string().required('Required')
});
