import React from 'react';
import { Label, FormGroup, Button, Alert, NavLink, Row, Col, Spinner } from 'reactstrap';
import { Formik, Field, Form } from "formik";
import { LegacyUserCreate } from './../../models';
import { AccountService } from './../../services/AccountService';
import { IdentityResult } from '../../models';
import { useReducer } from 'react';
import { Link } from 'react-router-dom';
import * as FormUtils from '../formUtils';
import { ValidationSchemaLegacyUser } from './../../models/UserRegister';

type RegisterState = {
    isLoading: boolean;
    identityResult?: IdentityResult;
    showForm: boolean;
    email: string;
}

const initialValues: LegacyUserCreate = {
    //userName: '',
    email: '',
    // firstName: '',
    // lastName: '',
    password: '',
    passwordConfirmation: ''
}

export const RegisterLegacy = () => {

    const [state, setState] = useReducer(
        (prevState: RegisterState, newState: Partial<RegisterState>) => ({ ...prevState, ...newState }),
        {
            isLoading: false,
            identityResult: undefined,
            showForm: true,
            email: ''
        }
    );

    return <Row>
        <Col sm="12" md="6" lg="4">
            <h3>Re-Register Existing Account</h3>
            <p>This is for users who already applied between 2018 and 2022.  Enter the email address you registered with last time and we will pull your account information from your last registration.</p>
            {state.showForm && <Formik
                initialValues={initialValues}
                validationSchema={ValidationSchemaLegacyUser}
                onSubmit={async (values) => {

                    setState({ isLoading: true, email: values.email })

                    var result = await AccountService.createLegacyUser(values);
                    //console.log('AccountService.createLegacyUser')

                    setState({
                        identityResult: result.value,
                        isLoading: false,
                        showForm: !(result.value?.succeeded == true)
                    });
                }}
            >
                <Form className="mb-3">
                    
                    <FormUtils.GroupSetText fieldName="email" title="Email" />                    
                    <p>Please enter a new password.  Your old password has not been transfered over from the old application. Your password must be at least ten characters. It must include a number, an uppercase letter, a lowercase letter, and a special character.</p>
                    <FormUtils.GroupSetText fieldName="password" title="Password" inputProps={{ type: "password" }} />
                    <FormUtils.GroupSetText fieldName="passwordConfirmation" title="Confirm Password" inputProps={{ type: "password" }} />

                    <Button type="submit" color="primary" className="px-4 mt-4" disabled={state.isLoading}>Register{state.isLoading && <Spinner color="light" size="sm" className="ms-1" ></Spinner>}</Button>
                </Form>
            </Formik>}
            {(state.identityResult?.errors || []).length > 0 &&
                <Alert color="danger">
                    <ul>
                        {state.identityResult?.errors.map(x => <li key={x.code}>{x.description}</li>)}
                    </ul>
                </Alert>
            }
            {state.identityResult?.succeeded == true &&
                <Alert color="light">
                    We sent an email confirmation to <strong>{state.email}</strong>, you can <NavLink tag={Link} className="text-dark d-inline-block" to="/login">login</NavLink> to start.
                </Alert>
            }

        </Col>
    </Row>
}