import React from 'react';
import { Label, FormGroup, Button, Row, Col, Input, NavLink } from 'reactstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Formik, Field, Form } from "formik";
import { ApplicationState } from './../../store';
import * as AuthorizationStore from './../../store/Authorization';
import * as Models from './../../models';
import { ValidationSchema } from './../../models/UserLogin';

import * as FormUtils from '../formUtils';
import { Link } from 'react-router-dom';

interface Props {
    authorization: AuthorizationStore.IState;
    authorizationActions: typeof AuthorizationStore.actionCreators;
}
const initialValues: Models.LoginInfo = {
    email: '',
    password: '',
    remember: false
}


const LoginToConnect = (props: Props) => {

    const renderLoginForm = () => {
        return <><Formik
            initialValues={initialValues}
            onSubmit={async (values) => {
                props.authorizationActions.login({ password: values.password, email: values.email, remember: values.remember });
            }}
            validationSchema={ValidationSchema}
        >{({ values }) => <Form className="mb-3">
            <FormUtils.GroupSetText
                fieldName="email"
                title="Email"
                explanationText={"your email here please"}
            />
            <FormUtils.GroupSetText
                fieldName="password"
                title="Password"
                inputProps={{ type: "password" }}
            />
            <FormUtils.GroupSetCheckbox
                fieldName="remember"
                title="Remember Me"
            />
                <Button color="primary" className="mt-4" type="submit">Login</Button>
        </Form>
            }
        </Formik >

        </>
    }

    const renderLoggedIn = () => {
        return <div>You are logged in as <strong>{props.authorization.currentUser?.userName}</strong></div>
    }

    const renderNotConfirmedMessage = () => {
        return <Col sm="12">
            <p className="alert alert-primary">
                <div>Please confirm your email, if no email recieved click <NavLink tag={Link} className="text-dark d-inline-block" to="/resendemailconfirmationemail">here</NavLink> to ge a new one</div>
            </p>
        </Col>
    }

    const renderLoginFailedMessage = () => {
        return <Col sm="12" md="6" lg="4">
            <p className="text-danger">Username or password incorrect</p>
        </Col>
    }

    return <>

        {props.authorization.isSignedIn && <>

            <Row>
                <Col sm="12" className="disclaimer-box">
                    <p className="disclaimer">The Alberta International Medical Graduate Program (AIMG Program) application portal collects applicant information in several parts. The AIMG Program Privacy Policy applies to all parts of the application portal.</p>

                    <p className="disclaimer">Privacy Policy:  Information is collected pursuant to <i>sections 33(c) and 39(1)(a)</i> of the authority of the Freedom of Information and Protection of Privacy Act.  It is required to evaluate your application for admission to the AIMG Program.  If admitted, it will form part of the AIMG Program record.  Specific data elements will be disclosed to the provincial and federal governments to meet reporting requirements.</p>

                    <p className="disclaimer">Please note that the information may be disclosed pursuant to <i>sections 39 (1)(c) and 40(1)(c) of the Freedom of Information and Protection of Privacy Act</i>, to other health care and regulatory bodies: names of applicants, dates of application and assessments, and residency program.  All other data is considered confidential and will be used and disclosed in accordance with privacy legislation.</p>
                </Col>
            </Row>

            <Row>
                <Col sm="12">
                    <p>The Application Portal is now opened for specific supporting documents.</p>

                    <p>Applicants may upload specific supporting documents accepted after the June 7th, 2024 3:30 PM MT main supporting document submission deadline. Please see the '<a href="https://www.aimg.ca/important-dates-deadlines/" target="_blank">2025 Cycle Important Dates and Deadlines Calendar</a>' for more information.</p>

                    <p>To upload documents, click on the My Application Submission link at the top, then click on the Your Files link to access your documents.  Here you will be able to upload specific documents.</p>
                </Col>
            </Row>

            <Row>
                <Col sm="12">
                    <p>All applicants to the Alberta International Medical Graduate (AIMG) Program must create a Profile and provide required and applicable supporting documents to be considered for the 2025 Application Cycle.  It is essential that all documents are accurate and properly documented, cited, and executed.<br />
                        It is strongly recommended that applicants use Google Chrome as your default browser.</p>
                    <ul className="instructions">
                        <li>Step 1: Review the AIMG Program website thoroughly for further information on the Application Process and note the Important Dates and Deadlines.  Email questions to <a href="mailto:aimg@ucalgary.ca">aimg@ucalgary.ca</a></li>
                        <li>Step 2: Gather all required Information.</li>
                        <li>Step 3: Complete &quot;<a href="/userprofile">Your Profile</a>&quot; from <strong><u>9:00 AM MT May 1, 2024 - 3:30 PM MT May 31, 2024</u></strong>.  Required fields are denoted by a red asterisk (*).  Your Profile can be saved and edited at any time while the application cycle is open.  Profiles deemed incomplete will not be able to submit an application.  Click &quot;Save&quot; to view message indicating &quot;Page has been successfully saved.&quot;</li>
                        <li>Step 4: Use &quot;<a href="/files">Your Supporting Documents</a>&quot; open from <strong><u>9:00 AM MT May 1, 2024 - 3:30 PM MT June 7th, 2024</u></strong> to choose the Residence in Alberta Option to which you wish to apply.  Applicants must only choose one Residence in Alberta Option; this should be the same as what is indicated on the 'AIMG 2025 Cycle Application Checklist'.</li>
                        <li>Step 5: Upload all required supporting documents.  Applicants can now &quot;View&quot;, &quot;Delete&quot; or &quot;Replace&quot; any uploaded supporting documents.  Multiple documents can be submitted for each supporting document tab.  Applicants are responsible for providing &quot;best quality images&quot; that clearly show pictures and images such as stamps, embossments, dates and signatures.  Get help for any supporting document upload issues via the CHAT imbedded into the Application Portal.</li>
                        <li>Step 6: Carefully review Your Profile and Your Supporting Documents to ensure everything is complete, accurate, and up-to-date. <br /><strong>All documents will be auto-submitted at the close of the online application cycle.</strong></li>
                    </ul>
                    <p><strong>NOTE:</strong> There is no &quot;Apply&quot; feature.  Completing &quot;Your Profile&quot; and uploading all &quot;Supporting Documents&quot; is the same as submitting an application. Applicants can edit and change their profile and supporting documents at any time during the application open.</p>

                </Col>
            </Row>
        </>}
        <Row>
            <Col sm="12" md="6" lg="4">
                {!props.authorization.isSignedIn && renderLoginForm()}
                {props.authorization.isSignedIn && renderLoggedIn()}
            </Col>
        </Row>
        <Row>
            <Col>
                {props.authorization.showLoginFailedMessage && renderLoginFailedMessage()}
            </Col>
        </Row>
        {!props.authorization.isSignedIn && props.authorization.emailConfirmationRequired && renderNotConfirmedMessage()}
        {!props.authorization.isSignedIn &&
            <Row>
                <Col>
                    <div>Forgot your password? Click <Link to="/forgot-password">here</Link>.</div>
                </Col>
            </Row>
        }
    </>
}

const Login = connect(
    (state: ApplicationState) => ({
        authorization: state.authorization
    }), // Selects which state properties are merged into the component's props
    (dispatch, getState) => ({
        authorizationActions: bindActionCreators(AuthorizationStore.actionCreators, dispatch)
    })

)(LoginToConnect as any)

export { Login }