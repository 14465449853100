import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { useEffect, useReducer } from 'react';
import { IdentityResult } from '../models/index';
import { AccountService }  from '../services/AccountService';

type State = {
    isLoading: boolean;
    identityResult?: IdentityResult;
    email: string;
}

export const ConfirmUserEmail = () => {

    const [state, setState] = useReducer(
        (prevState: State, newState: Partial<State>) => ({ ...prevState, ...newState }),
        {
            isLoading: false,
            identityResult: undefined,
            email: ''
        }
    );

    const confirmEmail = async () => {

        const queryParameters = new URLSearchParams(window.location.search)
        const token = queryParameters.get("token") || '';
        const email = queryParameters.get("email") || '';

        setState({ email: email, isLoading: true });

        var result = await AccountService.ConfirmUserEmail(token, email);

        setState({
            identityResult: result.value,
            isLoading: false
        })
    }

    useEffect(() => {

        confirmEmail();

    }, []);

    return <div>
        <h1>Email Confirmation</h1>

        {state.identityResult && state.identityResult.succeeded && <>
            <h2>Thank you!, email <strong>{state.email}</strong> is now confirmed.</h2>
            <p><a href="/login">Click here</a> to login to your account.</p>
        </>}
        {state.identityResult && !state.identityResult.succeeded && <h2>Email confirmation failed.</h2>}
        {state.isLoading && <>Loading..</>}

    </div>
};
