import { ServiceBase, Result, PagedResult, convertStringsToDates } from "./ServiceBase";
import { UserCreate, CurrentUserCtx, LoginInfo, IdentityResult, UserProfile } from "../models/index";
import { DateFields } from "../models/UserProfile";
import { UserProfileListView, DateFields as UserProfileListViewDateFields } from '../models/UserProfileListView';
import { DateFields as AppSubmissionReviewDateFields } from '../models/AppSubmissionReview';
import { AppUserFileWorkflowStep } from "../models/AppUserFile";

import profileBus from "../components/bus/ProfileBus";


const prepareForEdit = (input: UserProfile) => {
    if (input) {
        input.surname = input.surname || '';
        input.formerSurname = input.formerSurname || '';
        input.givenNames = input.givenNames || '';
        input.physiciansApply = input.physiciansApply || '';
        input.physiciansApplySurname = input.physiciansApplySurname || '';
        input.physiciansApplyGivenNames = input.physiciansApplyGivenNames || '';
        input.preferredName = input.preferredName || '';
        input.currentStreetAddress = input.currentStreetAddress || '';
        input.currentTownCity = input.currentTownCity || '';
        input.currentProvince = input.currentProvince || '';
        input.currentPostalCode = input.currentPostalCode || '';
        input.currentCountry = input.currentCountry || '';

        input.telephone = input.telephone || '';
        input.sameAsMailAddress = input.sameAsMailAddress || '';
        input.mailStreetAddress = input.mailStreetAddress || '';
        input.mailCity = input.mailCity || '';
        input.mailProvince = input.mailProvince || '';
        input.mailPostalCode = input.mailPostalCode || '';
        input.mailCountry = input.mailCountry || '';
        input.email1 = input.email1 || '';
        input.applicantIdentityDeclaration = input.applicantIdentityDeclaration || '';
        input.gender = input.gender || '';
        input.capParticipant = input.capParticipant || '';
        input.medicalDegreeCountry = input.medicalDegreeCountry || '';
        input.region = input.region || '';
        input.universityName = input.universityName || '';
        input.medicalDegreeName = input.medicalDegreeName || '';
        input.medicalSchoolGraduationYear = input.medicalSchoolGraduationYear || '';
        input.medicalSchoolGraduationMonth = input.medicalSchoolGraduationMonth || '';
        input.statusInCanada = input.statusInCanada || '';
        input.albertaResidencyType = input.albertaResidencyType || '';
        input.previousStreetAddress1 = input.previousStreetAddress1 || '';
        input.previousCity1 = input.previousCity1 || '';
        input.previousProvince1 = input.previousProvince1 || '';
        input.previousStreetAddress2 = input.previousStreetAddress2 || '';
        input.previousCity2 = input.previousCity2 || '';
        input.previousProvince2 = input.previousProvince2 || '';
        input.previousStreetAddress3 = input.previousStreetAddress3 || '';
        input.previousCity3 = input.previousCity3 || '';
        input.previousProvince3 = input.previousProvince3 || '';
        input.englishLanguageProficiency = input.englishLanguageProficiency || '';
        input.cpsaLicense = input.cpsaLicense || '';
        input.cpsaLicenseNumber = input.cpsaLicenseNumber || '';
        input.ieltstrfNumber = input.ieltstrfNumber || '';
        input.cpsaStatus = input.cpsaStatus || '';
        input.cpsaScore = input.cpsaScore || '';
        input.cpsaSpeaking = input.cpsaSpeaking || '';
        input.cpsaWriting = input.cpsaWriting || '';
        input.cpsaReading = input.cpsaReading || '';
        input.cpsaListening = input.cpsaListening || '';
        input.mccCandidateCode = input.mccCandidateCode || '';
        input.caRmsId = input.caRmsId || '';
        input.mccqePart1Registered = input.mccqePart1Registered || '';
        input.nacWarning = input.nacWarning || '';
        input.mccqePart1Score = input.mccqePart1Score || '';
        input.nacExamScore = input.nacExamScore || '';
        input.nacExamScoreType = input.nacExamScoreType || '';
        input.cpsaEvidence = input.cpsaEvidence || '';

        input.appUserFiles = (input.appUserFiles || []).map(x => {
            x.appUserFiles = (x.appUserFiles || []).map(y => convertStringsToDates(y, ["fileDate"]))
            return x;
        });

        if (input.dateOfBirth?.getFullYear() == 1)
            input.dateOfBirth = null;

        if (input.currentAddressMoveInDate?.getFullYear() == 1)
            input.currentAddressMoveInDate = null;

        if (input.previousMoveInDate1?.getFullYear() == 1)
            input.previousMoveInDate1 = null;

        if (input.previousMoveOutDate1?.getFullYear() == 1)
            input.previousMoveOutDate1 = null;

        if (input.previousMoveInDate2?.getFullYear() == 1)
            input.previousMoveInDate2 = null;

        if (input.previousMoveOutDate2?.getFullYear() == 1)
            input.previousMoveOutDate2 = null;

        if (input.previousMoveInDate3?.getFullYear() == 1)
            input.previousMoveInDate3 = null;

        if (input.previousMoveOutDate3?.getFullYear() == 1)
            input.previousMoveOutDate3 = null;

        if (input.cpsaDateTaken?.getFullYear() == 1)
            input.cpsaDateTaken = null;

        if (input.mccqePart1DateTaken?.getFullYear() == 1)
            input.mccqePart1DateTaken = null;

        if (input.nacExamDateTaken?.getFullYear() == 1)
            input.nacExamDateTaken = null;


        input.submissionCycleSettings = convertStringsToDates(input.submissionCycleSettings, ['applicationStart', 'applicationEnd']);
        input.submissionCycleSettings.submissionCycleSettingsToFileTypeExtensionDates =
            input.submissionCycleSettings.submissionCycleSettingsToFileTypeExtensionDates.map(x => convertStringsToDates(x, ["extendedDate"]));

        input.appSubmissionReview = convertStringsToDates(input.appSubmissionReview, AppSubmissionReviewDateFields);
    }

    return input;
}

const preProcessDates = (input: UserProfile) => {
    return prepareForEdit(convertStringsToDates(input, DateFields));
}

export class SearchIn extends Object {
    appUserId?: string;
    onlyApplications: boolean;
    keywords: string;
    submissionCycleSettingsId: number;
    withAnyMissingFiles: boolean;
    withMissingFileTypes: number[];
    appFileReviewState?: AppUserFileWorkflowStep;
    fileSoloReviewBy?: string;
    fileGroupReviewBy: string[];
    docMccQ1StatementResult?: string;
    docNacExamStatementResult?: string;
    medicalDegreeId?: number;
    englishLanguageProficiency?: string;
    albertaResidencyType?: string;
    subFileType?: number;    
}

export class LetterType extends Object {
    type: string;
}

export default class UserProfileService extends ServiceBase {

    public static async get(userId: string): Promise<Result<UserProfile>> {

        var result = await this.requestJson<UserProfile>({
            url: `api/userprofile/get/${userId}`,
            method: "GET"
        }, preProcessDates);

        return result;
    }

    public static async getNewApplicationSubmission(): Promise<Result<UserProfile>> {
        var result = await this.requestJson<UserProfile>({
            url: `api/userprofile/get-new-appsubmission`,
            method: "GET"
        }, preProcessDates);

        return result;
    }

    public static async getApplicationSubmissionById(id: string): Promise<Result<UserProfile>> {
        var result = await this.requestJson<UserProfile>({
            url: `api/userprofile/get-appsubmission/` + id,
            method: "GET"
        }, preProcessDates);

        return result;
    }

    public static async save(model: UserProfile): Promise<Result<UserProfile>> {

        //console.log('saving:', model);

        var result = await this.requestJson<UserProfile>({
            url: `api/userprofile/save`,
            method: "POST",
            data: model
        }, preProcessDates);

        profileBus.dispatch("hasAgreedProfile", { hasAgreedProfile: model.hasAgreedProfile });

        return result;
    }

    public static async saveApplicationSubmission(model: UserProfile): Promise<Result<UserProfile>> {

        var result = await this.requestJson<UserProfile>({
            url: `api/userprofile/saveappsubmission`,
            method: "POST",
            data: model
        }, preProcessDates);

        return result;
    }

    public static async search(model: SearchIn, pageSize: number, offset: number): Promise<Result<PagedResult<UserProfileListView>>> {

        model.docMccQ1StatementResult = model.docMccQ1StatementResult ?? '';
        model.docNacExamStatementResult = model.docNacExamStatementResult ?? '';

        var result = await this.requestJson<PagedResult<UserProfileListView>>({
            url: `api/userprofile/search`,
            method: "POST",
            data: {
                ...model, pageSize, offset
            }
        }, (input: PagedResult<UserProfileListView>) => {

            input.items = (input.items || []).map(x => convertStringsToDates(x, UserProfileListViewDateFields))
            return input;
        });

        return result;
    }

    public static async listforletters(model: LetterType): Promise<Result<PagedResult<UserProfileListView>>> {

        var result = await this.requestJson<PagedResult<UserProfileListView>>({
            url: `api/userprofile/listforletters`,
            method: "POST",
            data: {
                ...model
            }
        }, (input: PagedResult<UserProfileListView>) => {

            input.items = (input.items || []).map(x => convertStringsToDates(x, UserProfileListViewDateFields))
            return input;
        });

        return result;
    }

    public static async getLetter(userId: string): Promise<Result<string>> {

        var result = await this.requestJson<string>({
            url: `api/userprofile/getletter/${userId}`,
            method: "GET",
        }, (input: string) => {
            return input;
        });

        return result;
    }

    public static async getAgreedFiles(): Promise<Result<boolean>> {

        var result = await this.requestJson<boolean>({
            url: `api/userprofile/getagreedfiles/`,
            method: "GET",
        }, (input: string) => {
            return input;
        });

        return result;
    }

    public static async saveAgreedFiles(isAgree: boolean): Promise<Result<boolean>> {

        var result = await this.requestJson<boolean>({
            url: `api/userprofile/saveagreedfiles/${isAgree ? 1 : 0}`,
            method: "GET",
        }, (input: string) => {
            return input;
        });

        return result;
    }
}