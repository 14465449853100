import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { SubmissionCycleSettingsService } from '../services/SubmissionCycleSettingsService';
import * as Models from '../models/index';
import { useEffect, useReducer } from 'react';
import { formatDateAndTime } from '../utils';
import { Spinner, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';


type State = {
    canLoadMore: boolean;
    isLoading: boolean;
    isNoResults: boolean;
    items: Models.SubmissionCycleSettings[];
    //totalItems: number;
    //lastFilter?: SearchIn
    searchTrigger: number;
}


const initialState: State = {
    canLoadMore: false,
    isLoading: false,
    isNoResults: false,
    items: [],
    //totalItems: 0,
    //lastFilter: undefined,
    searchTrigger: 0
}


export const SubmissionCycleSettingsListPage = () => {

    const navigate = useNavigate();

    const [state, setState] = useReducer(
        (prevState: State, newState: Partial<State>) => ({ ...prevState, ...newState }),
        initialState
    );

    const search = async () => {

        

        setState({
            isLoading: true        
        });

        // 
        const result = await SubmissionCycleSettingsService.list();

        //console.log('aSubmissionCycleSettingsService', result);

        if (!result.hasErrors) {
            setState({
                items: result.value,
                isLoading: false,
                isNoResults: result.value.length == 0
            })
        } else {
            setState({                
                isLoading: false                
            })
        }

    }

    const editItem = (id: number) => {
        navigate(`/submission-cycle-settings-edit/${id}`);
    }

    useEffect(() => {
        search()
    }, [])

    return <>
        <h1>Submission Cycles</h1>
        <div className="table-responsive">
            <table className="table table-hover">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Application Start</th>
                        <th>Application End</th>                        
                    </tr>
                </thead>
                <tbody>
                    {state.items.map((item, index) => <tr key={item.id} onClick={() => { editItem(item.id) }} className="cursor-pointer">
                        <td>{index + 1}</td>
                        <td>{formatDateAndTime(item.applicationStart)}</td>
                        <td>{formatDateAndTime(item.applicationEnd)}</td>
                       
                    </tr>)}
                    {state.isNoResults &&
                        <tr>
                            <td colSpan={5}>No Results</td>
                        </tr>
                    }
                    {state.isLoading &&
                        <tr>
                            <td colSpan={5}>
                                <Spinner color="primary" size="sm" />
                            </td>
                        </tr>
                    }
                </tbody>
            </table>
        </div>
        <Link className="btn btn-primary" to="/submission-cycle-settings-add">Add New</Link>
    </>
    
};
