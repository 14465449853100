import * as React from 'react';
import { useReducer, useEffect, useRef, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Spinner, Button, Container, Row, Col } from 'reactstrap';
import InfiniteScroll from 'react-infinite-scroller';
import * as ArrayUtils from 'typescript-array-utils';
import { ChatMessage } from '../../models';
import cn from 'classnames';
import AuthenticationContext from '../context/AuthenticationContext';
import { ProvinceService } from '../../services/ProvinceService';
import { Context, Hub } from 'react-signalr/lib/signalr/types';
import handleViewport, { InjectedViewportProps } from 'react-in-viewport';
import { ChatMessagesService } from '../../services/ChatMessagesService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands, icon } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used
import { useTimeout } from '../../utils';
import { ChatMessages } from './ChatMessages';
import { PagedResult, Result } from '../../services/ServiceBase';
import { MessageViewedInfo } from '../../models/ChatMessage';


type ChatHistoryAreaState = {
    messages: ChatMessage[];
    loadingMoreStoped: boolean;
    isLoadingHistory: boolean;
    totalItems: number;
    scrollToBottomTrigger: number;
}



export const ChatHistoryArea = (props: {

    newMessage?: ChatMessage;
    //scrollToBottomTrigger: number;
    loadMessages: (date?: Date, refId?: number) => Promise<Result<PagedResult<ChatMessage>>>;
    messageViewed?: ChatMessage;
    //opened: boolean;
    //signalRHub: Context<Hub<string, string>>;

}) => {

    let [state, setState] = useReducer(
        (prevState: ChatHistoryAreaState, newState: Partial<ChatHistoryAreaState>) => ({ ...prevState, ...newState }),
        {
            messages: [],
            loadingMoreStoped: false,
            isLoadingHistory: false,
            totalItems: -1,
            scrollToBottomTrigger: 0
        }
    );

    const messagesEndRef = useRef(null);
    const containerRef = React.createRef<HTMLDivElement>();


    const loadMore = async () => {

        if (state.isLoadingHistory)
            return;

        if (state.messages.length > 0 && state.totalItems >= 0) {
            if (state.totalItems <= state.messages.length)
                return;
        }


        var refDate: Date | undefined = undefined;
        var refId = 0;
        if (state.messages.length > 0) {
            refDate = state.messages[0].created;
            refId = state.messages[0].id;
        }

        setState({
            isLoadingHistory: true,
            loadingMoreStoped: false
        });

        

        var result = await props.loadMessages(refDate, refId);

        if (!result.hasErrors) {

            if (result.value.items.length > 0) {

                var newSet = result.value.items.concat(state.messages)
                setState({
                    messages: newSet,
                    isLoadingHistory: false,
                    totalItems: result.value.totalItems,
                    scrollToBottomTrigger: state.messages.length == 0 ? state.scrollToBottomTrigger + 1 : state.scrollToBottomTrigger
                });
            } else {
                setState({
                    isLoadingHistory: false
                });
            }
        }
    }

    useEffect(() => {

        //console.log('try load more by state.messages effect');

        if (!state.loadingMoreStoped && state.totalItems > state.messages.length) {
            //console.log('load more by state.messages effect');
            loadMore();
        }


    }, [state.messages])

    useEffect(() => {

        if (props.newMessage)
            setState({
                messages: state.messages.concat([props.newMessage]),
                scrollToBottomTrigger: state.scrollToBottomTrigger + 1
            });

    }, [props.newMessage])

    useEffect(() => {

        let msgFound = false;

        if (props.messageViewed) {

            let newSet = state.messages.map(x => {

                if (x.id == props.messageViewed?.id || x.clientId == props.messageViewed?.clientId) {
                    msgFound = true;
                    //x.readAt = props.messageViewed?.readAt;
                    return props.messageViewed as ChatMessage;
                }

                return x;
            })

            if (msgFound) {
                //console.log('msg was found');
                setState({ messages: newSet });
            }
        }

    }, [props.messageViewed])

    useEffect(() => {

        if (state.scrollToBottomTrigger)
            setTimeout(() => { (messagesEndRef.current as any)?.scrollIntoView({ behavior: "smooth" }) }, 100);
    }, [state.scrollToBottomTrigger])

    

    return <div className="chat-history-area d-flex flex-column" ref={containerRef}>
        <div className="inner">
            <ViewportLoadMoreTrigger onLoadMore={() => {

                //console.log('load more by viewport');
                loadMore()

            }}
                onStopLoadingMore={() => {

                    setState({ loadingMoreStoped: true })

                }} />
            {state.isLoadingHistory && <div className="text-center py-3">
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>}


            {/*<div style={{ height: 100, position: 'fixed', border: '1px solid red', right: 0, bottom: 0, zIndex: 10000 }}>
                {(messagesEndRef.current as any).scrollTop} </div>*/}

            <ChatMessages messages={state.messages} />
        </div>
        <div ref={messagesEndRef}></div>
    </div>
};

const LoadMoreTrigger = (props: {
    onLoadMore: () => void;
    onStopLoadingMore: () => void;
} & InjectedViewportProps<HTMLDivElement>) => {

    useEffect(() => {
        //console.log('inViewport changed to', props.inViewport);

        if (props.inViewport)
            props.onLoadMore();
        else {
            props.onStopLoadingMore();
        }

    }, [props.inViewport])

    return <div ref={props.forwardedRef}>{/*<Button type="button" onClick={() => props.onLoadMore()}>Load More</Button> [{props.inViewport ? '+' : '-'}]*/}</div>
}

const ViewportLoadMoreTrigger = handleViewport(LoadMoreTrigger, {}, { disconnectOnLeave: false });