import { ServiceBase, Result, PagedResult, convertStringsToDates } from "./ServiceBase";
import { TeamReviewEligiblePending } from "../models/index";

export class TeamReviewEligiblePendingService extends ServiceBase {

    public static async list(): Promise<Result<TeamReviewEligiblePending[]>> {

        var result = await this.requestJson<TeamReviewEligiblePending[]>({
            url: `api/TeamReviewEligiblePending/list`,          
            method: "GET"
        });

        return result;
    }   
}