import * as React from 'react';
import { useField, Field, FieldMetaProps, FieldProps, useFormik, useFormikContext } from "formik";

interface LabelProps {
    [key: string]: any;
    required?: boolean;
    fieldName: string;
    title: React.ReactNode;
}

export const Label = (props: LabelProps | any) => {

    const [field, meta] = useField(props.fieldName);

    return (<label
        className= { "form-label lead " + (props.required ? " required" : "") + ((meta.error && meta.touched) ? ' has-errors' : '') }
        htmlFor={props.fieldName}
    >{props.title}{props.required && <sup className="required-mark">*</sup>}</label>)
}