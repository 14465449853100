import * as React from 'react';
import GenericEditor from './GenericEditor';
import { UserProfileEditorFields } from '../editorFields/UserProfileEditorFields';
import { UserProfile, ValidationSchema } from '../../models/UserProfile';
import UserProfileService from '../../services/UserProfileService';
import * as AuthorizationStore from './../../store/Authorization';
import AuthenticationContext from '../context/AuthenticationContext';
import { useContext } from 'react';

export const UserProfileEditor = () => {

    const authCtx = useContext(AuthenticationContext); 

    var disableButton = false; // authCtx.currentUser?.userName != '10549' && authCtx.currentUser?.userName != '10004';

    return <GenericEditor<UserProfile, string>
        getById={id => UserProfileService.get(id || '')}
        save={model => UserProfileService.save(model)}
        formikFields={UserProfileEditorFields}
        validationSchema={ValidationSchema}
        stringToId={x => x}
        getId={x => x.userProfileId}
        shouldTryToLoad={id => true}
        disableSaveButton={disableButton}
        errorsMap={{
            surname: 'Surname',
            formerSurname: 'Former Surname',
            givenNames: 'Given Name(s)',
            preferredName: 'Preferred Given Name',
            physiciansApply: 'Is this the name used on your physiciansapply.ca account?',
            physiciansApplySurname: 'physiciansapply.ca Surname',
            physiciansApplyGivenNames: 'physiciansapply.ca Given Names',
            currentStreetAddress: 'Current Street Address',
            currentTownCity: 'Current Town/City',
            currentProvince: 'Current Province',
            currentPostalCode: 'Current Postal Code',
            currentCountry: 'Current Country',
            telephone: 'Telephone',
            sameAsMailAddress: 'Is this also your mailing address?',
            mailStreetAddress: 'Mailing Street Address',
            mailCity: 'Mailing Town/City',
            mailProvince: 'Mailing Province/Territory',
            mailPostalCode: 'Mailing Postal Code',
            mailCountry: 'Mailing Country',
            email1: 'Email Address',
            acknowledgeEmailResponsibility: 'I agree with the following terms and conditions',
            applicantIdentityDeclaration: 'Supporting Document Declaration',
            dateOfBirth: 'Date Of Birth',
            gender: 'Gender',
            capParticipant: <>I have completed <a href='https://www.aimg.ca/how-it-works/clinical-assessment-placement/' target='_blank'>Clinical Assessment Placement</a></>,
            medicalDegreeCountry: 'Issuing Country on Medical Degree',
            region: 'Region',
            universityName: 'University Name',
            medicalDegreeName: 'Medical Degree Name',
            medicalSchoolGraduationYear: 'Medical School Graduation Year / Expected Graduation Year',
            medicalSchoolGraduationMonth: 'Graduation Month',
            statusInCanada: 'Status In Canada',
            albertaResidencyType: 'Proof of Residence in Alberta',
            currentAddressMoveInDate: 'Living at address entered above (current address) since',
            previousStreetAddress1: 'Optional additional Alberta address to help prove third option of Residence in Alberta (above).',
            previousCity1: 'Town/City',
            previousProvince1: 'Province',
            previousMoveInDate1: 'From',
            previousMoveOutDate1: 'To',
            previousStreetAddress2: 'Street Address',
            previousCity2: 'Town/City',
            previousProvince2: 'Province',
            previousMoveInDate2: 'From',
            previousMoveOutDate2: 'To',
            previousStreetAddress3: 'Street Address',
            previousCity3: 'Town/City',
            previousProvince3: 'Province',
            previousMoveInDate3: 'From',
            previousMoveOutDate3: 'To',
            englishLanguageProficiency: 'English Language Proficiency',
            cpsaLicense: 'ELP Exams',
            cpsaLicenseNumber: 'CPSA License Number',
            cpsaDateTaken: 'Date Taken',
            ieltstrfNumber: 'TRF Number',
            cpsaStatus: 'Status',
            cpsaListening: 'Listening score',
            cpsaReading: 'Reading score',
            cpsaSpeaking: 'Speaking score',
            cpsaWriting: 'Writing score', 
            mccCandidateCode: 'MCC Candidate Code',
            caRmsId: 'CaRMS ID# (Optional)',
            mccqePart1Pass: 'Do you currently have a PASS standing for MCCQE Part 1',
            mccqePart1DateTaken: 'Date MCCQE Part 1 taken',
            mccqePart1Score: 'MCCQE Part 1 Score', 
            mccqePart1Registered: 'I am registered for a siting of the MCCQE Part 1 and will share my result with the AIMG Program through physiciansapply.ca no later than..',
            mccqePart1RegisterIntend: 'I intend to register to sit the MCCQE Part 1 exam and will share my results by..',
            nacExam: 'Do you currently have a pass standing on the NAC Exam?',
            nacExamDateTaken: 'Date NAC Exam taken',
            nacExamScore: 'NAC Exam Score',
            nacRetakeFall: 'I am registered for a sitting of the NAC, and will share my result with the AIMG Program through physiciansapply.ca no later than..',
            nacRegisterIntend: 'I intend to register to sit the NAC exam and will share my results by..'
        }}
    />;
}