import { ServiceBase, Result, PagedResult, convertStringsToDates } from "./ServiceBase";
import { SubmissionCycleSettings, DateFields } from "../models/SubmissionCycleSettings";
import ShortUniqueId from 'short-unique-id';

const uid = new ShortUniqueId({ length: 10 });

const preProcessDate = (input: SubmissionCycleSettings) => {

    var item: SubmissionCycleSettings = convertStringsToDates(input, DateFields);

    item.submissionCycleSettingsToFileTypeExtensionDates = (item.submissionCycleSettingsToFileTypeExtensionDates || []).map(x => {

        x._id = uid();
        return convertStringsToDates(x, ["extendedDate"])
    })

    return item;
}

const preProcessDates = (input: SubmissionCycleSettings[]) => {
    console.log('preProcessDates', input)

    return input.map(x => convertStringsToDates(x, DateFields));
}


export class SubmissionCycleSettingsService extends ServiceBase {

    public static async list(): Promise<Result<SubmissionCycleSettings[]>> {

        var result = await this.requestJson<SubmissionCycleSettings[]>({
            url: `api/submissioncyclesettings/list`,
            method: "GET"
        }, preProcessDates);

        return result;
    }

    public static async get(id: number): Promise<Result<SubmissionCycleSettings>> {

        var result = await this.requestJson<SubmissionCycleSettings>({
            url: `api/submissioncyclesettings/get/${id}`,
            method: "GET"
        }, preProcessDate);

        return result;
    }

    public static async save(item: SubmissionCycleSettings): Promise<Result<SubmissionCycleSettings>> {

        var result = await this.requestJson<SubmissionCycleSettings>({
            url: `api/submissioncyclesettings/save`,
            method: "POST",
            data: item
        }, preProcessDate);

        return result;
    }

    public static async getActive(): Promise<Result<SubmissionCycleSettings>> {

        var result = await this.requestJson<SubmissionCycleSettings>({
            url: `api/submissioncyclesettings/getactive`,
            method: "GET"
        }, preProcessDate);

        return result;
    }
}