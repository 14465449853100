import * as yup from 'yup';
//import { AppUserFile } from './AppUserFile';
import { FileTypeToAppUserFile } from './FileTypeToAppUserFile';
import { SubmissionCycleSettings } from './SubmissionCycleSettings';

export class UserProfile {
    userProfileId: string;
    appUserId: string;
    surname: string;
    formerSurname: string;
    givenNames: string;
    physiciansApply: string;
    physiciansApplySurname: string;
    physiciansApplyGivenNames: string;
    preferredName: string;
    currentStreetAddress: string;
    currentTownCity: string;
    currentProvince: string;
    currentPostalCode: string;
    currentCountry: string;
    telephone: string;
    sameAsMailAddress: string;
    mailStreetAddress: string;
    mailCity: string;
    mailProvince: string;
    mailPostalCode: string;
    mailCountry: string;
    email1: string;
    acknowledgeEmailResponsibility: boolean;
    applicantIdentityDeclaration: string;
    dateOfBirth: Date | null;
    gender: string;
    capParticipant: string;
    medicalDegreeCountry: string;
    region: string;
    universityName: string;
    medicalDegreeName: string;
    medicalSchoolGraduationYear: string;
    medicalSchoolGraduationMonth: string;
    statusInCanada: string;
    albertaResidencyType: string;
    currentAddressMoveInDate: Date | null;
    hasPreviousAddress1: boolean;
    previousStreetAddress1: string;
    previousCity1: string;
    previousProvince1: string;
    previousMoveInDate1: Date | null;
    previousMoveOutDate1: Date | null;
    hasPreviousAddress2: boolean;
    previousStreetAddress2: string;
    previousCity2: string;
    previousProvince2: string;
    previousMoveInDate2: Date | null;
    previousMoveOutDate2: Date | null;
    hasPreviousAddress3: boolean;
    previousStreetAddress3: string;
    previousCity3: string;
    previousProvince3: string;
    previousMoveInDate3: Date | null;
    previousMoveOutDate3: Date | null;
    englishLanguageProficiency: string;
    cpsaEvidence: string;
    cpsaLicense
    cpsaLicenseNumber: string;
    ieltstrfNumber: string;
    cpsaDateTaken: Date | null;
    cpsaStatus: string;
    cpsaScore: string;
    cpsaListening: string;
    cpsaReading: string;
    cpsaWriting: string;
    cpsaSpeaking: string;
    mccCandidateCode: string;
    caRmsId: string;
    mccqePart1Pass: boolean;
    mccqePart1RegisterIntend: boolean;
    mccqePart1DateTaken: Date | null;
    mccqePart1Score: string;
    mccqePart1Registered: string;
    nacExam: boolean;
    nacExamDateTaken: Date | null;
    nacExamScore: string;
    nacRetakeFall: boolean;
    nacRegisterIntend: boolean;
    nacWarning: string;
    nacExamScoreType: string;
    // DateTime FormInserted ;

    isApplicationSubmission: boolean;
    submissionDate?: Date;
    appUserFiles: FileTypeToAppUserFile[];
    submissionCycleSettings: SubmissionCycleSettings;
    appSubmissionReview: UserProfileReview;

    hasAgreedProfile?: boolean;
    hasAgreedFiles?: boolean;
    isExternship?: boolean;
    administrativeExceptionId?: number;

}

class UserProfileReview {
    feePaymentReceived: boolean;
    docMccQ1StatementResult?: string;    
    docNacExamStatementResult?: string;    
    medicalDegreeId: number | undefined;
    changeOfNameDocument: boolean;
    soloReviewNotes: string;
    teamReviewNotes: string;

    useMccCandidateToSearchPhysiciansapply: boolean;
    reviewStepDate: Date | null;
    reviewStepUserId: string;
    caRmsEligibleStatus: boolean;
    // caRmsEligiblePending: boolean;
    caRmsEligibleUndecided: boolean;
    caRmsIneligibleStatus?: boolean;
    caRmsProfileErrors?: boolean;

    teamReviewDate: Date | null;
    teamReviewUserIds: string[];
    teamReviewEligiblePending: string[];
    teamReviewNotEligible: string[];
    teamReviewCaRMSEligible: string[];
    caRMSEligiblePending: string[];

    additionalNotes: string;
    supportingDocReceivedByDeadline: boolean;
    docsReceivedDate: Date | null;
    lateDocumentNames: string;

    soloAlbertaResidencyScore1?: number;
    soloAlbertaResidencyScore2?: number;
    soloAlbertaResidencyScore3?: number;
    soloAlbertaResidencyScore4?: number;
    soloAlbertaResidencyScore5?: number;
    soloAlbertaResidencyScore6?: number;
    soloAlbertaResidencyScoreFinal?: number;
    teamAlbertaResidencyScore1?: number;
    teamAlbertaResidencyScore2?: number;
    teamAlbertaResidencyScore3?: number;
    teamAlbertaResidencyScore4?: number;
    teamAlbertaResidencyScore5?: number;
    teamAlbertaResidencyScore6?: number;
    teamAlbertaResidencyScoreFinal?: number;

    customLetterText: string;
}


export const DateFields = [
    "dateOfBirth",
    "currentAddressMoveInDate",
    "previousMoveInDate1",
    "previousMoveOutDate1",
    "previousMoveInDate2",
    "previousMoveOutDate2",
    "previousMoveInDate3",
    "previousMoveOutDate3",
    "cpsaDateTaken",
    "mccqePart1DateTaken",
    "nacExamDateTaken",
    "submissionDate"
];


//const _ielts5to9_step05 = ['5.0', '5.5', '6.0', '6.5', '7.0', '7.5', '8.0', '8.5', '9.0'];
const _oetStatements = ['A', 'B', 'C+', 'C', 'D', 'E'];

const validator5to9_step05 = {
    message: 'Expected from 5 to 9 with 0.5 as step',
    test: (value) => {
        //console.log('medicalSchoolGraduationYear value', value);
        if ((value || '').length == 0)
            return false;

        var isInt = Number.isInteger((value as any) * 1);
        //console.log('isInt ', isInt);
        if (isInt == true) {
            var parsed = Number.parseInt(value as string, 10);

            return parsed <= 9 && parsed >= 5;
        }

        if (!isNaN(value as any) && (value as string).indexOf('.') != -1) {

            var floatVal = parseFloat(value as string);

            if (floatVal >= 5 && floatVal <= 9) {

                if (floatVal % 0.5 == 0)
                    return true;
            }
        }

        return false;
    }
}

const genericDateValidator = {
    message: 'Required',
    test: (value) => {

        if (value == null || value == undefined)
            return false;

        return Object.prototype.toString.call(value) === '[object Date]'
    }
}



const isNumericValidator = {
    message: 'Number Required',
    test: (value) => { 

       
        //const isInt = Number.isInteger(value);
        //const isFloat = (Number(value) === value && value % 1 !== 0);

        //console.log('isNumericValidator ', value, typeof value, isInt, isFloat);        

        //return isInt || isFloat;
        return !Number.isNaN(Number(value));
    }
}

const validatorOetValues = {
    message: "Expected values are one of 'A', 'B', 'C+', 'C', 'D', 'E'",
    test: (value) => {
        //console.log('medicalSchoolGraduationYear value', value);
        if ((value || '').length == 0)
            return false;

        return _oetStatements.indexOf(value.toUpperCase()) > -1;
    }
}

const validatorCelpipValues = {
    message: "Expected value from 3 to 12",
    test: (value) => {
        //console.log('medicalSchoolGraduationYear value', value);
        if ((value || '').length == 0)
            return false;

        var isInt = Number.isInteger((value as any) * 1);
        //console.log('isInt ', isInt);
        if (isInt == true) {
            var parsed = Number.parseInt(value as string, 10);

            return parsed >= 3 && parsed <= 12;
        }

        if (!isNaN(value as any) && (value as string).indexOf('.') != -1) {

            var floatVal = parseFloat(value as string);

            if (floatVal >= 3 && floatVal <= 12) {
                return true;
            }
        }

        return false;
    }
}

export const ValidationSchema = yup.object().shape({
    surname: yup.string().required('Required'),
    //formerSurname: yup.string().required('Required'),
    givenNames: yup.string().required('Required'),
    physiciansApply: yup.string().required('Required'),

    physiciansApplySurname: yup.mixed().when('physiciansApply', {
        is: (val) => val == 'No',
        then: (schema) => schema.required('Required'),
        otherwise: undefined
    }),

    physiciansApplyGivenNames: yup.mixed().when('physiciansApply', {
        is: (val) => val == 'No',
        then: (schema) => schema.required('Required'),
        otherwise: undefined
    }),
    preferredName: yup.mixed().when('physiciansApply', {
        is: (val) => val == 'No',
        then: (schema) => schema.required('Required'),
        otherwise: undefined
    }),

    currentStreetAddress: yup.string().required('Required'),
    currentTownCity: yup.string().required('Required'),
    currentProvince: yup.string().required('Required'),
    currentPostalCode: yup.string().required('Required'),
    currentCountry: yup.string().required('Required'),

    telephone: yup.string().required('Required'),
    sameAsMailAddress: yup.string().required('Required'),

    mailStreetAddress: yup.mixed().when('sameAsMailAddress', {
        is: (val) => val != "Yes",
        then: (schema) => schema.required('Required'),
        otherwise: undefined
    }),
    mailCity: yup.mixed().when('sameAsMailAddress', {
        is: (val) => val != "Yes",
        then: (schema) => schema.required('Required'),
        otherwise: undefined
    }),
    mailProvince: yup.mixed().when('sameAsMailAddress', {
        is: (val) => val != "Yes",
        then: (schema) => schema.required('Required'),
        otherwise: undefined
    }),
    mailPostalCode: yup.mixed().when('sameAsMailAddress', {
        is: (val) => val != "Yes",
        then: (schema) => schema.required('Required'),
        otherwise: undefined
    }),
    mailCountry: yup.mixed().when('sameAsMailAddress', {
        is: (val) => val != "Yes",
        then: (schema) => schema.required('Required'),
        otherwise: undefined
    }),

    acknowledgeEmailResponsibility: yup.bool().required('Required').oneOf([true], "Required"),
    applicantIdentityDeclaration: yup.string().required('Required'),
    //...
    dateOfBirth: yup.date().typeError('Required').required('Required'),
    gender: yup.string().required('Required'),
    medicalDegreeCountry: yup.string().required('Required'),
    universityName: yup.string().required('Required'),
    medicalSchoolGraduationYear: yup.string().test({
        message: 'Required',
        test: (value) => {
            //console.log('medicalSchoolGraduationYear value', value);
            if ((value || '').length == 0)
                return false;

            var isInt = Number.isInteger((value as any) * 1);
            //console.log('isInt ', isInt);


            if (isInt == true) {
                var parsed = Number.parseInt(value as string, 10);

                //console.log('parsed ', parsed);
                //console.log('getFullYear ', new Date().getFullYear());
                return parsed <= new Date().getFullYear();
            }


            //return value == '2000'
            return false;
        }
    }).required('Required'),
    medicalSchoolGraduationMonth: yup.string().required('Required'),
    statusInCanada: yup.string().required('Required'),
    albertaResidencyType: yup.string().required('Required'),
    currentAddressMoveInDate: yup.date().typeError('Required').required('Required'),

    previousStreetAddress1: yup.mixed().when('hasPreviousAddress1', {
        is: (has) => has,
        then: (schema) => schema.required('Required'),
        otherwise: undefined
    }),
    previousCity1: yup.mixed().when('hasPreviousAddress1', {
        is: (has) => has,
        then: (schema) => schema.required('Required'),
        otherwise: undefined
    }),
    previousProvince1: yup.mixed().when('hasPreviousAddress1', {
        is: (has) => has,
        then: (schema) => schema.required('Required'),
        otherwise: undefined
    }),
    previousMoveInDate1: yup.mixed().nullable().when('hasPreviousAddress1', {
        is: (has) => has,
        then: (schema) => schema.typeError('Required').required('Required'),
        otherwise: undefined
    }),
    previousMoveOutDate1: yup.mixed().nullable().when('hasPreviousAddress1', {
        is: (has) => has,
        then: (schema) => schema.typeError('Required').required('Required'),
        otherwise: undefined
    }),



    previousStreetAddress2: yup.mixed().when('hasPreviousAddress2', {
        is: (has) => has,
        then: (schema) => schema.required('Required'),
        otherwise: undefined
    }),
    previousCity2: yup.mixed().when('hasPreviousAddress2', {
        is: (has) => has,
        then: (schema) => schema.required('Required'),
        otherwise: undefined
    }),
    previousProvince2: yup.mixed().when('hasPreviousAddress2', {
        is: (has) => has,
        then: (schema) => schema.required('Required'),
        otherwise: undefined
    }),
    previousMoveInDate2: yup.mixed().nullable().when('hasPreviousAddress2', {
        is: (has) => has,
        then: (schema) => schema.typeError('Required').required('Required'),
        otherwise: undefined
    }),
    previousMoveOutDate2: yup.mixed().nullable().when('hasPreviousAddress2', {
        is: (has) => has,
        then: (schema) => schema.typeError('Required').required('Required'),
        otherwise: undefined
    }),

    previousStreetAddress3: yup.mixed().nullable().when('hasPreviousAddress3', {
        is: (has) => has,
        then: (schema) => schema.required('Required'),
        otherwise: undefined
    }),
    previousCity3: yup.mixed().nullable().when('hasPreviousAddress3', {
        is: (has) => has,
        then: (schema) => schema.required('Required'),
        otherwise: undefined
    }),
    previousProvince3: yup.mixed().when('hasPreviousAddress3', {
        is: (has) => has,
        then: (schema) => schema.required('Required'),
        otherwise: undefined
    }),
    previousMoveInDate3: yup.mixed().nullable().when('hasPreviousAddress3', {
        is: (has) => has,
        then: (schema) => schema.typeError('Required').required('Required'),
        otherwise: undefined
    }),
    previousMoveOutDate3: yup.mixed().nullable().when('hasPreviousAddress3', {
        is: (has) => has,
        then: (schema) => schema.typeError('Required').required('Required'),
        otherwise: undefined
    }),

    englishLanguageProficiency: yup.string().required('Required'),

    cpsaLicense: yup.mixed().when("englishLanguageProficiency", {
        is: (val) => val == "IELTS",
        then: (schema) => schema.required('Required'),
        otherwise: undefined
    }),       

    cpsaLicenseNumber: yup.mixed().when(["englishLanguageProficiency", 'cpsaEvidence'], {
        is: (elp, evidence) => elp == "CPSA" && evidence == 'license',
        then: (schema) => schema.required('Required'),
        otherwise: undefined
    }),

    //// TEMP DISABLED
    //ieltstrfNumber: yup.mixed().when(["englishLanguageProficiency", /*'cpsaLicense',*/ 'cpsaStatus'], {
    //    is: (val, /*cpsaLicense,*/ cpsaStatus) => val == "IELTS" /*&& cpsaLicense == 'IELTS'*/ && cpsaStatus == 'Pass',
    //    then: (schema) => schema.required('Required'),
    //    otherwise: undefined
    //}),

    //// TEMP DISABLED
    //cpsaDateTaken: yup.mixed().nullable().when(["englishLanguageProficiency", 'cpsaLicense', 'cpsaStatus'], {
    //    is: (val, cpsaLicense, cpsaStatus) => {
    //        return val == "IELTS" && ['IELTS', 'OET', 'CELPIP'].indexOf(cpsaLicense || '') >= 0 && cpsaStatus == 'Pass';

    //        //console.log('validation.cpsaDateTaken ', val, cpsaLicense, result);

    //        //return result
    //    },
    //    then: (schema) => schema.required('Required'),
    //    //otherwise: undefined        
    //}),       
        
    cpsaStatus: yup.mixed().when(["englishLanguageProficiency", 'cpsaLicense'], {
        is: (val, cpsaLicense) => val == "IELTS" && ['IELTS', 'OET', 'CELPIP'].indexOf(cpsaLicense) >= 0,
        then: (schema) => schema.required('Required'),
        otherwise: undefined
    }),

    //// TEMP DISABLED
    //cpsaListening: yup.mixed().when(["englishLanguageProficiency", 'cpsaLicense', 'cpsaStatus'], {
    //    is: (val, cpsaLicense, cpsaStatus) => val == "IELTS" && cpsaLicense == 'IELTS' && cpsaStatus == 'Pass',
    //    then: (schema) => schema.test(validator5to9_step05).required('Required'),
    //    otherwise: undefined
    //}).when(["englishLanguageProficiency", 'cpsaLicense', 'cpsaStatus'], {
    //    is: (val, cpsaLicense, cpsaStatus) => val == "IELTS" && cpsaLicense == 'OET' && cpsaStatus == 'Pass',
    //    then: (schema) => schema.test(validatorOetValues).required('Required'),
    //    otherwise: undefined
    //}).when(["englishLanguageProficiency", 'cpsaLicense', 'cpsaStatus'], {
    //    is: (val, cpsaLicense, cpsaStatus) => val == "IELTS" && cpsaLicense == 'CELPIP' && cpsaStatus == 'Pass',
    //    then: (schema) => schema.test(validatorCelpipValues).required('Required'),
    //    otherwise: undefined
    //}),

    //// TEMP DISABLED ALL BELOW
    //cpsaReading: yup.mixed().when(["englishLanguageProficiency", 'cpsaLicense', 'cpsaStatus'], {
    //    is: (val, cpsaLicense, cpsaStatus) => val == "IELTS" && cpsaLicense == 'IELTS' && cpsaStatus == 'Pass',
    //    then: (schema) => schema.test(validator5to9_step05).required('Required'),
    //    otherwise: undefined
    //}).when(["englishLanguageProficiency", 'cpsaLicense', 'cpsaStatus'], {
    //    is: (val, cpsaLicense, cpsaStatus) => val == "IELTS" && cpsaLicense == 'OET' && cpsaStatus == 'Pass',
    //    then: (schema) => schema.test(validatorOetValues).required('Required'),
    //    otherwise: undefined
    //}).when(["englishLanguageProficiency", 'cpsaLicense', 'cpsaStatus'], {
    //    is: (val, cpsaLicense, cpsaStatus) => val == "IELTS" && cpsaLicense == 'CELPIP' && cpsaStatus == 'Pass',
    //    then: (schema) => schema.test(validatorCelpipValues).required('Required'),
    //    otherwise: undefined
    //}),


    //cpsaWriting: yup.mixed().when(["englishLanguageProficiency", 'cpsaLicense', 'cpsaStatus'], {
    //    is: (val, cpsaLicense, cpsaStatus) => val == "IELTS" && cpsaLicense == 'IELTS' && cpsaStatus == 'Pass',
    //    then: (schema) => schema.test(validator5to9_step05).required('Required'),
    //    otherwise: undefined
    //}).when(["englishLanguageProficiency", 'cpsaLicense', 'cpsaStatus'], {
    //    is: (val, cpsaLicense, cpsaStatus) => val == "IELTS" && cpsaLicense == 'OET' && cpsaStatus == 'Pass',
    //    then: (schema) => schema.test(validatorOetValues).required('Required'),
    //    otherwise: undefined
    //}).when(["englishLanguageProficiency", 'cpsaLicense', 'cpsaStatus'], {
    //    is: (val, cpsaLicense, cpsaStatus) => val == "IELTS" && cpsaLicense == 'CELPIP' && cpsaStatus == 'Pass',
    //    then: (schema) => schema.test(validatorCelpipValues).required('Required'),
    //    otherwise: undefined
    //}),


    //cpsaSpeaking: yup.mixed().when(["englishLanguageProficiency", 'cpsaLicense', 'cpsaStatus'], {
    //    is: (val, cpsaLicense, cpsaStatus) => val == "IELTS" && cpsaLicense == 'IELTS' && cpsaStatus == 'Pass',
    //    then: (schema) => schema.test(validator5to9_step05).required('Required'),
    //    otherwise: undefined
    //}).when(["englishLanguageProficiency", 'cpsaLicense', 'cpsaStatus'], {
    //    is: (val, cpsaLicense, cpsaStatus) => val == "IELTS" && cpsaLicense == 'OET' && cpsaStatus == 'Pass',
    //    then: (schema) => schema.test(validatorOetValues).required('Required'),
    //    otherwise: undefined
    //}).when(["englishLanguageProficiency", 'cpsaLicense', 'cpsaStatus'], {
    //    is: (val, cpsaLicense, cpsaStatus) => val == "IELTS" && cpsaLicense == 'CELPIP' && cpsaStatus == 'Pass',
    //    then: (schema) => schema.test(validatorCelpipValues).required('Required'),
    //    otherwise: undefined
    //}),


    mccCandidateCode: yup.string().required('Required'),
    //mincNumber: yup.string().required('Required'),

    mccqePart1DateTaken: yup.mixed().nullable().when('mccqePart1Pass', {
        is: true,
        then: (schema) => schema.typeError('Required').required('Required'),
        otherwise: undefined
    }),
    mccqePart1Score: yup.mixed().when('mccqePart1Pass', {
        is: true,
        then: (schema) => schema.required('Required'),
        otherwise: undefined
    }),
    mccqePart1Registered: yup.mixed().when('mccqePart1Pass', {
        is: false,
        then: (schema) => schema.required('Required'),
        otherwise: undefined
    }),
    mccqePart1RegisterIntend: yup.mixed().when('mccqePart1Registered', {
        is: (val) => val == 'No',
        then: (schema) => yup.bool().required('Required').oneOf([true], "Required"),//schema.bool().required('Required'),
        otherwise: undefined
    }),

    nacExamDateTaken: yup.mixed().nullable().when('nacExam', {
        is: true,
        then: (schema) => schema.typeError('Required').required('Required'),
        otherwise: undefined
    }),
    nacExamScore: yup.mixed().when(['nacExam', 'nacExamDateTaken', 'nacExamScoreType'], {
        is: (nacExam, dateTaken, nacExamScoreType) => {
            //if (nacExam && dateTaken != null) {
            //    return dateTaken < new Date(2020, 9, 1) || dateTaken > new Date(2021, 5, 30)
            //}
            //return false;
            return nacExam?.toString() == 'true' && nacExamScoreType == 'score';
            
        },
        then: (schema) => schema.test(isNumericValidator).required('Required'),
        otherwise: undefined
    }),
    nacRegisterIntend: yup.mixed().when(['nacExam', 'nacRetakeFall'], {
        is: (nacExam: boolean, nacRetakeFall: boolean) => {
            //console.log('nacRegisterIntend', nacExam, nacRetakeFall);
            return !nacExam && !nacRetakeFall
        },
        then: (schema) => yup.bool().required('Required').oneOf([true], "Required"),
        otherwise: undefined
    }),        
    appUserFiles: yup.array()
        .test({
            message: "All required file types have to be uploaded",
            test: (value) => {
                //return false;
                var filtered = (value || []).filter(x => x.mandatoryOnApply == true && (x.appUserFileId || '').length == 0);

                //console.log('filtered', filtered);
                return filtered.length == 0;
            }
        })   
    .required('Required')

    
});

