import * as React from 'react';
import { toast } from "react-toastify";

export function SaveSuccessMessage(props: { title: string; subtitle: string }) {

    return (
        <div className="notification-save-success media">
            <div className="circle -circle-green mr-3">
                <i className="fa fa-check"></i>
            </div>
            <div className="media-body">
                <h5>{props.title}</h5>
                {props.subtitle}
            </div>
        </div>);
}

function SaveFailedMessage() {
    return (<div className="notification-save-success media">
        <div className="circle -circle-green mr-3">
            <i className="fa fa-check"></i>
        </div>
        <div className="media-body">
            <h5>Saving failed <i className="fa fa-ban"></i></h5>
        </div>
    </div>
    );
}

export function showSaveSuccess() {
    return toast.success(<SaveSuccessMessage title="Saved!" subtitle="Page has been successfully saved." />, { autoClose: 2500, pauseOnHover: false, closeOnClick: false });
}

export function showDeletedMessage() {
    return toast.success(<SaveSuccessMessage title="Deleted" subtitle="Data was deleted." />, { autoClose: 2500, pauseOnHover: false, closeOnClick: false });
}

export const UIMessages = {
    showSaveSuccess,
    showDeletedMessage,
    showSaveFailed,
    showDeleteFailed
}

function DeleteFailedMessage(props: { title: string; subtitle: string }) {
    return (
        <div className="notification-save-warning media">
            <div className="circle -circle-green mr-3">
                <i className="fa fa-times"></i>
            </div>
            <div className="media-body">
                <h5>{props.title}</h5>
                {props.subtitle}
            </div>
        </div>);
}

function showDeleteFailed() {
    return toast.warn(<DeleteFailedMessage title="Failed" subtitle="Could not delete." />, { autoClose: 2500, pauseOnHover: false, closeOnClick: false });
}



export function showSaveFailed() {    
    return toast.error(<SaveFailedMessage />, { autoClose: 5000, pauseOnHover: false, closeOnClick: false });
}