import * as React from 'react';
import GenericEditor from './GenericEditor';
import { SubmissionCycleSettingsEditorFields } from '../editorFields/SubmissionCycleSettingsEditorFields';
import { SubmissionCycleSettings, ValidationSchema } from '../../models/SubmissionCycleSettings';
import { SubmissionCycleSettingsService } from '../../services/SubmissionCycleSettingsService';
import { SubmissionCycleService, SubmitAllUsersResult } from '../../services/SubmissionCycleService';
import { Result } from '../../services/ServiceBase';
import { useReducer } from 'react';
import { useParams } from 'react-router-dom';
import { Col, Row, Spinner } from 'reactstrap';


type State = {
    isLoading: boolean;
    submitAllUsersResult?: SubmitAllUsersResult;
};

const initialState: State = {
    isLoading: false,
    submitAllUsersResult: undefined
}

export const SubmissionCycleSettingsEditor = () => {

    const [state, setState] = useReducer(
        (prevState: State, newState: Partial<State>) => ({ ...prevState, ...newState }),
        initialState
    );

    const { id } = useParams();

    const runAutomaticSubmission = async () => {

        setState({
            isLoading: true
        });

        var result = await SubmissionCycleService.submitAllUsers(parseInt(id as string, 10));
        if (!result.hasErrors) {
            setState({
                isLoading: false,
                submitAllUsersResult: result.value
            })
        } else {
            setState({
                isLoading: false
            });
        }
    }


    return <>
        <Row>
            <Col>
                <div className="mb-2">
                    <button type="button" className="btn btn-primary" onClick={() => { runAutomaticSubmission() }} >Submit all users for current cycle
                        {state.isLoading && <Spinner color="primary" size="sm" className="ms-2" />}
                    </button>
                </div>
                {state.submitAllUsersResult && <dl className="row">
                    <dt className="col-sm-3">Total Profiles</dt>
                    <dd className="col-sm-9">{state.submitAllUsersResult.total}</dd>
                    <dt className="col-sm-3">Submited</dt>
                    <dd className="col-sm-9">{state.submitAllUsersResult.successSubmissions}</dd>
                    <dt className="col-sm-3">Skiped</dt>
                    <dd className="col-sm-9">{state.submitAllUsersResult.skiped}</dd>
                    <dt className="col-sm-3">Failed Submissions</dt>
                    <dd className="col-sm-9">{state.submitAllUsersResult.failedSubmissions}</dd>
                    <dt className="col-sm-3">Rebuilt Missing Files</dt>
                    <dd className="col-sm-9">{state.submitAllUsersResult.successRebuiltMissingFileTypes}</dd>
                    <dt className="col-sm-3">Rebuilt Missing Files Fails</dt>
                    <dd className="col-sm-9">{state.submitAllUsersResult.failedRebuiltMissingFileTypes}</dd>
                </dl>}

                <hr />

                {state.submitAllUsersResult &&
                    <pre>
                        {state.submitAllUsersResult.failedUserProfileIds.map((item) => item + "\n")}
                    </pre>
                }
            </Col>
        </Row>

        <Row className="mt-3">
            <Col>
                <GenericEditor<SubmissionCycleSettings, number>
                    getById={id => {
                        if (id > 0)
                            return SubmissionCycleSettingsService.get(id)
                        else {
                            var x = new SubmissionCycleSettings();
                            x.applicationStart = new Date();
                            x.applicationEnd = new Date();

                            x.questionCompletedCapOrPriorYear = '';
                            x.questionCpsaStatusDateBoundary = '';
                            x.questionEnglishLanguageProficiencyDateBoundary = '';
                            x.questionMCCQEPart1ShareScoreOnWebsiteByDateBoundary = '';
                            x.questionNACExamNoPassWillBeInDateBoundary = '';
                            x.questionNACExamShareScoreOnWebsiteByDateBoundary = '';                                          
                            x.questionMCCQEPart1DateBoundary = '';
                            x.questionMCCQEPart1SessionBoundary = '';
                            x.submissionCycleSettingsToFileTypeExtensionDates = [];

                            return Promise.resolve<Result<SubmissionCycleSettings>>(Result.FromValue(x) as Result<SubmissionCycleSettings>);
                        }
                    }}
                    save={model => SubmissionCycleSettingsService.save(model)}                    
                    formikFields={SubmissionCycleSettingsEditorFields}
                    validationSchema={ValidationSchema}
                    stringToId={x => parseInt(x, 10)}
                    getId={x => x.id}
                    shouldTryToLoad={id => true}
                />
            </Col>
        </Row>
    </>
}