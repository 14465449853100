import * as React from 'react';
import { useParams } from 'react-router-dom';
import GenericEditor from './GenericEditor';
import { UserAdministrativeExceptionAdminFields } from '../editorFields/UserAdministrativeExceptionAdminFields';
import { UserAdministrativeException, ValidationSchema } from '../../models/UserAdministrativeException';
import UserAdministrativeExceptionService  from '../../services/UserAdministrativeExceptionService';
import { AppUserFile, AppUserInfo, FileTypeToAppUserFile, SubmissionCycleSettingsToFileTypeExtensionDate } from '../../models';
import { useContext } from 'react';
import AuthenticationContext from '../context/AuthenticationContext';


export const AEEditor = () => {

    let { id } = useParams();

    return <>
        <GenericEditor<UserAdministrativeException, string>
            getById={id => UserAdministrativeExceptionService.getAE(id || '')}
            save={model => UserAdministrativeExceptionService.save(model)}
            formikFields={UserAdministrativeExceptionAdminFields}
            validationSchema={ValidationSchema}
            getId={x => x.userProfileId}
            stringToId={x => x}
            shouldTryToLoad={id => true}
            disableSaveButton={false}
            errorsMap={{

            }}
        />

        
    </>
}
