import * as React from 'react';
import { GroupSetCustom, GroupSetProps, SetFieldValueProp } from './index';
import { useField } from "formik";
import { useContext } from 'react';
import { FormViewContext } from '../context/FormViewContext';


export const GroupSetTextArea = (props: GroupSetProps & { textareaProps?: React.TextareaHTMLAttributes<HTMLTextAreaElement> } & Partial<SetFieldValueProp>) => {
    const [field, meta, helpers] = useField(props.fieldName);
    field.value = field.value || '';


    if (props.setFieldValue) {
        field.onChange = (e) => { props.setFieldValue?.(props.fieldName, e.target.value) };
    }

    const formViewContext = useContext(FormViewContext);

    return (<GroupSetCustom {...props}>        
        <textarea {...field} {...props.textareaProps} className={"form-control " + ((meta.error && meta.touched && "is-invalid") || '')} disabled={props.disabled || formViewContext.readonly == true} />
    </GroupSetCustom>);
}