import * as React from 'react';
import { useReducer, useEffect, useRef, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Spinner, Button, Container, Row, Col } from 'reactstrap';
import InfiniteScroll from 'react-infinite-scroller';
import * as ArrayUtils from 'typescript-array-utils';

type ChatSendMessageAreaState = {
    message: string;
}

export const ChatSendMessageArea = (props: {
    onSend: (message: string) => void;
}) => {

    let [state, setState] = useReducer(
        (prevState: ChatSendMessageAreaState, newState: Partial<ChatSendMessageAreaState>) => ({ ...prevState, ...newState }),
        {
            message: ''
        }
    );

    return <div className="send-message-area d-flex">

        <textarea value={state.message} onChange={(e) => { setState({ message: e.target.value }) }} className="d-flex-grow-1"></textarea>

        <Button type="button" className="btn-chat-send" onClick={() => {

            if (state.message.length > 0) {
                props.onSend(state.message);
                setState({ message: '' });
            }

        }}></Button>
    </div>
};