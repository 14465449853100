import * as React from "react";
import * as FormUtils from '../formUtils/index';
import { UserAdministrativeException } from '../../models/UserAdministrativeException';
import { Button, Col, Row, Tooltip } from "reactstrap";
import { FormikProps, FormikValues } from "formik";
import { useState } from "react";
import { formatDate } from '../../utils';
//import AuthenticationContext from "../context/AuthenticationContext";
//import { useContext } from "react";

import { UserAdministrativeExceptionChat } from '../../models/UserAdministrativeExceptionChat';
import UserAdministrativeExceptionChatService from '../../services/UserAdministrativeExceptionChatService';

interface IProps {
    values: UserAdministrativeException;
}

type LookupItem = {
    value: number;
    text: string;
}

const Situations: LookupItem[] = [{
    value: 1,
    text: 'Employment letter does no meet the requirement. This may be due to it being provided by the Human Resources department and not including all necessary information.'
}, {
    value: 2,
    text: 'Status in Canada supporting document(s) cannot be submitted by the deadline.'
}, {
    value: 3,
    text: 'Other'
}];




export function UserAdministrativeExceptionEditorFields(props: FormikProps<FormikValues>) {

    const values = props.values as UserAdministrativeException;

    //const authCtx = useContext(AuthenticationContext);

    // const isReadOnly = values.userProfileId.length > 0;
    const isReadOnly = true;

    const sendChat = () => {
        let newChat: UserAdministrativeExceptionChat = {
            userAdministrativeExceptionChatId: 0,
            userAdministrativeExceptionId: values.userAdministrativeExceptionId,
            chatMessage: "Hello World",
            dateAdded: new Date(),
            isAdmin: false,
            aeType: 1,
            adminRead: false,
            userRead: true
        };
        UserAdministrativeExceptionChatService.add(newChat);
    }

    return (<React.Fragment>

        <div className="form-section">

            <div className="card mb-3 p-3">
                <h3>Administrative Exceptions</h3>

                <Row>
                    <Col sm="12" md="12">
                        <FormUtils.GroupSetDropdown<LookupItem>
                            fieldName="situationId"
                            title="Check the box that BEST describes your situation:"
                            dropdownProps={{
                                dataSource: Situations,
                                getId: x => x.value,
                                renderItem: x => x.text,
                                renderSelectedItem: x => x.text,
                                selectedValue: values.situationId
                            }}
                            disabled={isReadOnly}
                            required
                        />
                        
                    </Col>
                </Row>

                <Row>
                    <Col sm="12" md="12">
                        <FormUtils.GroupSetTextArea
                            fieldName="description"
                            title="Please explain your situation further. Only forms with a detailed explanation will be reviewed. (1000 characters max)"
                            disabled={isReadOnly}
                            controlSize="large"
                            required
                        />
                    </Col>
                </Row>

                <Row>
                    <Col sm="12" md="12">
                        <p><strong>I certify the above information is correct. I understand the instructions provided, and I will provide the required supporting documents.</strong></p>
                        <FormUtils.GroupSetCheckbox
                            fieldName="agree"
                            title="I Agree"
                            explanationText=""
                            disabled={isReadOnly}
                            required
                        />
                    </Col>
                </Row>

                {values.administrativeExceptionId && <>
                    <h3>Status: {values.administrativeException}</h3>
                </>}

                <textarea id="txtAEChat" className="form-control "></textarea>
                <button id="btnAEChat" className="btn btn-default" onClick={sendChat}>Send</button>

                <></>

                <hr />

                <FormUtils.GroupSetCheckbox
                    fieldName="hasAE2"
                    title="Add Another Exception"
                    explanationText=""
                    disabled={isReadOnly}
                />

                {values.hasAE2 && <>
                    <h3>Administrative Exception 2</h3>
                    <Row>
                        <Col sm="12" md="12">
                            <FormUtils.GroupSetDropdown<LookupItem>
                                fieldName="situationId2"
                                title="Check the box that BEST describes your situation:"
                                dropdownProps={{
                                    dataSource: Situations,
                                    getId: x => x.value,
                                    renderItem: x => x.text,
                                    renderSelectedItem: x => x.text,
                                    selectedValue: values.situationId2
                                }}
                                disabled={isReadOnly}
                                required
                            />

                        </Col>

                    </Row>

                    <Row>
                        <Col sm="12" md="12">
                            <FormUtils.GroupSetTextArea
                                fieldName="description2"
                                title="Please explain your situation further. Only forms with a detailed explanation will be reviewed. (1000 characters max)"
                                disabled={isReadOnly}
                                controlSize="large"
                                required
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col sm="12" md="12">
                            <p><strong>I certify the above information is correct. I understand the instructions provided, and I will provide the required supporting documents.</strong></p>
                            <FormUtils.GroupSetCheckbox
                                fieldName="agree2"
                                title="I Agree"
                                explanationText=""
                                disabled={isReadOnly}
                                required
                            />
                        </Col>
                    </Row>

                    <hr />

                    <FormUtils.GroupSetCheckbox
                        fieldName="hasAE3"
                        title="Add Another Exception"
                        explanationText=""
                        disabled={isReadOnly}
                    />

                    {values.hasAE3 && <>
                        <h3>Administrative Exception 3</h3>
                        <Row>
                            <Col sm="12" md="12">
                                <FormUtils.GroupSetDropdown<LookupItem>
                                    fieldName="situationId3"
                                    title="Check the box that BEST describes your situation:"
                                    dropdownProps={{
                                        dataSource: Situations,
                                        getId: x => x.value,
                                        renderItem: x => x.text,
                                        renderSelectedItem: x => x.text,
                                        selectedValue: values.situationId3
                                    }}
                                    disabled={isReadOnly}
                                    required
                                />

                            </Col>

                        </Row>

                        <Row>
                            <Col sm="12" md="12">
                                <FormUtils.GroupSetTextArea
                                    fieldName="description3"
                                    title="Please explain your situation further. Only forms with a detailed explanation will be reviewed. (1000 characters max)"
                                    disabled={isReadOnly}
                                    controlSize="large"
                                    required
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Col sm="12" md="12">
                                <p><strong>I certify the above information is correct. I understand the instructions provided, and I will provide the required supporting documents.</strong></p>
                                <FormUtils.GroupSetCheckbox
                                    fieldName="agree3"
                                    title="I Agree"
                                    explanationText=""
                                    disabled={isReadOnly}
                                    required
                                />
                            </Col>
                        </Row>
                    </>}
                </>}

            </div>

        </div>

    </React.Fragment>);
}

