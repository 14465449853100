import { ServiceBase, Result } from "./ServiceBase";
import { EnglishLanguageProficiency } from "../models/index";

export class EnglishLanguageProficiencyService extends ServiceBase {

    public static async list(): Promise<Result<EnglishLanguageProficiency[]>> {

        var result = await this.requestJson<EnglishLanguageProficiency[]>({
            url: `api/EnglishLanguageProficiency/list`,          
            method: "GET"
        });

        return result;
    }   
}