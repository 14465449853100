import * as yup from 'yup';
//import { AppUserFile } from './AppUserFile';
export class UserAdministrativeException {
    userAdministrativeExceptionId: number;
    userAdministrativeExceptionStringId: string;
    userProfileId: string;
    dateAdded: Date;
    situationId?: number;
    description?: string;
    agree: boolean;
    administrativeExceptionId: number;
    administrativeException: string;
    hasAE2: boolean;
    dateAdded2?: Date;
    situationId2?: number;
    description2?: string;
    agree2?: boolean;
    administrativeExceptionId2?: number;
    administrativeException2: string;
    hasAE3: boolean;
    dateAdded3?: Date;
    situationId3?: number;
    description3?: string;
    agree3?: boolean;
    administrativeExceptionId3?: number;
    administrativeException3: string;
    adminNote1?: string;
    adminNote2?: string;
    adminNote3?: string;
}

const situations = [{ "id": 1, "name": "Employment letter does no meet the requirement. This may be due to it being provided by the Human Resources department and not including all necessary information." },
    { "id": 2, "name": "Status in Canada supporting document(s) cannot be submitted by the deadline. " },
    { "id": 3, "name": "Other" }];

export const DateFields = [
    "dateAdded"
];

export const ValidationSchema = yup.object().shape({
    //situationId: yup.string().required('Required'),
    //description: yup.string().required('Required').max(1000),
    //agree: yup.boolean().required('Required'),

    //situationId2: yup.mixed().when('hasAE2', {
    //    is: (has) => has,
    //    then: (schema) => schema.required('Required'),
    //    otherwise: undefined
    //}),

    //description2: yup.mixed().when('hasAE2', {
    //    is: (has: boolean) => has,
    //    then: (schema) => schema.required('Required'),
    //    otherwise: undefined
    //}),
    //agree2: yup.mixed().when('hasAE2', {
    //    is: (has) => has,
    //    then: (schema) => schema.required('Required'),
    //    otherwise: undefined
    //}),

    //situationId3: yup.mixed().when('hasAE3', {
    //    is: (has) => has,
    //    then: (schema) => schema.required('Required'),
    //    otherwise: undefined
    //}),
    //description3: yup.mixed().when('hasAE3', {
    //    is: (has) => has,
    //    then: (schema) => schema.required('Required'),
    //    otherwise: undefined
    //}),
    //agree3: yup.mixed().when('hasAE3', {
    //    is: (has) => has,
    //    then: (schema) => schema.required('Required'),
    //    otherwise: undefined
    //}),
});