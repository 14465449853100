import * as React from 'react';
import { useReducer, useEffect, useRef, useState } from 'react';
//import { Link } from 'react-router-dom';
import { Spinner, Button, Container, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
//import InfiniteScroll from 'react-infinite-scroller';
//import * as ArrayUtils from "typescript-array-utils";
import { UIMessages } from '../UIUtils';
//import { PagedResult, Result } from '../../services/ServiceBase';
import { AlbertaResidencyType, AppUserFile, FileType } from '../../models';
import AppUserFilesService from '../../services/AppUserFileService';
import { formatDate, downloadFile, bytesToFriendly } from '../../utils';
//import axios from 'axios';
import { FileTypeService } from '../../services/FileTypeService';
import { Formik } from 'formik';
import { ValidationSchema } from '../../models/AppUserFile';
import * as FormUtils from '../formUtils/index';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FileSubtype } from '../../models/FileSubtype';
import { AlbertaResidencyTypeService } from '../../services/AlbertaResidencyTypeService';
import UserProfileService from '../../services/UserProfileService';

type Props = {
    //triggerRefresh: number;
    aeOnly: boolean;

}

type State = {
    isLoading: boolean;
    //items: AppUserFile[];
    isNoResults: boolean;
    fileTypes: FileType[];
    profileResidenceType?: AlbertaResidencyType;
    showCompleteProfileFirstMessage: boolean;
    hasAgreedFiles: boolean;
}



export const AppUserFilesSearch = (props: Props) => {

    const [state, setState] = useReducer(
        (prevState: State, newState: Partial<State>) => ({ ...prevState, ...newState }),
        {
            isLoading: false,
            //items: [],
            isNoResults: false,
            fileTypes: [],
            profileResidenceType: undefined,
            showCompleteProfileFirstMessage: false,
            hasAgreedFiles: false
        }
    );


    const loadProfileResidenceType = async () => {

        var result = await AlbertaResidencyTypeService.getProfileResidenseTypeOption();

        if (!result.hasErrors) {
            console.log('Result', result);
            setState({
                profileResidenceType: result.value,
                showCompleteProfileFirstMessage: (result.value?.type || '').length == 0
            })
        }
    }

    const loadData = async () => {

        setState({ isLoading: true });

        var result = await FileTypeService.list(props.aeOnly);

        if (!result.hasErrors) {
            setState({
                fileTypes: result.value,
                isLoading: false
            })
        } else {
            setState({
                isLoading: false
            })
        }
    }

    const saveAgree = async () => {
        let results = UserProfileService.saveAgreedFiles(state.hasAgreedFiles);
        console.log('saveagreedfiles', results);
    }

    const getAgree = async () => {

        var result = await UserProfileService.getAgreedFiles();

        if (!result.hasErrors) {
            setState({
                hasAgreedFiles: result.value,
            })
        }
    }

    useEffect(() => {
        loadProfileResidenceType();
        loadData()
        getAgree();
    }, [])

    return <Row>
        <Col sm="12">
            {!props.aeOnly && <h3>Supporting Documents</h3>}

            {props.aeOnly && <h3>Administrative Exception Request Documents</h3>}

            <div className="alert alert-secondary">
                <p className="disclaimer">Please ensure your documents are no larger than 20MB in size.</p>
            </div>
            <div className="disclaimer-box for-fields col-sm-12">
                <p className="disclaimer"><span style={{fontWeight: 600}}>Please note. Larger files can only be seen via download. This is not an issue. If you can see the document, we can see the document.</span></p>
                <p className="disclaimer">Submitting an Administrative Exception request does not alter the Important Dates and Deadlines to which all applicants must adhere, unless an AE is granted for an alternative deadline.</p>
                <p className="disclaimer">Documents uploaded to the Administrative Exceptions tab as part of the AE request process do not become part of the applicant's AIMG Program application.</p>
            </div>

            {!props.aeOnly && <>
                <div className="disclaimer-box for-fields col-sm-12">
                    <p className="disclaimer">By checking this box, applicants acknowledge that they are responsible for ensuring all supporting documents meet the minimum eligibility criteria. The AIMG Program does not provide advisory services or screen documents prior to file review. During the extensive and comprehensive file review process, each application is thoroughly examined. Applicants must ensure that documents are accurately notarized/commissioned, the notary is in good standing, and images, signatures, and seals are present and legible. It is the applicant's duty to meet all posted deadlines and provide strong evidence for proof of residence in Alberta, including proving all months for Option C. Applicants will not receive reminders or opportunities to correct deficiencies for which they are responsible.</p>
                    <p><input type="checkbox" id="chkAgree" className="form-check-input" checked={state.hasAgreedFiles} onClick={(e) => setState({ hasAgreedFiles: e.currentTarget.checked })}></input>
                        <label htmlFor="chkAgree" className="form-check-label  mx-2">I Agree</label></p>

                    <button className="btn btn-white" onClick={() => saveAgree()}>Save</button>
                </div>

                {state.profileResidenceType?.type == 'A' && <>
                    <p>On the previous Profile page, you selected <strong>Option A</strong> - Full-time attendance at an accredited Alberta senior high school for 2 or more years, at least two semesters per academic year.</p>
                    <p>These are the files that are required.</p>
                    <ul className="required-docs-list">
                        <li>Application Fee Tracking Receipt</li>
                        <li>Applicant Identify Form</li>
                        <li>Name Verification</li>
                        <li>Admin Exception (if applicable)</li>
                        <li>Official Transcript High School</li>
                        <li>Status in Canada</li>
                        <li>English Language Proficiency</li>
                        <li>QE1 Payment Receipt (if applicable)</li>
                        <li>NAC Payment Receipt (if applicable)</li>
                        <li>Medical Degree/Declaration</li>
                        <li>Application Checklist</li>
                    </ul>
                </>}

                {state.profileResidenceType?.type == 'B' &&
                    <>
                        <p>On the previous Profile page, you selected <strong>Option B</strong> - Full-time attendance at an accredited post-secondary institution in Alberta in an undergraduate program for 2 or more years; at least two semesters per academic year.</p>
                        <p>These are the files that are required.</p>
                        <ul className="required-docs-list">
                            <li>Application Fee Tracking Receipt</li>
                            <li>Applicant Identify Form</li>
                            <li>Name Verification</li>
                            <li>Admin Exception (if applicable)</li>
                            <li>Official Transcript Post Secondary</li>
                            <li>Status in Canada</li>
                            <li>English Language Proficiency</li>
                            <li>QE1 Payment Receipt (if applicable)</li>
                            <li>NAC Payment Receipt (if applicable)</li>
                            <li>Medical Degree/Declaration</li>
                            <li>Application Checklist</li>
                        </ul>
                    </>
                }

                {state.profileResidenceType?.type == 'C' && <>
                    <p>On the previous Profile page, you selected <strong>Option C</strong> - Currently physically present and living on a day-to-day basis in the province of Alberta and have done so continuously since December 1, 2023.</p>
                    <p>These are the files that are required.</p>
                    <ul className="required-docs-list">
                        <li>Application Fee Tracking Receipt</li>
                        <li>Applicant Identify Form</li>
                        <li>Name Verification</li>
                        <li>Admin Exception (if applicable)</li>
                        <li>Alberta Residency Statutory Declaration</li>
                        <li>Letters of Employment/Guarantor Letters</li>
                        <li>Alberta Drivers License or ID Card</li>
                        <li>Alberta Health Care Card</li>
                        <li>Status in Canada</li>
                        <li>English Language Proficiency</li>
                        <li>QE1 Payment Receipt (if applicable)</li>
                        <li>NAC Payment Receipt (if applicable)</li>
                        <li>Medical Degree/Declaration</li>
                        <li>Application Checklist</li>
                    </ul>
                </>}
            </>}

            {
                state.showCompleteProfileFirstMessage && <p className="alert alert-warning">
                    Please complete your user profile first
                </p>
            }

            {!state.showCompleteProfileFirstMessage && state.fileTypes
                /*.filter((val) => {
                    return (state.profileResidenceType?.type || '').length > 0
                        && (val.visibleForResidence || '').length > 0
                        && (val.visibleForResidence || '').toLocaleLowerCase().indexOf((state.profileResidenceType?.type || '').toLowerCase()) > -1
                })*/
                .map((item, index: number) => {

                    var isVisible = (state.profileResidenceType?.type || '').length > 0
                        && (item.visibleForResidence || '').length > 0
                        && (item.visibleForResidence || '').toLocaleLowerCase().indexOf((state.profileResidenceType?.type || '').toLowerCase()) > -1

                    if (!isVisible)
                        return null;

                    return <AppFileTypeSetView key={item.id}
                        fileType={item}
                        rowNumber={index + 1}
                    />
                }
                )}
            {state.isLoading &&
                <div className="text-center">
                    <Spinner color="primary" size="sm" />
                </div>
            }


        </Col>
    </Row>
}

type AppFileTypeSetViewState = {
    showConfirm: boolean;
    items: AppUserFile[],
    isLoading: boolean;
    showModal: boolean;
    isSaving: boolean;
    reloadTrigger: number;
    modalUsedFor: 'add' | 'update';
    editObject: AppUserFile;
    modalKey: number;
}

const AppFileTypeSetView = (props: {
    fileType: FileType;
    rowNumber: number;
}) => {

    const getNewAppUserEditModel = () => {

        var result = new AppUserFile();
        result.fileMimeType = '';
        result.fileTypeId = props.fileType.id;
        result.id = '';
        result.fileDescription = '';

        return result;
    }

    const [state, setState] = useReducer(
        (prevState: AppFileTypeSetViewState, newState: Partial<AppFileTypeSetViewState>) => ({ ...prevState, ...newState }),
        {
            isLoading: false,
            items: [],
            showConfirm: false,
            showModal: false,
            isSaving: false,
            reloadTrigger: 0,
            modalUsedFor: 'add',
            editObject: getNewAppUserEditModel(),
            modalKey: 0
        }
    );

    const loadAppUserFile = async () => {

        var userId = '';

        setState({ isLoading: true });

        var result = await AppUserFilesService.getByUserAndFileType(props.fileType.id, userId);

        if (!result.hasErrors) {
            setState({
                items: result.value,
                isLoading: false
            });
        } else {
            setState({ isLoading: false });
        }

    }

    useEffect(() => {
        loadAppUserFile();
    }, [state.reloadTrigger])


    return <div className="card mb-3">
        <div className="card-body">
            <h5>
                {(props.fileType.fileLinkUrl || '').length > 0
                    ? <a href={props.fileType.fileLinkUrl} target="_blank" className="text-decoration-none"><span className="text-decoration-underline">{props.fileType.description}</span> {props.fileType.mandatoryOnApply && <sup className="required-mark">*</sup>} <sup className=""><FontAwesomeIcon icon={solid("arrow-up-right-from-square")} /></sup></a>
                    : <>{props.fileType.description} {props.fileType.mandatoryOnApply && <sup className="required-mark">*</sup>}</>
                }
            </h5>
            {props.fileType.fileAdditionalMessage && <div className="additional-message">{props.fileType.fileAdditionalMessage}</div>}

            <table className="table table-hover">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">File Name</th>
                        <th scope="col">Upload Date</th>
                        <th scope="col">File Size</th>
                        <th scope="col">View</th>
                        <th scope="col">Actions</th>
                    </tr>
                </thead>
                <tbody>

                    {state.items.map((item, index) =>
                        <AppFileRow key={item.id}
                            {...{
                                item: item,
                                index: index,
                                onDeleted: () => { loadAppUserFile(); },
                                onSaved: () => { loadAppUserFile(); }
                            }}
                        />
                    )}


                </tbody>
            </table>

            <button type="button" className="btn btn-primary btn-outline" onClick={() => {
                setState({
                    showModal: true,
                    editObject: state.editObject,
                    modalKey: state.modalKey + 1
                })

            }}>add new</button>

            <Formik key={state.modalKey}
                enableReinitialize={true}
                validationSchema={ValidationSchema}
                validateOnBlur={false}
                initialValues={state.editObject}
                onSubmit={async (values, actions) => {

                    setState({ isSaving: true });

                    var saveResult = await AppUserFilesService.save(values);

                    if (!saveResult.hasErrors) {
                        setState({
                            showModal: false,
                            isSaving: false,
                            reloadTrigger: state.reloadTrigger + 1
                        })
                    } else {
                        setState({ isSaving: false })
                    }

                }}
                validateOnMount={true}
            >
                {(formikState) => {

                    const fileSizeTooLow = formikState.values.fileSize != undefined && formikState.values.fileSize < 1024;
                    const canSave = formikState.isValid && !state.isLoading && !fileSizeTooLow;

                    //let canSave = formikState.isValid && !state.isLoading;

                    return (
                        <Modal isOpen={state.showModal} size="lg">
                            <ModalHeader toggle={() => { setState({ showModal: !state.showModal }) }}>Upload file for <strong>{props.fileType.description}</strong></ModalHeader>

                            <ModalBody>
                                <FormUtils.GroupSetFileUpload
                                    title=""
                                    fieldName="fileBinnary"
                                    controlSize="large"
                                />

                                {(props.fileType.subTypes || []).length > 0 &&
                                    <FormUtils.GroupSetDropdown<FileSubtype>
                                        title="Type Of Document"
                                        fieldName="fileSubtypeId"
                                        controlSize="large"
                                        dropdownProps={{
                                            dataSource: props.fileType.subTypes,
                                            getId: x => x.id,
                                            renderItem: x => x.description,
                                            renderSelectedItem: x => x.description,
                                            selectedValue: formikState.values.fileSubtypeId
                                        }}
                                    />
                                }

                            </ModalBody>

                            <ModalFooter>
                                <Button color="primary"
                                    disabled={!canSave}
                                    onClick={() => { formikState.submitForm() }}
                                    size="sm">
                                    {state.isSaving && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                    Save
                                </Button>{' '}
                                <Button outline color="primary" size="sm" onClick={() => {
                                    formikState.resetForm();
                                    setState({ showModal: false })
                                }}>
                                    Cancel
                                </Button>
                            </ModalFooter>
                        </Modal>
                    )
                }}
            </Formik>

        </div>
    </div>
}

type AppFileRowState = {
    showConfirm: boolean;
    isLoading: boolean;
    isSaving: boolean;
    showModal: boolean;
}

const AppFileRow = (props: {
    item: AppUserFile,
    index: number,
    onDeleted: () => void,
    onSaved: () => void
}) => {

    const [state, setState] = useReducer(
        (prevState: AppFileRowState, newState: Partial<AppFileRowState>) => ({ ...prevState, ...newState }),
        {
            showConfirm: false,
            isLoading: false,
            isSaving: false,
            showModal: false
        }
    );

    const deleteFile = async (id: string) => {

        console.log('AppUserFilesSearch deleteFile');

        setState({
            isLoading: true
        })

        var result = await AppUserFilesService.delete(id);

        if (!result.hasErrors) {

            UIMessages.showDeletedMessage();

            props.onDeleted()

        } else {
            UIMessages.showDeleteFailed();
        }
    }


    return <tr key={props.item.id}>
        <td>{props.index + 1}</td>
        <td>
            <a href={`/api/appuserfile/open/${props.item.id}/${props.item.fileName}`} target="_blank">
                {props.item.fileName}
            </a>
            <div className="small">{props.item.fileSubtypeDescription}</div>
        </td>
        <td>{formatDate(props.item.fileDate)}</td>
        <td>{bytesToFriendly(props.item.fileSize)}</td>
        <td>
            <Button color="secondary" onClick={(e) => { e.preventDefault(); downloadFile(`api/appuserfile/download/${props.item.id}`, props.item.fileName) }}>
                <i className="fa fa-download" aria-hidden="true"></i> view
            </Button>
        </td>
        <td>
            {state.showConfirm == false && <>
                <button type="button" className="btn btn-primary me-2" onClick={() => { setState({ showConfirm: true }) }}>delete</button>
                <button type="button" className="btn btn-primary btn-outline" onClick={() => {
                    setState({
                        showModal: true
                    })
                }}>replace</button>
            </>
            }
            {state.showConfirm == true &&
                <>
                    <button type="button" className="btn btn-success me-2" onClick={() => { deleteFile(props.item.id) }}>confirm</button>
                    <button type="button" className="btn btn-danger me-2" onClick={() => {
                        setState({
                            showConfirm: false
                        })
                    }}>cancel</button>
                </>
            }
            {
                <>



                    <Formik
                        enableReinitialize={true}
                        validationSchema={ValidationSchema}
                        validateOnBlur={false}
                        initialValues={props.item}
                        onSubmit={async (values, actions) => {

                            setState({ isSaving: true });

                            var saveResult = await AppUserFilesService.save(values);

                            if (!saveResult.hasErrors) {
                                setState({
                                    showModal: false,
                                    isSaving: false,
                                    //reloadTrigger: state.reloadTrigger + 1
                                })
                                props.onSaved()
                            } else {
                                setState({ isSaving: false })
                            }

                        }}
                        validateOnMount={true}
                    >
                        {(formikState) => {

                            //let canSave = formikState.isValid && formikState.dirty && !state.isLoading;
                            const fileSizeTooLow = formikState.values.fileSize != undefined && formikState.values.fileSize < 1024;
                            const canSave = formikState.isValid && !state.isLoading && !fileSizeTooLow;

                            return (
                                <Modal isOpen={state.showModal}>
                                    <ModalHeader toggle={() => { setState({ showModal: !state.showModal }) }}>Replace file</ModalHeader>

                                    <ModalBody>
                                        <FormUtils.GroupSetFileUpload
                                            title=""
                                            fieldName="fileBinnary"
                                            controlSize="large"
                                        />
                                    </ModalBody>

                                    <ModalFooter>
                                        <Button color="primary"
                                            disabled={!canSave}
                                            onClick={() => { formikState.submitForm() }}>
                                            {state.isSaving && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                            Save
                                        </Button>{' '}
                                        <Button color="secondary" onClick={() => {
                                            formikState.resetForm();
                                            setState({ showModal: false })
                                        }}>
                                            Cancel
                                        </Button>
                                    </ModalFooter>
                                </Modal>
                            )
                        }}
                    </Formik>

                </>
            }


        </td>
    </tr>
}