import { ServiceBase, Result, PagedResult, convertStringsToDates } from "./ServiceBase";
import { FileSubtypeView } from "../models/index";

export class FileSubtypeService extends ServiceBase {

    public static async list(): Promise<Result<FileSubtypeView[]>> {

        var result = await this.requestJson<FileSubtypeView[]>({
            url: `api/FileSubtype/list`,
            method: "GET"
        });

        return result;
    }
}