import { ServiceBase, Result, PagedResult, convertStringsToDates } from "./ServiceBase";
import { Province } from "../models/index";

export class ProvinceService extends ServiceBase {

    public static async list(): Promise<Result<Province[]>> {

        var result = await this.requestJson<Province[]>({
            url: `api/province/list`,          
            method: "GET"
        });

        return result;
    }   
}