import * as React from 'react';
import { useState, useEffect, useRef, useId } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Spinner } from 'reactstrap';
import { Result } from '../../services/ServiceBase';
import cn from 'classnames';

export type GenericDropdownAsyncProps<T, ItemID> = {
    loadDataSource: () => Promise<Result<T[]>>;
    dataSourceDependentParams?: any[];
    preprocessDataSource?: (items: T[]) => T[];
    renderItem: (item: T) => React.ReactNode;
    renderSelectedItem: (item: T) => React.ReactNode;
    onSelectedItemChanged?: (selectedItem: T | null) => void;
    getId: (item: T) => ItemID;
    selectedValue?: ItemID | undefined;
    allowNullSelection?: boolean;
    nullSelectionText?: string;
    className?: string;
    onBlur?: (event: React.FocusEvent<HTMLElement>) => void;
    disabled?: boolean;
    selectFirstByDefault?: boolean;
}

export function GenericDropdownAsync<T, ItemID>(props: GenericDropdownAsyncProps<T, ItemID>) {

    const mountRed = useRef(true);
    const [ready, setReady] = useState(false);
    const [dataSource, setDataSource] = useState<T[]>([]);
    const [selectedItem, setSelectedItem] = useState<T>();
    const [opened, setOpened] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const loadDataSource = () => {
        setIsLoading(true);

        props.loadDataSource().then(x => {

            if (!mountRed.current)
                return;

            if (!x.hasErrors) {

                let ds = (props.preprocessDataSource)
                    ? props.preprocessDataSource(x.value)
                    : x.value;

                setDataSource(ds);

                if (props.selectFirstByDefault == true && ds.length > 0) {
                    if (props.onSelectedItemChanged)
                        props.onSelectedItemChanged(ds[0]);
                    else
                        setSelectedItem(ds[0]);
                }
            }

            setReady(true);
            setIsLoading(false);
        });
    }

    const recalcSelectedItem = () => {

        let newValue;

        if (props.selectedValue && dataSource) {
            newValue = dataSource.find(x => props.selectedValue == props.getId(x));
        }

        if (newValue != selectedItem) {

            setSelectedItem(newValue);

            if (props.onSelectedItemChanged)
                props.onSelectedItemChanged(newValue);
        }
    }

    useEffect(() => {

        if (ready) {
            recalcSelectedItem();
        }

    }, [props.selectedValue, dataSource, ready])


    // on component mount
    useEffect(() => {

        // keep track of mounted state
        return (() => {
            mountRed.current = false;
        })

    }, []);


    // reload ds on component mount or datasource dependent params change
    useEffect(() => {

        loadDataSource();

    }, props.dataSourceDependentParams || []);

    const ddId = useId();

    return (<React.Fragment>
        <Dropdown id={ddId} className={props.className} disabled={props.disabled} onBlur={props.onBlur} isOpen={opened} toggle={() => {
            setOpened(!opened);
            if (!isLoading && !ready) {
                loadDataSource();
            }
        }}>
            <DropdownToggle caret onBlur={props.onBlur} id={ddId + 'tg'} disabled={props.disabled}>
                {selectedItem && props.renderSelectedItem(selectedItem as T)}
                {!selectedItem && (props.nullSelectionText || 'Select')}
            </DropdownToggle>
            {
                ready ? <DropdownMenu>
                    <div className="dropdown-menu-inner">
                        {props.allowNullSelection === true &&
                            <DropdownItem key="null-selection" onClick={x => {
                                props.onSelectedItemChanged && props.onSelectedItemChanged(null);
                                setSelectedItem(undefined);
                            }} className="clear-selection">
                                {props.nullSelectionText || "Select"}
                            </DropdownItem>
                        }
                        {dataSource.map(
                            (item: T) => <DropdownItem key={(props.getId(item) as any as string)} className={cn({ "active": props.getId(item) == props.selectedValue })} onClick={x => {
                                if (props.onSelectedItemChanged)
                                    props.onSelectedItemChanged(item);
                                else
                                    setSelectedItem(item);
                            }}>
                                {props.renderItem(item)}
                            </DropdownItem>)}
                    </div>
                </DropdownMenu>
                    : <DropdownMenu>
                        <Spinner />
                    </DropdownMenu>
            }
        </Dropdown>
    </React.Fragment>);
}

