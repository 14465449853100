import { ServiceBase, Result, PagedResult, convertStringsToDates } from "./ServiceBase";
import { TeamReviewCaRMSEligible } from "../models/index";

export class TeamReviewCaRMSEligibleService extends ServiceBase {

    public static async list(): Promise<Result<TeamReviewCaRMSEligible[]>> {

        var result = await this.requestJson<TeamReviewCaRMSEligible[]>({
            url: `api/TeamReviewCaRMSEligible/list`,          
            method: "GET"
        });

        return result;
    }   
}