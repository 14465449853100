
export class ChatMessage {
    id: number;
    clientId?: string;
    fromUserId: string;
    toUserId?: string;
    message: string;
    created: Date;
    readAt?: Date;
}

export class UserOnlineChangedData {
    userId: string;
    isOnline: boolean;
} 

export class MessageViewedInfo {
    id: number;
    readAt?: Date;
}

export const DateFields = [
    "created",
    "readAt"    
];

