import * as React from 'react';
import { GroupSetCustom, GroupSetProps } from './index';
import { useField } from "formik";
import { useContext, useEffect, useState } from 'react';
import { dateFormatString } from '../../utils';
import DatePicker, { ReactDatePickerProps } from "react-datepicker";
//import "react-datepicker/dist/react-datepicker.css";
//import DatePicker, { DatePickerProps } from 'react-date-picker';
//import 'react-date-picker/dist/DatePicker.css';

import { FormViewContext } from '../context/FormViewContext';
import TimePicker, { TimePickerValue } from 'react-time-picker';
//import 'react-time-picker/dist/TimePicker.css';
//import 'react-clock/dist/Clock.css';
//import Timeit from "react-timeit";
//import "react-timeit/dist/index.css";
import { format } from 'date-fns';



declare type GroupSetDatePickerProps = GroupSetProps & {
    date: Date | null;
    pickerConfig?: Partial<ReactDatePickerProps>;
    //pickerConfig?: Partial<DatePickerProps>;
    onValueChanged?: () => void;
    noTime?: boolean;
    showYearPicker?: boolean;
};

export const GroupSetDatePicker = (props: GroupSetDatePickerProps) => {
    const [field, meta, helpers] = useField(props.fieldName);
    const [pickerDate, setPickerDate] = useState<Date | null>(props.date);
    const [timeDate, setTimeDate] = useState<Date | null>(props.date);
    const formViewContext = useContext(FormViewContext);
    //const [timeValue, onTimeChange] = useState('00:00');

    const onTimeChangeHandler = (x: TimePickerValue) => {



        var currentDate = props.date as Date;

        const hours = parseInt((x?.toString() || '00:00').substr(0, 2), 10);
        const minutes = parseInt((x?.toString() || '00:00').substr(3), 10);

        currentDate = new Date(currentDate.setHours(hours, minutes, 0, 0));

        setTimeDate(currentDate);
        setPickerDate(currentDate);
        helpers.setValue(currentDate);

        //console.log('currentDate after', currentDate);
    }

    const getTimeValue = (): string => {

        if (pickerDate == undefined)
            return '00:00';

        var formatedDate = format(pickerDate, "HH:mm");

        //console.log('formated date', formatedDate);

        return formatedDate;
    }



    useEffect(() => {
        if (pickerDate != (props.date)) {
            setTimeDate((props.date));
            setPickerDate((props.date));
            helpers.setValue((props.date));
        }
    }, [props.date]);

    useEffect(() => {

        if (props.onValueChanged)
            props.onValueChanged();

    }, [pickerDate])

    return (<GroupSetCustom {...props}>
        <div className={"form-control date-picker " + (!props.noTime ? " with-time " : "")}>
            <DatePicker
                selected={pickerDate}
                dateFormat={dateFormatString}
                onChange={date => {
                    //console.log('date change to', date);

                    if (props.noTime === true && date)
                        date = new Date(date.setHours(0, 0, 0, 0));
                    //else
                    //date = new Date(date);
                    //if (timeDate != null) {
                    //    date = new Date(date.setHours(timeDate.getHours(), timeDate.getMinutes()));
                    //}


                    //console.log('processed date change to', date);

                    setPickerDate(date);
                    helpers.setValue(date);
                    //props.setFieldValue(props.fieldName, date)
                }}
                //showYearPicker={props.showYearPicker}
                {...{ ...props.pickerConfig, ...{ className: "form-control " + (meta.error && meta.touched && " is-invalid ") }} }
                disabled={props.disabled || formViewContext.readonly}
                //readOnly={props.disabled}
                maxDate={props.pickerConfig?.maxDate || new Date(new Date().setFullYear(new Date().getFullYear() + 5))}
            />
            {props.noTime != true && <TimePicker className="ms-3" onChange={onTimeChangeHandler} value={getTimeValue()} />}           
        </div>
    </GroupSetCustom>);
}