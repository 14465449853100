import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
// import 'bootstrap/dist/css/bootstrap.css';
//import './index.css';
import { ReduxRouter } from '@lagunovsky/redux-react-router';
import { createBrowserHistory } from 'history';
import configureStore from './store/configureStore';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';



// Create browser history to use in the Redux store

const history = createBrowserHistory();
const store = configureStore(history);

const root = createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    //<React.StrictMode> // su: enabling strict mode, will cause useffect run twice, we don't want this
        <Provider store={store}>
            <ReduxRouter history={history}>
                <App />
            </ReduxRouter>
        </Provider>
    //</React.StrictMode>
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
