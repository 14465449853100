import * as React from "react";
import * as FormUtils from '../formUtils/index';
import { AlbertaResidencyType, EnglishLanguageProficiency, IELTSStatus, MedicalDegreeCountry, Province, StatusInCanada, UserProfile } from "../../models/index";
import { AlbertaResidencyTypeService } from '../../services/AlbertaResidencyTypeService';
import { IELTSStatusService } from '../../services/IELTSStatusService';
import { ProvinceService } from '../../services/ProvinceService';
import { MedicalDegreeCountryService } from '../../services/MedicalDegreeCountryService';
import { EnglishLanguageProficiencyService } from '../../services/EnglishLanguageProficiencyService';
import { StatusInCanadaService } from '../../services/StatusInCanadaService';
import { Button, Col, Row, Tooltip } from "reactstrap";
import { FormikProps, FormikValues } from "formik";
import { useState } from "react";
//import AuthenticationContext from "../context/AuthenticationContext";
//import { useContext } from "react";

interface IProps {
    values: UserProfile;
}

type LookupItem = {
    value: string;
    text: string;
}

const cpsaLicenseOptions: LookupItem[] = [{
    value: 'IELTS',
    text: 'IELTS'
}, {
    value: 'OET',
    text: 'OET'
},
{
    value: 'CELPIP',
    text: 'CELPIP'
}];

const cpsaEvidenceOptions: LookupItem[] = [{
    value: 'license',
    text: 'CPSA License'
}, {
    value: 'letter',
    text: 'Letter of Exemption'
}];

const nacExamScoreTypesOptions: LookupItem[] = [{
    value: 'pass',
    text: 'PASS (score not provided)'
}, {
    value: 'pass_superior',
    text: 'PASS WITH SUPERIOR PERFORMANCE (score not provided)'
}, {
    value: 'score',
    text: 'Score'
}];



export function UserProfileEditorFields(props: FormikProps<FormikValues>) {

    const values = props.values as UserProfile;

    //const authCtx = useContext(AuthenticationContext);

    // values.submissionCycleSettings.applicationEnd
    // const isReadOnly = values.userProfileId.length > 0 && values.isApplicationSubmission == true;
    let isReadOnly = true;
    /*
    if (values.appUserId == '6aa7dc27-3ea5-4081-bec7-72ed904cc41f') {
        isReadOnly = false;
    }
    if (values.appUserId == 'a5e5bcf7-1b47-4f89-b28b-d8ab6bd7db05') {
        isReadOnly = false;
    }
    if (values.appUserId == '79fa859f-49f2-4fbd-b902-8df44de28c00') {
        isReadOnly = false;
    }
    if (values.appUserId == 'f33cde3a-bfa9-447a-afc3-9aa737bf4e44') {
        isReadOnly = false;
    }
    */

    const getAdditionalTextOnIssuingCountryMedicalDegreee = (country: string) => {

        const countries = ["Canada", "United States of America"];

        const matchingCountry = countries.filter(x => x.toLowerCase() == country.toLowerCase());
        if (matchingCountry.length > 0)
            return <>{country} - <span className="text-danger">Not eligible for this program</span></>

        return country;
    };

    const expectNumericValue = () => {

        if (values.nacExamDateTaken != null) {

            return values.nacExamDateTaken < new Date(2020, 9, 1) || values.nacExamDateTaken > new Date(2021, 5, 30);
        }

        return false;
    }

    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

    //console.log('props UserProfileEditorFields: ', props);

    return (<React.Fragment>

        <div className="form-section">

            {/*<div className="disclaimer-box for-fields col-sm-12">*/}
            {/*    <p className="disclaimer">The main supporting documents deadline was 3:30pm MT June 7, 2024. We are downloading locked files. Applicants will not be able to access their online application while we complete this work. Thank you for your patience.</p>*/}
            {/*</div>*/}

            <div className="disclaimer-box for-fields col-sm-12">
                <p className="disclaimer">By selecting this checkbox, applicants confirm their responsibility for ensuring the accuracy of all information on their profile page. Applicants will not receive reminders or opportunities to rectify any inaccuracies for which they are accountable. Please thoroughly review your profile to ensure the correctness of birthdates, addresses, and exam scores.<br />
                <br />
                * You must agree to this step before you can upload supporting documents.</p>

                <FormUtils.GroupSetCheckbox
                    fieldName="hasAgreedProfile"
                    title="I Agree"
                    explanationText=""
                    disabled={isReadOnly}
                    required
                />
            </div>

            <div className="card mb-3 p-3">
                <h3>Personal Background Information</h3>

                <Row>
                    <Col sm="12" md="6">
                        <FormUtils.GroupSetText
                            fieldName="surname"
                            title="Surname"
                            disabled={isReadOnly}
                            required
                        />
                        
                    </Col>
                    <Col sm="12" md="6">
                        <FormUtils.GroupSetText
                            fieldName="formerSurname"
                            title="Former Surname"
                            disabled={isReadOnly}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col sm="12" md="6">
                        <FormUtils.GroupSetText
                            fieldName="givenNames"
                            title="Given Name(s)"
                            disabled={isReadOnly}
                            required
                        />
                    </Col>
                    <Col sm="12" md="6">
                        <FormUtils.GroupSetText
                            fieldName="preferredName"
                            title="Preferred Given Name"
                            disabled={isReadOnly}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <FormUtils.GroupSetDatePicker
                            fieldName="dateOfBirth"
                            title="Date Of Birth"
                            noTime={true}
                            pickerConfig={{
                                minDate: new Date(1950, 1, 1),
                                maxDate: new Date(),
                                showMonthDropdown: true,
                                showYearDropdown: true,
                                dropdownMode: 'select',
                                className: "form-control"
                            }}
                            date={values?.dateOfBirth}
                            disabled={isReadOnly}
                            required
                        />
                    </Col>
                    <Col>
                        <FormUtils.GroupSetDropdown<string>
                            fieldName="gender"
                            title="Gender"
                            dropdownProps={{
                                dataSource: ["Female", "Male", "X"],
                                getId: x => x,
                                renderItem: x => x,
                                renderSelectedItem: x => x,
                                selectedValue: values.gender
                            }}
                            disabled={isReadOnly}
                            required
                        />
                    </Col>
                </Row>

            </div>

            <FormUtils.GroupSetRadio
                title="Is this the name used on your physiciansapply.ca account?"
                fieldName="physiciansApply"
                options={["Yes", "No"]}
                disabled={isReadOnly}
                required />


            {values.physiciansApply == 'No' && <>
                <div className="card mb-3 p-3">
                    <h3>Indicate your name as seen on your physiciansapply.ca profile.</h3>
                    <Row>
                        <Col sm="12" md="6">
                            <FormUtils.GroupSetText
                                fieldName="physiciansApplySurname"
                                title="physiciansapply.ca Surname"
                                disabled={isReadOnly}
                                required />
                        </Col>
                        <Col sm="12" md="6">
                            <FormUtils.GroupSetText
                                fieldName="physiciansApplyGivenNames"
                                title="physiciansapply.ca Given Names"
                                disabled={isReadOnly}
                                required />
                        </Col>
                    </Row>
                </div>
            </>}

            <div className="card mb-3 p-3">

                <Row>
                    <Col sm="12" md="6">
                        <FormUtils.GroupSetText
                            fieldName="currentStreetAddress"
                            title="Current Street Address"
                            disabled={isReadOnly}
                            required />
                    </Col>
                    <Col sm="12" md="6">
                        <FormUtils.GroupSetText
                            fieldName="currentTownCity"
                            title="Current Town/City"
                            disabled={isReadOnly}
                            required />
                    </Col>
                </Row>

                <Row>
                    <Col sm="12" md="6">
                        <FormUtils.GroupSetText
                            fieldName="currentProvince"
                            title="Current Province"
                            disabled={isReadOnly}
                            required />

                        {/*<FormUtils.GroupSetDropdownAsync<Province, string>
                            fieldName="currentProvince"
                            title="Current Province"
                            disabled={isReadOnly}
                            dropdownProps={{
                                loadDataSource: () => ProvinceService.list(),
                                getId: x => x.name,
                                renderItem: x => x.name,
                                renderSelectedItem: x => x ? x.name : 'Select',
                                selectedValue: values.currentProvince
                            }}
                            required
                        />*/}
                    </Col>
                    <Col sm="12" md="6">
                        <FormUtils.GroupSetText
                            fieldName="currentPostalCode"
                            title="Current Postal Code"
                            disabled={isReadOnly}
                            required />
                    </Col>
                </Row>

                <Row>
                    <Col sm="12" md="6">
                        <FormUtils.GroupSetDropdownAsync<MedicalDegreeCountry, string>
                            fieldName="currentCountry"
                            title="Current Country"
                            dropdownProps={{
                                loadDataSource: () => MedicalDegreeCountryService.list(),
                                getId: x => x.country,
                                renderItem: x => x.country,
                                renderSelectedItem: x => x ? x.country : 'Select',
                                selectedValue: values.currentProvince
                            }}
                            disabled={isReadOnly}
                            required
                        />
                    </Col>
                    <Col sm="12" md="6">
                        <FormUtils.GroupSetPhoneInput
                            fieldName="telephone"
                            title="Telephone"
                            disabled={isReadOnly}
                            required />
                    </Col>
                </Row>

            </div>

            <FormUtils.GroupSetRadio
                title="Is this also your mailing address?"
                fieldName="sameAsMailAddress"
                options={["Yes", "No"]}
                disabled={isReadOnly}
                required
            />

            {values.sameAsMailAddress == 'No' && <>
                <div className="card mb-3 p-3">
                    <Row>
                        <Col sm="12" md="6">
                            <FormUtils.GroupSetText
                                fieldName="mailStreetAddress"
                                title="Mailing Street Address"
                                disabled={isReadOnly}
                                required
                            />
                        </Col>
                        <Col sm="12" md="6">
                            <FormUtils.GroupSetText
                                fieldName="mailCity"
                                title="Mailing Town/City"
                                disabled={isReadOnly}
                                required />
                        </Col>
                    </Row>

                    <Row>
                        <Col sm="12" md="6">
                            <FormUtils.GroupSetText
                                title="Mailing Province/Territory"
                                fieldName="mailProvince"
                                disabled={isReadOnly}
                                required
                            />
                        </Col>
                        <Col sm="12" md="6">
                            <FormUtils.GroupSetText
                                fieldName="mailPostalCode"
                                title="Mailing Postal Code"
                                disabled={isReadOnly}
                                required
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col sm="12" md="6">
                            <FormUtils.GroupSetDropdownAsync<MedicalDegreeCountry, string>
                                fieldName="mailCountry"
                                title="Mailing Country"
                                dropdownProps={{
                                    loadDataSource: () => MedicalDegreeCountryService.list(),
                                    getId: x => x.country,
                                    renderItem: x => x.country,
                                    renderSelectedItem: x => x ? x.country : 'Select',
                                    selectedValue: values.currentProvince
                                }}
                                disabled={isReadOnly}
                                required
                            />
                        </Col>
                        <Col sm="12" md="6">
                        </Col>
                    </Row>
                </div>
            </>}

            <Row>
                <Col sm="12" md="6">
                    <FormUtils.GroupSetText
                        fieldName="email1"
                        title="Email Address"
                        explanationText="Email address changes must be done through your User Profile."
                        disabled={isReadOnly}
                    />
                </Col>
                <Col sm="12" md="6">
                </Col>
            </Row>

            <FormUtils.GroupSetCheckbox
                fieldName="acknowledgeEmailResponsibility"
                title="I agree with the following terms and conditions"
                explanationText="The AIMG Program will use the email address above to communicate with you throughout the application and eligibility process; you must advise the AIMG Program if you change the email address to which the AIMG Program should send correspondence. Correspondence sent to this email address and left unread by an applicant will not be accepted as an excuse for missed deadline(s)."
                disabled={isReadOnly}
                required
            />

            <FormUtils.GroupSetRadio
                fieldName="applicantIdentityDeclaration"
                title="Supporting Document Declaration"
                options={["Yes;I can provide all required supporting documentation and will proceed with the application.", "No;I cannot provide all of the required supporting documentation and will submit an <a href='https://www.aimg.ca/eligibility-supporting-documents-requirements/administrative-exceptions/' target='_blank'>Administrative Exception Request</a> within the stipulated timelines."]}
                disabled={isReadOnly}
                required
            />

            <FormUtils.GroupSetRadio
                title={<>I have completed <a href='https://www.aimg.ca/how-it-works/clinical-assessment-placement/' target='_blank'>Clinical Assessment Placement</a> {values.submissionCycleSettings.questionCompletedCapOrPriorYear}</>}
                fieldName="capParticipant"
                options={["Yes", "No"]}
                disabled={isReadOnly}
            />

        </div>

        <div className="form-section">
            <h3>Eligibility Requirements</h3>
            <div className="card mb-3 p-3">
                <Row>
                    <Col>
                        <FormUtils.GroupSetDropdownAsync<MedicalDegreeCountry, string>
                            fieldName="medicalDegreeCountry"
                            title="Issuing Country on Medical Degree"
                            dropdownProps={{
                                loadDataSource: () => MedicalDegreeCountryService.list(),
                                getId: x => x.country,
                                renderItem: x => getAdditionalTextOnIssuingCountryMedicalDegreee(x.country),
                                renderSelectedItem: x => x ? getAdditionalTextOnIssuingCountryMedicalDegreee(x.country) : 'Select',
                                selectedValue: values.medicalDegreeCountry
                            }}
                            disabled={isReadOnly}
                            required
                        />
                    </Col>
                    <Col>
                        <FormUtils.GroupSetText
                            fieldName="region"
                            title="Region"
                            disabled={isReadOnly}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <FormUtils.GroupSetText
                            fieldName="universityName"
                            title="University Name"
                            explanationText={<p className="mt-3">The AIMG Program requires that the diploma granting medical school be listed in the World Directory of <a href='https://www.wdoms.org/' target='_blank'>Medical Schools</a> and that the degree be recognized in Canada. It is the responsibility of the applicant to confirm that these requirements are met. Please confirm and use the name of your medical school according to the World Directory List.</p>}
                            disabled={isReadOnly}
                            required
                        />
                    </Col>
                    <Col>
                        <FormUtils.GroupSetText
                            fieldName="medicalDegreeName"
                            title="Medical Degree Name"
                            disabled={isReadOnly}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <FormUtils.GroupSetText
                            fieldName="medicalSchoolGraduationYear"
                            title="Medical School Graduation Year / Expected Graduation Year"
                            maxLength={4}
                            disabled={isReadOnly}
                            required
                        />
                    </Col>
                    <Col>
                        <FormUtils.GroupSetDropdown<string>
                            fieldName="medicalSchoolGraduationMonth"
                            title="Graduation Month"
                            dropdownProps={{
                                dataSource: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
                                getId: x => x,
                                renderItem: x => x,
                                renderSelectedItem: x => x,
                                selectedValue: values.medicalSchoolGraduationMonth
                            }}
                            disabled={isReadOnly}
                            required
                        />
                    </Col>
                </Row>
            </div>

            <FormUtils.GroupSetRadioAsync<StatusInCanada>
                fieldName="statusInCanada"
                title="Status In Canada"
                getDisplayText={x => x.value}
                getValue={x => x.key}
                loadDataSource={() => StatusInCanadaService.list()}
                preprocessDataSource={(items) => items.map(x => {
                    x.value = x.value.replace("{DateBoundary}", values.submissionCycleSettings.questionStatusInCanadaDateBoundary)
                    return x;
                })}
                disabled={isReadOnly}
                required
            />

            <FormUtils.GroupSetRadioAsync<AlbertaResidencyType>
                fieldName="albertaResidencyType"
                title="Proof of Residence in Alberta"
                getDisplayText={x => 'Option ' + x.type + ' - ' + x.description}
                getValue={x => x.type}
                loadDataSource={() => AlbertaResidencyTypeService.list()}
                preprocessDataSource={(items) => items.map(x => {
                    x.description = x.description.replace("{DateBoundary}", values.submissionCycleSettings.questionProofOfResidenceInAlbertaDateBoundary)
                    return x;
                })}
                disabled={isReadOnly}
                required
            />

            <FormUtils.GroupSetDatePicker
                fieldName="currentAddressMoveInDate"
                title="Living at address entered above (current address) since"
                noTime={true}
                pickerConfig={{
                    minDate: new Date(1950, 1, 1),
                    maxDate: new Date(),
                    showMonthDropdown: true,
                    showYearDropdown: true,
                    dropdownMode: 'select'

                }}
                date={props.values?.currentAddressMoveInDate}
                disabled={isReadOnly}
                required
            />

            {props.values.hasPreviousAddress1 == false && <FormUtils.GroupSetCustom
                fieldName="hasPreviousAddress1"
                title={null}
            >
                <Button type="button" color="primary" onClick={() => { props.setFieldValue('hasPreviousAddress1', true, true) }} disabled={isReadOnly}>Add Previous Address</Button>
            </FormUtils.GroupSetCustom>}

            {values.hasPreviousAddress1 && <div className="card mb-3 p-3">
                <h3>Previous Address 1</h3>
                <FormUtils.GroupSetText
                    fieldName="previousStreetAddress1"
                    title="Street Address"
                    explanationText="Optional additional Alberta address to help prove third option of Residence in Alberta (above)."
                    disabled={isReadOnly}
                    required
                />

                <Row>
                    <Col>
                        <FormUtils.GroupSetText
                            fieldName="previousCity1"
                            title="Town/City"
                            disabled={isReadOnly}
                            required
                        />
                    </Col>
                    <Col>
                        <FormUtils.GroupSetDropdownAsync<Province, string>
                            fieldName="previousProvince1"
                            title="Province"
                            dropdownProps={{
                                loadDataSource: () => ProvinceService.list(),
                                getId: x => x.name,
                                renderItem: x => x.name,
                                renderSelectedItem: x => x ? x.name : 'Select',
                                selectedValue: props.values.previousProvince1
                            }}
                            disabled={isReadOnly}
                            required
                        />
                    </Col>
                </Row>


                <Row>
                    <Col>
                        <FormUtils.GroupSetDatePicker
                            fieldName="previousMoveInDate1"
                            title="From"
                            noTime={true}
                            pickerConfig={{
                                minDate: new Date(1950, 1, 1),
                                maxDate: new Date(),
                                showMonthDropdown: true,
                                showYearDropdown: true,
                                dropdownMode: 'select'
                            }}
                            date={values?.previousMoveInDate1}
                            disabled={isReadOnly}
                            required
                        />
                    </Col>
                    <Col>
                        <FormUtils.GroupSetDatePicker
                            fieldName="previousMoveOutDate1"
                            title="To"
                            noTime={true}
                            pickerConfig={{
                                minDate: new Date(1950, 1, 1),
                                maxDate: new Date(),
                                showMonthDropdown: true,
                                showYearDropdown: true,
                                dropdownMode: 'select'
                            }}
                            date={values?.previousMoveOutDate1}
                            disabled={isReadOnly}
                            required
                        />
                    </Col>
                </Row>

                <FormUtils.GroupSetCustom
                    fieldName="hasPreviousStreetAddress1"
                    title={null}
                >
                    <Button type="button" color="primary" onClick={() => { props.setFieldValue('hasPreviousAddress1', false, true) }} disabled={isReadOnly}>Remove this Address</Button>
                </FormUtils.GroupSetCustom>

            </div>
            }

            {values.hasPreviousAddress2 == false && values.hasPreviousAddress1 && <FormUtils.GroupSetCustom
                fieldName="hasPreviousAddress2"
                title={null}
            >
                <Button type="button" color="primary" onClick={() => { props.setFieldValue('hasPreviousAddress2', true, true) }} disabled={isReadOnly}>Add Another Address</Button>
            </FormUtils.GroupSetCustom>}

            {values.hasPreviousAddress2 && <div className="card mb-3 p-3">
                <h3>Previous Address 2</h3>
                <p>Please enter any previous addresses you have resided in over the last two years.</p>

                <FormUtils.GroupSetText
                    fieldName="previousStreetAddress2"
                    title="Street Address"
                    explanationText="Optional additional Alberta address to help prove third option of Residence in Alberta (above)."
                    disabled={isReadOnly}
                    required
                />
                <Row>
                    <Col>
                        <FormUtils.GroupSetText
                            fieldName="previousCity2"
                            title="Town/City"
                            disabled={isReadOnly}
                            required
                        />
                    </Col>
                    <Col>
                        <FormUtils.GroupSetDropdownAsync<Province, string>
                            fieldName="previousProvince2"
                            title="Province"
                            dropdownProps={{
                                loadDataSource: () => ProvinceService.list(),
                                getId: x => x.name,
                                renderItem: x => x.name,
                                renderSelectedItem: x => x ? x.name : 'Select',
                                selectedValue: values.previousProvince2
                            }}
                            disabled={isReadOnly}
                            required
                        />
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <FormUtils.GroupSetDatePicker
                            fieldName="previousMoveInDate2"
                            title="From"
                            noTime={true}
                            pickerConfig={{
                                minDate: new Date(1950, 1, 1),
                                maxDate: new Date(),
                                showMonthDropdown: true,
                                showYearDropdown: true,
                                dropdownMode: 'select'
                            }}
                            date={values?.previousMoveInDate2}
                            disabled={isReadOnly}
                            required
                        />
                    </Col>
                    <Col>
                        <FormUtils.GroupSetDatePicker
                            fieldName="previousMoveOutDate2"
                            title="To"
                            noTime={true}
                            pickerConfig={{
                                minDate: new Date(1950, 1, 1),
                                maxDate: new Date(),
                                showMonthDropdown: true,
                                showYearDropdown: true,
                                dropdownMode: 'select'
                            }}
                            date={values?.previousMoveOutDate2}
                            disabled={isReadOnly}
                            required
                        />
                    </Col>
                </Row>

                <FormUtils.GroupSetCustom
                    fieldName="hasPreviousAddress2"
                    title={null}
                >
                    <Button type="button" color="primary" onClick={() => { props.setFieldValue('hasPreviousAddress2', false, true) }} disabled={isReadOnly}>Remove this Address</Button>
                </FormUtils.GroupSetCustom>
            </div>
            }


            {values.hasPreviousAddress3 == false && values.hasPreviousAddress2 && <FormUtils.GroupSetCustom
                fieldName="hasPreviousAddress2"
                title={null}
            >
                <Button type="button" color="primary" onClick={() => { props.setFieldValue('hasPreviousAddress3', true, true) }} disabled={isReadOnly}>Add Another Address</Button>
            </FormUtils.GroupSetCustom>}

            {values.hasPreviousAddress3 && <div className="card mb-3 p-3">
                <h3>Previous Address 3</h3>
                <FormUtils.GroupSetText
                    fieldName="previousStreetAddress3"
                    title="Street Address"
                    explanationText="Optional additional Alberta address to help prove third option of Residence in Alberta (above)."
                    disabled={isReadOnly}
                    required
                />
                <Row>
                    <Col>
                        <FormUtils.GroupSetText
                            fieldName="previousCity3"
                            title="Town/City"
                            disabled={isReadOnly}
                            required
                        />
                    </Col>
                    <Col>
                        <FormUtils.GroupSetDropdownAsync<Province, string>
                            fieldName="previousProvince3"
                            title="Province"
                            dropdownProps={{
                                loadDataSource: () => ProvinceService.list(),
                                getId: x => x.name,
                                renderItem: x => x.name,
                                renderSelectedItem: x => x ? x.name : 'Select',
                                selectedValue: values.previousProvince3
                            }}
                            disabled={isReadOnly}
                            required
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormUtils.GroupSetDatePicker
                            fieldName="previousMoveInDate3"
                            title="From"
                            noTime={true}
                            pickerConfig={{
                                minDate: new Date(1950, 1, 1),
                                maxDate: new Date(),
                                showMonthDropdown: true,
                                showYearDropdown: true,
                                dropdownMode: 'select'
                            }}
                            date={values?.previousMoveInDate3}
                            disabled={isReadOnly}
                            required
                        />
                    </Col>
                    <Col>
                        <FormUtils.GroupSetDatePicker
                            fieldName="previousMoveOutDate3"
                            title="To"
                            noTime={true}
                            pickerConfig={{
                                minDate: new Date(1950, 1, 1),
                                maxDate: new Date(),
                                showMonthDropdown: true,
                                showYearDropdown: true,
                                dropdownMode: 'select'
                            }}
                            date={values?.previousMoveOutDate3}
                            disabled={isReadOnly}
                            required
                        />
                    </Col>
                </Row>

                <FormUtils.GroupSetCustom
                    fieldName="hasPreviousAddress3"
                    title={null}
                >
                    <Button type="button" color="primary" onClick={() => { props.setFieldValue('hasPreviousAddress3', false, true) }}
                        disabled={isReadOnly}
                    >Remove this Address</Button>
                </FormUtils.GroupSetCustom>
            </div>}

            <FormUtils.GroupSetRadioAsync<EnglishLanguageProficiency>
                fieldName="englishLanguageProficiency"
                title="English Language Proficiency"
                getDisplayText={x => x.description}
                getValue={x => x.key}
                loadDataSource={() => EnglishLanguageProficiencyService.list()}
                preprocessDataSource={(items) => items.map(x => {
                    x.description = x.description.replace("{DateBoundary}", values.submissionCycleSettings.questionEnglishLanguageProficiencyDateBoundary)
                    return x;
                })}
                vertical={true}
                explanationText={values.englishLanguageProficiency == 'Exemption' ? <>WARNING!  Choosing this option will require applicants to complete the English Language Proficiency (ELP) Exemption Form.  Please refer to the Eligibility & Supporting Documents/English Language Proficiency webpage.  ELP exemptions are subject to CPSA approval.  Previous CPSA registration or exemption does not guarantee ELP exemption.  Failure to complete the Exemption Form for this option will result in an incomplete application.</> : null}
                disabled={isReadOnly}
                required
            />

            {values.englishLanguageProficiency == "CPSA" && <>

                <div className="card mb-3 p-3">
                    <FormUtils.GroupSetDropdown<LookupItem>
                        fieldName="cpsaEvidence"
                        title="CPSA Evidence"
                        dropdownProps={{
                            dataSource: cpsaEvidenceOptions,
                            getId: x => x.value,
                            renderItem: x => x.text,
                            renderSelectedItem: x => x.text,
                            selectedValue: values.cpsaEvidence
                        }}
                        disabled={isReadOnly}
                    />

                    {values.cpsaEvidence == 'license' &&
                        <FormUtils.GroupSetText
                            fieldName="cpsaLicenseNumber"
                            title="CPSA License Number"
                            disabled={isReadOnly}
                            required
                        />}
                </div>
            </>}

            {values.englishLanguageProficiency == "IELTS" && <div className="card mb-3 p-3">
                <FormUtils.GroupSetDropdown<LookupItem>
                    fieldName="cpsaLicense"
                    title="ELP Exams"
                    dropdownProps={{
                        dataSource: cpsaLicenseOptions,
                        getId: x => x.value,
                        renderItem: x => x.text,
                        renderSelectedItem: x => x.text,
                        selectedValue: values.cpsaLicense
                    }}
                    disabled={isReadOnly}
                />

                {values.cpsaLicense != 'CPSALicenseNumber' && values.cpsaLicense.length > 0 && <>

                    <FormUtils.GroupSetRadioAsync<IELTSStatus>
                        fieldName="cpsaStatus"
                        title="Status"
                        getDisplayText={x => x.status}
                        getValue={x => x.key}
                        loadDataSource={() => IELTSStatusService.list()}
                        preprocessDataSource={(items) => items.map(x => {
                            var status = x.status;
                            if (values.cpsaLicense == 'IELTS')
                                status = status.replace('OET Candidate Number', 'IELTS Academic Version Test Report Form');
                            else if (values.cpsaLicense == 'CELPIP')
                                status = status.replace('OET Candidate Number', 'CELPIP General Examination Official Score Report');
                            x.status = status.replace("{DateBoundary}", values.submissionCycleSettings.questionCpsaStatusDateBoundary)
                            return x;
                        })}
                        vertical={true}
                        disabled={isReadOnly}
                        required
                    />

                    <FormUtils.GroupSetDatePicker
                        fieldName="cpsaDateTaken"
                        title="Date Taken"
                        noTime={true}
                        date={values.cpsaDateTaken}
                        disabled={isReadOnly}
                        pickerConfig={{
                            minDate: new Date(1950, 1, 1),
                            maxDate: new Date(),
                            showMonthDropdown: true,
                            showYearDropdown: true,
                            dropdownMode: 'select'
                        }}
                    />

                    {/*values.cpsaLicense == 'IELTS' &&
                        <FormUtils.GroupSetText
                            fieldName="ieltstrfNumber"
                            title="TRF Number"
                            disabled={isReadOnly}
                        />
                    */}

                    <FormUtils.GroupSetText
                        fieldName="ieltstrfNumber"
                        title={<>{values.cpsaLicense == 'IELTS' && <>TRF Number</>}{values.cpsaLicense == 'OET' && <>OET Candidate Number</>}{values.cpsaLicense == 'CELPIP' && <>CELPIP General Examination Official Score Report</>}</>}
                        disabled={isReadOnly}
                    />


                    <FormUtils.GroupSetText
                        fieldName="cpsaListening"
                        title="Listening score"
                        disabled={isReadOnly}
                    />

                    <FormUtils.GroupSetText
                        fieldName="cpsaReading"
                        title="Reading score"
                        disabled={isReadOnly}
                    />

                    <FormUtils.GroupSetText
                        fieldName="cpsaSpeaking"
                        title="Speaking score"
                        disabled={isReadOnly}
                    />

                    <FormUtils.GroupSetText
                        fieldName="cpsaWriting"
                        title="Writing score"
                        disabled={isReadOnly}
                    /></>}



            </div>}

            <FormUtils.GroupSetText
                fieldName="mccCandidateCode"
                title="MCC Candidate Code"
                maxLength={11}
                disabled={isReadOnly}
                required
            />

            <FormUtils.GroupSetText
                fieldName="caRmsId"
                title="CaRMS ID#"
                disabled={isReadOnly}
            />

            <div className="card mb-3 p-3">
                <h3>
                    Medical Council of Canada Qualifying Exam (MCCQE) Part 1
                </h3>
                <FormUtils.GroupSetRadio
                    fieldName="mccqePart1Pass"
                    title="Do you currently have a PASS standing for MCCQE Part 1"
                    vertical
                    valueAsBoolean={true}
                    options={["true;I currently have a PASS score", "false;I currently do NOT have a PASS score"]}
                    explanationText={values.mccqePart1Pass && <div className="fst-italic">Please share your MCCQE Part 1 exam on your <a href="https://physicianapply.ca">physicianapply.ca</a> profile by {values.submissionCycleSettings.questionMCCQEPart1ShareScoreOnWebsiteByDateBoundary}.</div>}
                    disabled={isReadOnly}
                />

                {values.mccqePart1Pass?.toString() == "true" &&
                    <>
                        <FormUtils.GroupSetDatePicker
                            fieldName="mccqePart1DateTaken"
                            title="Date MCCQE Part 1 taken"
                            noTime
                            date={values.mccqePart1DateTaken}
                            disabled={isReadOnly}
                            pickerConfig={{
                                minDate: new Date(1950, 1, 1),
                                maxDate: new Date(),
                                showMonthDropdown: true,
                                showYearDropdown: true,
                                dropdownMode: 'select'
                            }}
                            required
                        />

                        <FormUtils.GroupSetText
                            fieldName="mccqePart1Score"
                            title="MCCQE Part 1 Score"
                            disabled={isReadOnly}
                            required
                        />
                    </>
                }


                {values.mccqePart1Pass?.toString() != "true" &&
                    <>
                        <FormUtils.GroupSetRadio
                            fieldName="mccqePart1Registered"
                            title={<>I am registered for a siting of the MCCQE Part 1 and will share my result with the AIMG Program through physiciansapply.ca no later than {values.submissionCycleSettings.questionMCCQEPart1DateBoundary}.</>}
                            options={["Yes", "No"]}
                            required={true}
                            disabled={isReadOnly}
                        />

                        {values.mccqePart1Registered == 'No' &&

                            <>
                                <FormUtils.GroupSetCheckbox
                                    fieldName="mccqePart1RegisterIntend"
                                    title={<>I intend to register to sit the MCCQE1 Part 1 exam and will share my results by {values.submissionCycleSettings.questionMCCQEPart1DateBoundary}.</>}
                                    required={true}
                                    disabled={isReadOnly}
                                />

                                <div className="bg-light border rounded-1 p-2">
                                    <p className="fst-italic">Additional Notes:</p>
                                    <div>
                                        * The last opportunity to take the exam is the {values.submissionCycleSettings.questionMCCQEPart1SessionBoundary} session. <br />
                                        * A receipt is required as proof of registration as part of the supporting document upload, if registered. <br />
                                        * Applicants must <strong>share</strong> access with the AIMG Program to the MCCQ1 Exam Statement of Results showing a PASS standing through physiciansapply.ca no later than {values.submissionCycleSettings.questionMCCQEPart1DateBoundary}.
                                    </div>
                                </div>
                            </>}
                    </>
                }
            </div>

            <div className="card mb-3 p-3">
                <h3>
                    National Assessment Collaboration (NAC)
                </h3>
                <FormUtils.GroupSetRadio
                    fieldName="nacExam"
                    title="Do you currently have a pass standing on the NAC Exam?"
                    vertical
                    valueAsBoolean={true}
                    options={["true;I currently have a PASS score", "false;I currently do NOT have a PASS score."]}
                    explanationText={values.nacExam && <div className="fst-italic">Please share your NAC exam on your <a href="https://physicianapply.ca">physicianapply.ca</a> profile by {values.submissionCycleSettings.questionNACExamShareScoreOnWebsiteByDateBoundary}.</div>}
                    disabled={isReadOnly}
                />

                {values.nacExam?.toString() == "true" && <><FormUtils.GroupSetDatePicker
                    fieldName="nacExamDateTaken"
                    title="Date NAC Exam taken"
                    noTime
                    date={values.nacExamDateTaken}
                    pickerConfig={{
                        minDate: new Date(1950, 1, 1),
                        maxDate: new Date(),
                        showMonthDropdown: true,
                        showYearDropdown: true,
                        dropdownMode: 'select'
                    }}
                    disabled={isReadOnly}
                    required
                />

                    {/*values.nacExamDateTaken != null && <FormUtils.GroupSetDropdown<string>
                        fieldName="nacExamScorePlaceholder"
                        title="NAC Exam Score"
                        dropdownProps={{
                            dataSource: ["PASS (score not provided)", "PASS WITH SUPERIOR PERFORMANCE (score not provided)", "Score (enter numerical value)"],
                            getId: x => x,
                            renderItem: x => x,
                            renderSelectedItem: x => x,
                            selectedValue: values.nacExamScore,
                            onSelectedItemChanged: (item) => {
                                if (item) { values.nacExamScore = item.toString() }
                            }
                        }}
                        disabled={isReadOnly}
                        required
                    />*/}

                    <FormUtils.GroupSetDropdown<LookupItem>
                        fieldName="nacExamScoreType"
                        title="NAC Exam Outcome"
                        dropdownProps={{
                            dataSource: nacExamScoreTypesOptions,
                            getId: x => x.value,
                            renderItem: x => x.text,
                            renderSelectedItem: x => x.text,
                            selectedValue: values.nacExamScoreType
                        }}
                        disabled={isReadOnly}
                    />

                    {values.nacExamDateTaken != null && values.nacExamScoreType == "score" &&
                        <FormUtils.GroupSetText
                            fieldName="nacExamScore"
                            title="Enter Score"
                            disabled={isReadOnly}
                            required
                        />
                    }

                    {/*
                    {values.nacExamDateTaken != null && (expectNumericValue()
                        ? <FormUtils.GroupSetText
                            fieldName="nacExamScore"
                            title="NAC Exam Score"
                            disabled={isReadOnly}
                            required
                        />
                        : <FormUtils.GroupSetDropdown<string>
                            fieldName="nacExamScore"
                            title="NAC Exam Score"
                            dropdownProps={{
                                dataSource: ["FAIL", "PASS", "PASS WITH SUPERIOR PERFORMANCE"],
                                getId: x => x,
                                renderItem: x => x,
                                renderSelectedItem: x => x,
                                selectedValue: values.nacExamScore,
                                onSelectedItemChanged: 
                            }}
                            disabled={isReadOnly}
                            required
                        />
                    )}
                    */}

                </>}

                {values.nacExam?.toString() != 'true' && <>
                    <FormUtils.GroupSetRadio
                        fieldName="nacRetakeFall"
                        title="I am registered for a sitting of the NAC, and will share my result with the AIMG Program through physiciansapply.ca no later than November 13, 2024."
                        vertical
                        valueAsBoolean={true}
                        options={["true;Yes", "false;No"]}
                        disabled={isReadOnly}
                    />

                    {values.nacRetakeFall?.toString() != 'true' && <>
                        <FormUtils.GroupSetCheckbox
                            fieldName="nacRegisterIntend"
                            title={<>I intend to register to sit the NAC exam and will share my results by {values.submissionCycleSettings.questionNACExamShareScoreOnWebsiteByDateBoundary}.</>}
                            required={true}
                            disabled={isReadOnly}
                        />

                        <div className="bg-light border rounded-1 p-2">
                            <p className="fst-italic">Additional Notes:</p>
                            <div>
                                * The last opportunity to take the exam is {values.submissionCycleSettings.questionNACExamRetakeOpportunityDateBoundary}.<br />
                                * A receipt is required as proof of registration as part of the supporting document upload, if registered. <br />
                                * Applicants must <strong>share</strong> access with the AIMG Program to the NAC Exam Statement of Results showing a PASS standing through physiciansapply.ca no later than {values.submissionCycleSettings.questionNACExamShareScoreOnWebsiteByDateBoundary}.
                            </div>
                        </div>
                    </>}
                </>}

            </div>

        </div>

    </React.Fragment>);
}

