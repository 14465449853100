import * as React from 'react';
import GenericEditor from './GenericEditor';
import { UserAccountEditorFields } from '../editorFields/UserAccountEditorFields';
import { UserAccountEdit, ValidationSchema } from '../../models/UserAccountEdit';
import { AccountService }  from '../../services/AccountService';


export const UserAccountEditor = () => {

    return <GenericEditor<UserAccountEdit, string>
        getById={id => AccountService.get(id || '')}
        save={model => AccountService.save(model)}
        formikFields={UserAccountEditorFields}
        validationSchema={ValidationSchema}
        stringToId={x => x}
        getId={x => x.id}
        shouldTryToLoad={id => true}
    />;
}