export class UserProfileListView {
    userProfileId: string;
    userName: string;
    surname: string;
    formerSurname: string;
    givenNames: string;
    email: string;
    submissionDate: Date;
}

export const DateFields = ["submissionDate"];